import { scaleZPL } from "./scaleZPL";
import { handleBrowserPrintToPrinter } from "./browserPrint";

import axios from "../../axios/axios.config";

export const printCustomerLabel = async ({
  type = "print",
  currentPrinter,
  title = "",
  data,
  currentLocationInternalId = null,
  orderType,
}) => {
  try {
    if (!currentPrinter) {
      throw new Error(
        "No printer selected. Please select a printer in your settings."
      );
    }

    if (!orderType) {
      throw new Error("Order Type is required");
    }

    let template = null;
    const {
      ucclabeltemplate,
      ponumber,
      specialhandlingnotes,
      packdetails,
      createdfrom,
      status,
      createdfrominternalid,
      shipmentsequence,
    } = data;

    if (status !== "packed") {
      throw new Error("Error: Order must be completely packed before printing");
    }

    if (ucclabeltemplate === "AUTOZONE") {
      template = "";
      //pallet case
      if (orderType === "PALLET") {
        for (const pallet of packdetails) {
          template += `^XA
^MMT
^PW1200^LL1800
^LS0^PON^LH0,0
^FT700,100^FB1800,2,0,L,0^A0R,225^FDPO# ${ponumber}^FS
^FT200,100^FB1600,5,0,L,0^A0R,125^FD${specialhandlingnotes}^FS
^FT60,850^FB500,1,0,L,0^A0R,75^FDPLT:^FS
^FT60,1200^FB250,1,0,L,0^A0R,75^FD${pallet?.palletId}^FS
^FT60,1350^FB250,1,0,L,0^A0R,75^FDof  ${packdetails?.length}^FS
^PQ4,0,1,Y
^XZ`;
        }
      }

      //box case
      if (orderType === "BOX") {
        if (packdetails?.length > 1 || packdetails.length <= 0) {
          throw new Error("Box type only supports single pallet orders");
        }

        const boxes = packdetails[0]?.boxes;

        if (boxes?.length <= 0) {
          throw new Error("No boxes found on pallet");
        }

        for (const box of boxes) {
          template += `^XA
^MMT
^PW1200^LL1800
^LS0^PON^LH0,0
^FT700,100^FB1800,2,0,L,0^A0R,225^FDPO# ${ponumber}^FS
^FT200,100^FB1600,5,0,L,0^A0R,125^FD${specialhandlingnotes}^FS
^FT60,850^FB500,1,0,L,0^A0R,75^FDBOX:^FS
^FT60,1200^FB250,1,0,L,0^A0R,75^FD${box?.boxId}^FS
^FT60,1350^FB250,1,0,L,0^A0R,75^FDof  ${boxes?.length}^FS
^PQ1,0,1,Y
^XZ`;
        }
      }
    }

    if (ucclabeltemplate === "NISSAN") {
      if (orderType === "BOX") {
        throw new Error("Box type not supported for this order");
      }

      template = "";
      for (const pallet of packdetails) {
        template += `^XA
^MMT^PW1200^LL1800
^LS0
^PON
^LH0,0
^FT75,1790^A0B,50^FDPART NO^FS
^FT125,1790^A0B,50^FD(P)^FS
^FT175,1400^A0B,100^FD${
          pallet?.items?.length > 1
            ? "MIXED LOAD LABEL"
            : `${pallet?.items[0]?.item?.item}`
        }^FS
^FO215,0^GB6,1850,6^FS
^FT300,1790^A0B,50^FDQUANTITY^FS
^FT350,1790^A0B,50^FD(Q)^FS
^FT450,1400^A0B,100^FD${pallet?.totalitemquantity}^FS
^FO220,900^GB330,6,6^FS
^FT400,875^FB600,4,0,L,0^A0B,44^FDAIT\\&550 HInton Oaks Blvd\\&Knightdale, NC 27545^FS
^FT640,875^FB600,5,0,L,0^A0B,44^FD${new Date()?.toLocaleDateString()}^FS
^FT700,875^FB600,5,0,L,0^A0B,44^FDPallet ${pallet?.palletId}  of  ${
          packdetails?.length
        }^FS
^FO550,625^GB6,1200,6^FS
^FT625,1790^A0B,50^FDSUPPLIER^FS
^FT675,1790^A0B,50^FD(V)^FS
^FT675,1500^A0B,75^FDF01972002^FS
^FT825,1500^BY3,3,133^B3B,N,125,N,N^FDVF01972002^FS
^FO875,625^GB6,1200,6^FS
^FT950,1790^A0B,50^FDSERIAL^FS
^FT1000,1790^A0B,50^FD(S)^FS
^FT1000,1550^A0B,75^FD${pallet?.palletucc}^FS
^FT1150,1575^BY3,3,133^B3B,N,125,N,N^FDS${pallet?.palletucc}^FS
^FO550,620^GB660,6,6^FS
^PQ1,0,1,Y
^XZ`;
      }
    }

    if (ucclabeltemplate === "NAPA") {
      if (orderType === "BOX") {
        throw new Error("Box type not supported for this order");
      }
      template = "";
      const locationAddressResponse = await axios.get(
        `locations/get/location-address?locationinternalid=${currentLocationInternalId}`
      );

      if (
        !currentLocationInternalId ||
        !locationAddressResponse?.data?.address
      ) {
        throw new Error("No Location Address Found.");
      }

      //get shipping data
      const orderShippingResponse = await axios.post(
        `netsuite/print-labels/customer-labels/get-order-data`,
        {
          orderInternalId: createdfrominternalid,
          shipmentSequence: shipmentsequence,
        }
      );

      const {
        po,
        shippingmethod,
        shippingzip,
        trackingnumber,
        shippingattention,
        shippingaddressee,
        shippingaddr1,
        shippingaddr2,
        shippingcity,
        shippingstate,
      } = orderShippingResponse.data;
      //create full address from data
      const shippingAddress = `${shippingattention || ""}\\&
      ${shippingaddressee}\\&
      ${shippingaddr1}\\&
      ${shippingaddr2 || ""}\\&
      ${shippingcity}, ${shippingstate} ${shippingzip}`;

      //loop through and create pallet labels
      for (const pallet of packdetails) {
        template += `^XA
^MMT
^PW1200^LL1800
^LS0
^POI
^LH0,0
^FT40,100^A0N,50^FDFROM:^FS
^FT40,340^FB600,5,0,L,0^A0N,44^FD${locationAddressResponse?.data?.address
          ?.replace("AIT", "ADVANCED")
          .replace(/\n/g, "\\&")}^FS
^FO600,0^GB15,455,15^FS
^FT640,70^A0N,50^FDPallet Count: ${pallet?.palletId} of ${
          packdetails?.length
        }^FS
^FT640,170^A0N,50^FDTO:^FS
^FT640,420^FB600,5,0,L,0^A0N,44^FD${shippingAddress}^FS
^FO600,100^GB600,15,15^FS
^FO0,450^GB1250,15,15^FS
^FT40,525^A0N,50^FDSHIP TO POST:^FS
^FT235,615^A0N,75^FD${shippingzip}^FS
^FO650,455^GB15,200,15^FS
^FT700,525^A0N,50^FDCARRIER:^FS
^FT700,615^A0N,75^FD${shippingmethod}^FS
^FO0,650^GB1250,15,15^FS
^FT40,720^A0N,50^FDPO NO^FS
^FT40,820^FB1000,1,0,C,0^A0N,80^FD${po}^FS
^FO0,850^GB1250,15,15^FS
^FT40,915^A0N,50^FDBOL/SHIPMENT NO^FS
^FT40,1020^FB1000,1,0,C,0^A0N,100^FD${trackingnumber}^FS
^FO0,1050^GB1250,15,15^FS
^FT40,1125^A0N,50^FDCUSTOMER^FS
^FT40,1375^FB600,5,0,L,0^A0N,44^FD${shippingAddress}^FS
^FO0,1400^GB1250,15,15^FS
^FT40,1460^A0N,50^FDSSCC-18:^FS
^FT150,1720^BY5,3,220^BCN,220,Y,N,N,N^FD${pallet.palletucc}^FS
^PQ1,0,1,Y
^XZ`;
      }
    }

    if (!template) {
      throw new Error(
        `No template exists for ${ucclabeltemplate}-${createdfrom}`
      );
    }

    //either print or return the final zpl code generated generated
    if (type === "print") {
      let templateType =
        currentPrinter.printerresolution !== "300dpi"
          ? scaleZPL(template)
          : template;
      let printJobUrl = "https://api.printnode.com/printjobs";
      let apiID = process.env.REACT_APP_PRINT_API_ID;
      let body = {
        printerId: currentPrinter.printnodeid,
        title,
        contentType: "raw_base64",
        content: btoa(templateType),
        source: `MERN > Print Order Labels > printCustomerLabelFn`,
      };

      if (
        currentPrinter.printername?.startsWith("USB") &&
        currentPrinter.printnodeid === 0
      ) {
        return handleBrowserPrintToPrinter({
          currentPrinter,
          template,
        });
      } else {
        return axios.post(printJobUrl, body, {
          headers: {
            Authorization: "Basic " + btoa(apiID),
            "Content-Type": "application/json",
          },
        });
      }
    } else {
      //returns final zpl code
      return template;
    }
  } catch (error) {
    throw error;
  }
};
