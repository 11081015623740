import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../redux/features/user/userSlice";
import { openGeneralAlert } from "../redux/features/alert/alertSlice";
import { Navigate, useLoaderData, useSearchParams } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import axios from "../axios/axios.config";

const InfoSelect = () => {
  const [searchParams] = useSearchParams();
  const user = useSelector((state) => state.user);
  const [subsidiary, setSubsidiary] = useState(user.subsidiary[0]);
  const [location, setLocation] = useState(
    user.location.filter(
      (userLocation) => userLocation.subsidiary === subsidiary
    )[0].location
  );
  const [subsidiaryId, setSubsidiaryId] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [netsuiteLocationIds, setNetsuiteLocationIds] = useState(null);
  const dispatch = useDispatch();
  const data = useLoaderData();

  useEffect(() => {
    if (location) {
      getNetsuiteLocationIds();
      getLocationId();
    }
  }, [location]);

  useEffect(() => {
    if (subsidiary) {
      getSubsidiaryId();
    }
  }, [subsidiary]);

  const handleSubmit = () => {
    let department = "";
    if (
      searchParams.get("department") &&
      user?.department.includes(searchParams.get("department"))
    ) {
      department = searchParams.get("department");
    } else if (user.department.length) {
      department = user.department[0];
    } else if (!user.department.length && data.department.length) {
      department = data.department[0];
    }

    dispatch(
      setUserInfo({
        location,
        locationinternalid: locationId,
        subsidiary,
        subsidiaryinternalid: subsidiaryId,
        department,
        netsuiteLocationIds,
      })
    );
  };

  const getNetsuiteLocationIds = async () => {
    try {
      setNetsuiteLocationIds(null);
      const { data } = await axios.get(
        `locations/get/netsuite-locations/internalids/${location}`
      );

      setNetsuiteLocationIds(data.netsuitelocationinternalids || []);
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Cannot get netsuite locations for ${location}`,
        })
      );
    }
  };

  const getSubsidiaryId = async () => {
    try {
      //get subsidiary internalid
      const subsidiaryResponse = await axios.get(
        `/subsidiaries/get/internalid/${subsidiary}`
      );

      setSubsidiaryId(subsidiaryResponse.data?.internalid || null);
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: `Cannot get subsidiary id for ${subsidiary}`,
          duration: 5000,
        })
      );
    }
  };

  //get location internal id
  const getLocationId = async () => {
    try {
      const locationIdResponse = await axios.get(
        `/locations/get/location-internalid?location=${location}&subsidiary=${subsidiary}`
      );

      setLocationId(locationIdResponse.data?.internalid || null);
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  };

  //submit will trigger a re render and current department will be updated
  return user.isAuthenticated && user.currentDepartment ? (
    <Navigate
      to={
        searchParams.get("redirect-path") ||
        `/portal/${user.currentDepartment.toLowerCase()}`
      }
      replace
    />
  ) : (
    <Container
      maxWidth="sm"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: 5,
      }}
    >
      <Typography
        sx={{ typography: { xs: "h3", md: "h2" } }}
        gutterBottom
        textAlign="center"
      >
        User Session
      </Typography>

      <Box
        sx={{ display: "flex", width: "100%", pb: 2, gap: 1 }}
        component="form"
      >
        <FormControl fullWidth required autoFocus>
          <InputLabel id="subsidiary-select-label">Subsidiary</InputLabel>
          <Select
            labelId="subsidiary-select-label"
            id="subsidiary-select"
            value={subsidiary}
            label="subsidiary"
            onChange={(e) => {
              setSubsidiary(e.target.value);
              setLocation("");
            }}
          >
            {user.subsidiary.length
              ? user.subsidiary.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })
              : data.subsidiary.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
          </Select>
        </FormControl>
        <FormControl required fullWidth disabled={!Boolean(subsidiary)}>
          <InputLabel id="location-select-label">Location</InputLabel>
          <Select
            labelId="location-select-label"
            id="location-select"
            value={location}
            label="Location"
            onChange={(e) => {
              setLocation(e.target.value);
            }}
          >
            {subsidiary && user.location.length
              ? user.location
                  .filter(
                    (userLocation) => userLocation.subsidiary === subsidiary
                  )
                  .map((item) => {
                    return (
                      <MenuItem key={item.location} value={item.location}>
                        {item.location}
                      </MenuItem>
                    );
                  })
              : data?.location
                  .filter(
                    (userLocation) => userLocation.subsidiary === subsidiary
                  )
                  .map((item) => {
                    return (
                      <MenuItem key={item.location} value={item.location}>
                        {item.location}
                      </MenuItem>
                    );
                  })}
          </Select>
        </FormControl>
      </Box>
      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={
          location === "" ||
          subsidiary === "" ||
          !netsuiteLocationIds ||
          !subsidiaryId ||
          !locationId
        }
        onClick={handleSubmit}
      >
        Continue
      </Button>
    </Container>
  );
};

export default InfoSelect;
