import { useState } from "react";
import { Search } from "@mui/icons-material";
import { useLoaderData, useNavigate, useNavigation } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import Loading from "../../../components/Loading";
import FlexWrapper from "../../../components/FlexWrapper";

const EquipmentChecklist = () => {
  const [searchInput, setSearchInput] = useState("");
  const [error, setError] = useState("");

  const equipmentData = useLoaderData();
  const matches = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const navData = useNavigation();

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      if (!searchInput) {
        throw new Error("Input Cannot Be Empty");
      }

      const foundEquipment = equipmentData.find(
        (equipment) => equipment.equipmentid === searchInput
      );

      if (!foundEquipment) {
        throw new Error("Equipment Not Found. Please See Supervisor.");
      }

      if (foundEquipment.status !== "active") {
        throw new Error("Equipment Is Out Of Order. Please See Supervisor.");
      }

      setError("");
      navigate(`${foundEquipment._id}`);
    } catch (error) {
      setError(error.message);
    }
  };

  if (navData.state === "loading")
    return <Loading message="Loaidng Equipment Checklist Form..." />;

  return (
    <Container maxWidth={matches ? "xl" : "md"} sx={{ py: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} component="form" onSubmit={handleSubmit}>
          <TextField
            disabled={!Boolean(equipmentData?.length)}
            fullWidth
            autoFocus
            required
            label="Search Equipment"
            variant="outlined"
            value={searchInput}
            onChange={(event) =>
              setSearchInput(event.target.value.toUpperCase())
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    disabled={!Boolean(equipmentData?.length)}
                    onClick={handleSubmit}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(error)}
            helperText={error}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            sx={{ height: "81vh", overflow: "scroll" }}
          >
            {equipmentData?.length ? (
              equipmentData?.map((equipment, index) => (
                <Grid item xs={12} key={index}>
                  <Card variant="outlined">
                    <CardContent>
                      <FlexWrapper
                        justifyContent="space-between"
                        alignItems="baseline"
                      >
                        <Typography variant="h6" gutterBottom>
                          Equipment ID: {equipment.equipmentid}
                        </Typography>
                        <Typography
                          variant="caption"
                          gutterBottom
                          color={
                            equipment.status === "active" ? "green" : "red"
                          }
                        >
                          {equipment.status.toUpperCase()}
                        </Typography>
                      </FlexWrapper>
                      <Typography>Type: {equipment.type}</Typography>
                      <Typography>Model: {equipment.model}</Typography>
                      <Typography>
                        Last Inspected:{" "}
                        {new Date(equipment.lastinspected).toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Box textAlign="center" width="100%" pt={2}>
                <Typography textAlign="center" variant="h5">
                  No Equipment to Display
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        {/* {searchResult && (
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Equipment Details
                </Typography>
                <Typography>Type: {searchResult.type}</Typography>
                <Typography>Model: {searchResult.model}</Typography>
                <Typography>
                  Last Inspected: {searchResult.lastinspected}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )} */}
      </Grid>
    </Container>
  );
};
export default EquipmentChecklist;
