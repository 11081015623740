import { handleBrowserPrintToPrinter } from "./browserPrint";
import { scaleZPL } from "./scaleZPL";

import axios from "../../axios/axios.config";

export const printGeneralV2 = async ({
  currentPrinter,
  title = "General Print Function",
  template,
}) => {
  try {
    if (!currentPrinter) {
      throw new Error(
        "No printer is selected. Please select one in your settings."
      );
    }

    if (!template) {
      throw new Error("No print template was provided");
    }

    if (
      currentPrinter.printername?.startsWith("USB") &&
      currentPrinter.printnodeid === 0
    ) {
      //print using browser printer
      await handleBrowserPrintToPrinter({
        currentPrinter,
        template,
      });
    } else {
      //print using print node
      let finalTemplate =
        currentPrinter.printerresolution !== "300dpi"
          ? btoa(scaleZPL(template))
          : btoa(template);

      let printJobUrl = "https://api.printnode.com/printjobs";
      let apiID = process.env.REACT_APP_PRINT_API_ID;
      let body = {
        printerId: currentPrinter.printnodeid,
        title,
        contentType: "raw_base64",
        content: finalTemplate,
        source: "MERN  > printGeneralFunction",
      };

      await axios.post(printJobUrl, body, {
        headers: {
          Authorization: "Basic " + btoa(apiID),
          "Content-Type": "application/json",
        },
      });
    }
  } catch (error) {
    throw error;
  }
};
