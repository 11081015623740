import { useState } from "react";
import { Search } from "@mui/icons-material";
import { useNavigate, useNavigation } from "react-router-dom";
import {
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import Loading from "../../../../components/Loading";
import axios from "../../../../axios/axios.config";
import { useSelector } from "react-redux";

const UpdatePackSearch = () => {
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [error, setError] = useState("");

  const navigation = useNavigation();
  const navigate = useNavigate();
  const { currentSubsidiary } = useSelector((state) => state.user);

  const fetchPackProcess = async (url, token) => {
    return await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const checkShippedFulfillment = async (internalId, shipmentSequence) => {
    const response = await axios.get(
      `netsuite/pack-process/get/shipped-fulfillments/${internalId}/${shipmentSequence}`
    );

    if (parseInt(response.data.count) > 0) {
      throw new Error(
        "One or more fulfillments have already been shipped. Updating pallet information once shipped is not allowed. Need to manually update related Transaction Packages record in NetSuite."
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSearchLoading(true);
    const token = localStorage.getItem("token");

    try {
      // First API call
      const primaryUrl = `pack-process/${search}/subsidiary/${currentSubsidiary}`;
      let response = await fetchPackProcess(primaryUrl, token);

      if (!Object.keys(response.data.packdetails).length) {
        throw new Error("Order Does Not Have Pallet Data.");
      }

      // Check shipped fulfillment status
      await checkShippedFulfillment(
        response.data.createdfrominternalid,
        response.data.shipmentsequence
      );

      setError("");
      setSearch("");
      navigate(response.data._id);
    } catch (error) {
      if (error?.response?.status === 404) {
        try {
          // Second API call if the first fails with 404
          const secondaryUrl = `pack-process-v2/${search}/subsidiary/${currentSubsidiary}`;
          const response = await fetchPackProcess(secondaryUrl, token);

          if (!response.data.packdetails.length) {
            throw new Error("Order Does Not Have Pallet Data.");
          }

          // Check shipped fulfillment status again
          await checkShippedFulfillment(
            response.data.createdfrominternalid,
            response.data.shipmentsequence
          );

          setError("");
          setSearch("");
          navigate(response.data._id);
        } catch (innerError) {
          setError(innerError.response?.data?.msg || innerError.message);
        }
      } else {
        setError(error.response?.data?.msg || error.message);
      }
    } finally {
      setSearchLoading(false); // Use false instead of an empty string for clarity
    }
  };

  if (searchLoading || navigation?.state === "loading")
    return (
      <Loading message={searchLoading ? "Searching..." : "Loading Order..."} />
    );

  return (
    <Container maxWidth="md">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Update Pallet Information
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          autoFocus
          required
          size="large"
          label="Search Order"
          error={Boolean(error)}
          helperText={error}
          value={search}
          onChange={(event) => setSearch(event.target.value.toUpperCase())}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton type="submit">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </Container>
  );
};
export default UpdatePackSearch;
