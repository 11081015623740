import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import {
  filterPath,
  findNextBin,
} from "../../../redux/features/pick-order/pickOrderSlice";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import GeneralModal from "../../GeneralModal";
import FlexWrapper from "../../FlexWrapper";

const PickOrderActionsWarningModal = ({ type, open, closeFn, action }) => {
  const [startingBin, setStartingBin] = useState("");
  const [endingBin, setEndingBin] = useState("");

  const matches = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const { path, _id } = useSelector((state) => state.pickOrder);
  const { openAlert } = useGeneralAlert();

  const filterFn = async () => {
    if (endingBin <= startingBin) {
      openAlert({
        type: "error",
        message: "Starting Bin Must Be Lower Than Ending Bin",
        duration: 5000,
      });
      return;
    }

    const filteredPath = path.filter((binObj) => {
      const binNum = binObj.binnumber;

      return binNum >= startingBin && binNum <= endingBin;
    });

    if (!filteredPath.length) {
      openAlert({
        type: "error",
        message: "No Bins Found",
        duration: 5000,
      });
      return;
    }
    setEndingBin("");
    setStartingBin("");
    closeFn();
    //logic to update the path with filtered path
    dispatch(
      filterPath({ filteredPath, filteredBins: `${startingBin}-${endingBin}` })
    );
    openAlert({
      type: "success",
      message: "Sucessfully filtered bins. Refresh bin data to revert",
      duration: 3000,
    });
    await dispatch(findNextBin({ _id })).unwrap();
  };

  return (
    <GeneralModal
      open={open}
      handleClose={() => {
        setEndingBin("");
        setStartingBin("");
        closeFn();
      }}
    >
      <Box>
        <Box>
          <Typography
            variant="h4"
            textAlign="center"
            py={1}
            textTransform="capitalize"
          >
            {type === "partial-fulfill" && "Partially fulfill this order?"}
            {type === "reset-all-items" && "Reset all Items?"}
            {type === "cancel-pick" && "Pause Pick?"}
            {type === "refresh-bin" && "Refresh Bin Data?"}
            {type === "filter-bin-range" && "Filter Bin Range"}
          </Typography>
          {type === "filter-bin-range" ? (
            <Grid container spacing={1} sx={{ p: 2 }} component="form">
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  inputProps={{ autoComplete: "off" }}
                  fullWidth
                  size="small"
                  label="Starting Bin"
                  value={startingBin}
                  onChange={(event) =>
                    setStartingBin(event.target.value.toUpperCase()?.trim())
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputProps={{ autoComplete: "off" }}
                  fullWidth
                  size="small"
                  label="Ending Bin"
                  value={endingBin}
                  onChange={(event) =>
                    setEndingBin(event.target.value.toUpperCase()?.trim())
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={filterFn}
                  disabled={!startingBin && !endingBin}
                  type="button"
                  variant="contained"
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          ) : (
            <>
              <Divider />
              <Typography variant="h6" textAlign="center" p={1}>
                {type === "partial-fulfill" &&
                  "Item fulfillment will be created in Netsuite and you will be logged out of this order."}
                {type === "reset-all-items" &&
                  "All progress will be lost and you will start from the beginning again"}
                {type === "cancel-pick" &&
                  "You will be removed from the order, but can resume at a later time. All data will be saved."}
                {type === "refresh-bin" &&
                  "Latest bin updates will be applied. Current path may change."}
                {type === "refresh-bin" &&
                  "Latest bin updates will be applied. Current path may change."}
              </Typography>
              <Divider />
              <Box p={1}>
                <FlexWrapper
                  justifyContent={matches ? "center" : "flex-end"}
                  flexDirection={matches ? "column" : "row"}
                  gap={1}
                  height={matches ? 100 : 50}
                  alignItems="center"
                >
                  <Button
                    fullWidth={matches}
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setEndingBin("");
                      setStartingBin("");
                      closeFn();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    fullWidth={matches}
                    variant="contained"
                    onClick={action}
                  >
                    Ok
                  </Button>
                </FlexWrapper>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </GeneralModal>
  );
};
export default PickOrderActionsWarningModal;
