import { useState } from "react";
import { useLoaderData, useOutletContext } from "react-router-dom";
import {
  updatePacejetStation,
  updatePrinter,
} from "../../redux/features/user/userSlice";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FlexWrapper from "../../components/FlexWrapper";

const ProfilePrinters = () => {
  const { user, dispatch } = useOutletContext();
  const printers = useLoaderData();
  const [printer, setPrinter] = useState(
    user.printer === "" ? "none" : user.printer
  );
  const [station, setStation] = useState(user.pacejetstation);

  const handlePrinterSubmit = async (event) => {
    event.preventDefault();
    try {
      await dispatch(
        updatePrinter({
          printerid: printer === "none" ? "" : printer,
          userid: user.username,
        })
      ).unwrap();

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully updated printer",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  };

  const handlePacejetSubmit = async (event) => {
    event.preventDefault();
    try {
      await dispatch(
        updatePacejetStation({ stationid: station, userid: user._id })
      ).unwrap();

      dispatch(
        openGeneralAlert({
          type: "success",
          message: "Successfully Updated Pacejet Station",
          duration: 3000,
        })
      );
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Typography variant="h5" gutterBottom>
          Printer Information
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Typography variant="body1" pb={3}>
          Current Printer:{" "}
          <strong>{user.printer ? user.printer : "N/A"}</strong>
        </Typography>
        <form onSubmit={handlePrinterSubmit}>
          <Grid container spacing={1} alignItems="stretch">
            <Grid item xs={12} md={8}>
              <FormControl fullWidth required>
                <InputLabel id="select-label-printers">
                  Select Printer
                </InputLabel>
                <Select
                  labelId="select-label-printers"
                  id="select-printer"
                  label="Select Printer"
                  name="printer"
                  value={printer}
                  onChange={(event) => setPrinter(event.target.value)}
                >
                  <MenuItem value="none">None</MenuItem>
                  {printers.map((printer) => (
                    <MenuItem key={printer._id} value={printer.printername}>
                      {printer.printername}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={1.5}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                sx={{ height: "100%" }}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      {/* Pacejet Section */}
      {parseInt(user.rolelevel) <= 4 && (
        <Box pt={3}>
          <Typography variant="h5" gutterBottom>
            Pacejet Workstation
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="body1" pb={3}>
            Current Station:{" "}
            <strong>{user.pacejetstation ? user.pacejetstation : "N/A"}</strong>
          </Typography>

          <form onSubmit={handlePacejetSubmit}>
            <Grid container spacing={1} alignItems="stretch">
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  label="Station ID"
                  type="number"
                  value={station}
                  onChange={(event) => setStation(event.target.value?.trim())}
                />
              </Grid>
              <Grid item xs={12} md={1.5}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{ height: "100%" }}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </Box>
  );
};
export default ProfilePrinters;
