import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { Info, Print } from "@mui/icons-material";
import { printGeneral } from "../../../../utils/printer-functions/printGeneralFn";
import { printMultiPickOrderPalletLabelsWithOneTemplate } from "../../../../utils/printer-functions/printPickOrderPalletLabel";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import {
  DataGrid,
  gridExpandedSortedRowIdsSelector,
  GridToolbar,
  GridToolbarContainer,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid";
import {
  ORDERPROCESSINGCOLUMNS,
  ORDERPROCESSINGCOLUMNSWGE,
} from "../../../../utils/columns/order-processing/orderProcessingColumns";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";
import FlexWrapper from "../../../../components/FlexWrapper";
import StockOrdersAssignPickersModal from "../../../../components/warehouse/orders/stock-orders/StockOrdersAssignPickersModal";
import StockOrdersInfoModal from "../../../../components/warehouse/orders/stock-orders/StockOrdersInfoModal";
//variable for selecting print type. Either netsuite api or custom zpl code
const PRINTCONTENTTYPE = "raw_base64"; //either pdf_base64 or raw_base64

function CustomToolbar({ getOrderdata, setLoading }) {
  const [whsePickers, setWhsePickers] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  const { currentPrinter } = useSelector((state) => state.printers);
  const { currentLocationInternalId, rolelevel } = useSelector(
    (state) => state.user
  );
  const { openAlert } = useGeneralAlert();
  const apiRef = useGridApiContext();
  //get filetred rows
  const filteredIds = useGridSelector(apiRef, gridExpandedSortedRowIdsSelector);
  // Map the IDs to get the actual row data
  const filteredRows = filteredIds.map((id) => apiRef.current.getRow(id));
  //get selected rows
  const selectedRows = apiRef.current.getSelectedRows(); //returns Map object
  // Convert Map to Array of Objects for Selected Orders
  const convertedArray = Array.from(selectedRows, ([key, value]) => {
    return {
      ...value,
      "SO #": key, // Replace the 'SO #' field with the stripped key value
    };
  }).sort((a, b) => {
    const soA = a["SO #"];
    const soB = b["SO #"];
    return soA.localeCompare(soB); // Lexicographical comparison
  });

  const totalProjectedPickQtySelected = convertedArray?.length
    ? convertedArray.reduce((acc, current) => {
        return acc + parseInt(current["Projected Pick Qty"]);
      }, 0)
    : 0;
  const numOfOrders = filteredRows.length;
  const numOfLines = filteredRows.length
    ? filteredRows.reduce((acc, current) => {
        return acc + parseInt(current["Number of Lines"]);
      }, 0)
    : 0;
  const numOfAllocatedLines = filteredRows.length
    ? filteredRows.reduce((acc, current) => {
        return acc + parseInt(current["Allocated Lines"]);
      }, 0)
    : 0;
  const numOfUnits = filteredRows?.length
    ? filteredRows.reduce((acc, current) => {
        return acc + parseInt(current["Total Qty"]);
      }, 0)
    : 0;
  const numOfAllocatedUnits = filteredRows?.length
    ? filteredRows.reduce((acc, current) => {
        return acc + parseInt(current["Projected Pick Qty"]);
      }, 0)
    : 0;

  const headerValues = [
    {
      headerName: "Number of Orders",
      headerValue: numOfOrders,
      bgColor: "#da1818",
    },
    {
      headerName: "Number of Lines",
      headerValue: numOfLines,
      bgColor: "#ff8800",
    },
    {
      headerName: "Number of Units",
      headerValue: numOfUnits,
      bgColor: "#982f94",
    },
    {
      headerName: "Number of Allocated Lines",
      headerValue: numOfAllocatedLines,
      bgColor: "#692aa4",
    },
    {
      headerName: "Number of Allocated Units",
      headerValue: numOfAllocatedUnits,
      bgColor: "#2897ca",
    },
  ];

  useEffect(() => {
    getPickerData();
  }, []);

  const getPickerData = async () => {
    const nsPickersResponse = await axios.get(
      "netsuite/order-processing/get/warehouse-pickers"
    );

    setWhsePickers(
      nsPickersResponse.data?.pickers?.length
        ? nsPickersResponse.data.pickers.map((pickerObj) => {
            return { name: pickerObj.name, id: pickerObj.id };
          })
        : []
    );
  };

  const handlePrintOrdersAndAssign = async ({
    pickerInternalId = null,
    pickerAssignedTime = null,
    pickerName = null,
  }) => {
    setLoading(
      pickerInternalId && pickerAssignedTime
        ? "Printing and Assigning Orders"
        : "Printing Orders..."
    );
    try {
      if (PRINTCONTENTTYPE === "pdf_base64") {
        const promiseArray = convertedArray.map((order) => {
          return axios.get(
            `netsuite/multi-page/get/picking-ticket?orderid=${order["Internal ID"]}`
          );
        });
        //printing logic for netsuite pdfs
        const data = await Promise.all(promiseArray);

        //do printing logic for netsuite print
        const base64Arr = data.map((res) => res.data.data64);
        const printPromises = base64Arr.map((string) =>
          //print order
          printGeneral({
            body: {
              printerId: currentPrinter.printnodeid,
              title: "Pick Ticket",
              contentType: PRINTCONTENTTYPE,
              content: string,
              source: "MERN > printPickingTicket",
            },
          })
        );

        await Promise.all(printPromises);
      } else {
        //printing using printnode/browser printer
        //create zpl code for all orders
        const combinedZPL = await compileZPLCodes({ pickerName });
        await printMultiPickOrderPalletLabelsWithOneTemplate({
          currentPrinter,
          template: combinedZPL,
        });
      }

      //update netsuite if needed
      const needUpdateOrders = convertedArray.filter((order) =>
        order["Warehouse Order Status"]?.includes("To be Printed")
      );

      if (
        needUpdateOrders?.length ||
        (pickerInternalId && pickerAssignedTime)
      ) {
        await handleUpdateNetSuiteOrder({
          selectedOrders: convertedArray,
          pickerInternalId,
          pickerAssignedTime,
          printedTime: new Date().toLocaleString()?.replace(",", ""),
        });
        await getOrderdata();
      } else {
        setLoading("");
      }
      openAlert({
        type: "success",
        message: "Successfully Printed Orders",
        duration: 3000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Something Went Wrong: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    }
  };

  const handleUpdateNetSuiteOrder = async ({
    selectedOrders,
    pickerInternalId = null,
    pickerAssignedTime = null,
    printedTime = null,
  }) => {
    //create array of promises
    const promiseArr = selectedOrders.map((order) =>
      axios.patch("netsuite/order-processing/update/sales-order", {
        salesOrderInternalId: order["Internal ID"],
        pickerInternalId,
        pickerAssignedTime,
        printedTime,
        isToBePrintedStatus:
          order["Warehouse Order Status"]?.includes("To be Printed"),
      })
    );
    //submit all requests at the same time
    return await Promise.all(promiseArr);
  };

  const compileZPLCodes = async ({ pickerName = null }) => {
    try {
      let compiledZPLCode = "";

      //get location address
      const locationAddressResponse = await axios.get(
        `locations/get/location-address?locationinternalid=${currentLocationInternalId}`
      );

      if (!locationAddressResponse?.data?.address) {
        throw new Error("No Location Address Found.");
      }

      for (const order of convertedArray) {
        const code = `^XA^MMT^PW1200^LL1800^LS0^POI
        ^FT105,150^BY4,3,133^BCN,,N,N^FD${
          order["SO #"]?.includes(":")
            ? order["SO #"]?.split(":")[0]
            : order["SO #"]
        }^FS
        ^FT100,240^A0N,111,126^FD${order["SO #"]}^FS
        ^FT500,75^FB591,1,0,R,0^A0N,44^FD${
          pickerName ? pickerName : order["Picker Assigned"]
        }^FS
        ^FT720,250^FB400,1,0,R,0^A0N,44^FD${order["Date"]}^FS
        ^FT650,475^FB500,1,0,C,0^A0N,250^FD${
          order["SO #"]?.includes(":")
            ? order["SO #"]?.split(":")[0]?.slice(-4)
            : order["SO #"]?.slice(-4)
        }^FS
        ^FT675,625^FB500,1,0,C,0^A0N,175^FD${order["Brand Packaging"]}^FS
        ^FO50,266^GB1100,375,3^FS
        ^FT75,330^A0N,44^FDFROM/DE:^FS
        ^FT75,575^FB1100,5,0,L,0^A0N,44^FD${locationAddressResponse?.data?.address?.replace(
          /\n/g,
          "\\&"
        )}^FS
        ^FO50,638^GB1100,375,3^FS
        ^FT720,720^A0N,44^FDShip With: ${order["Must Ship With"]}^FS
        ^FT75,720^A0N,44^FDTO/A:^FS
        ^FT75,975^FB1100,5,0,L,0^A0N,44^FD${order["Shipping Address"]?.replace(
          /\r\n/g,
          "\\&"
        )}^FS
        ^FT60,1080^FB1100,1,0,L,0^A0N,44^FDPO #: ${order["PO #"]}^FS
        ^FT60,1150^FB1100,1,0,L,0^A0N,44^FDShipping Method: ${
          order["Shipping Method"]
        }^FS
        ^FT60,1220^FB1100,1,0,L,0^A0N,44^FDProjected Pick Qty: ${
          order["Projected Pick Qty"]
        }^FS
        ^FT60,1340^FB1100,2,0,L,0^A0N,44^FDRelated Transfer Orders: ${
          order["Related Transfer Orders"]
        }^FS
        ^FT60,1600^FB1100,5,0,L,0^A0N,44^FDWarehouse Notes: ${
          order["Warehouse Notes"]
        }^FS
        ^FT60,1650^A0N,65^FDPALLET #:__________  of __________^FS
        ^FT60,1760^A0N,65^FDWEIGHT:________________________^FS
        ^PQ1,0,1,Y^XZ`;

        compiledZPLCode += code;
      }

      return compiledZPLCode;
    } catch (error) {
      throw error;
    }
  };

  const handleFilterToBePrinted = () => {
    if (apiRef.current) {
      if (isFiltered) {
        // Reset filter
        apiRef.current.setFilterModel({ items: [] });
      } else {
        // Apply filter
        apiRef.current.setFilterModel({
          items: [
            {
              field: "Warehouse Order Status",
              operator: "contains",
              value: "To Be Printed",
            },
          ],
        });
      }
      setIsFiltered(!isFiltered);
    }
  };

  return (
    <GridToolbarContainer sx={{ p: 0 }}>
      <FlexWrapper
        width="100%"
        justifyContent="space-between"
        alignItems="stretch"
      >
        {headerValues?.length
          ? headerValues.map((header) => (
              <Box
                key={header.headerName}
                color="white"
                bgcolor={header.bgColor}
                textAlign="right"
                flex={1}
                p={1}
              >
                <Stack>
                  <Typography variant="h6">{header.headerName}</Typography>
                  <Typography variant="body2">{header.headerValue}</Typography>
                </Stack>
              </Box>
            ))
          : null}
      </FlexWrapper>

      <FlexWrapper gap={2} flexDirection="column" width="100%">
        <FlexWrapper
          width="100%"
          justifyContent="space-between"
          gap={1}
          alignItems="stretch"
        >
          <FlexWrapper>
            <Typography px={1}>
              <strong>Selected Orders: </strong> {convertedArray?.length}
            </Typography>
            <Typography>
              <strong>Selected Pick Qty: </strong>{" "}
              {totalProjectedPickQtySelected?.toString()}
            </Typography>
          </FlexWrapper>

          {convertedArray.length > 0 && (
            <FlexWrapper gap={1}>
              <StockOrdersAssignPickersModal
                selectedOrders={convertedArray}
                pickers={whsePickers}
                getOrderdata={getOrderdata}
                setLoading={setLoading}
                totalPickQty={totalProjectedPickQtySelected}
                handleUpdateNetSuiteOrder={handlePrintOrdersAndAssign}
                type="print-and-assign"
              />

              <StockOrdersAssignPickersModal
                selectedOrders={convertedArray}
                pickers={whsePickers}
                getOrderdata={getOrderdata}
                setLoading={setLoading}
                totalPickQty={totalProjectedPickQtySelected}
                handleUpdateNetSuiteOrder={handleUpdateNetSuiteOrder}
                type="assign"
              />

              <Button
                disabled={convertedArray.length === 0}
                sx={{ mr: 1 }}
                size="small"
                variant="contained"
                endIcon={<Print />}
                onClick={handlePrintOrdersAndAssign}
              >
                Print Orders
              </Button>
            </FlexWrapper>
          )}
        </FlexWrapper>

        <FlexWrapper width="100%" gap={3} alignItems="center">
          <GridToolbar
            showQuickFilter
            csvOptions={{ fileName: `stock-orders-${Date.now()}` }}
            printOptions={{ disableToolbarButton: true }}
            sx={{ pl: 1 }}
          />
          {rolelevel !== 4 && (
            <Button
              size="small"
              variant="contained"
              onClick={() => handleFilterToBePrinted()}
            >
              {isFiltered ? "Reset Filter" : "Filter To Be Printed"}
            </Button>
          )}
          <StockOrdersInfoModal />
        </FlexWrapper>
      </FlexWrapper>
    </GridToolbarContainer>
  );
}

const OrderProcessing = () => {
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState("Loading Stock Orders...");
  const apiRef = useGridApiRef();
  const { currentNetsuiteLocationIds, currentSubsidiary, rolelevel } =
    useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();

  const columns =
    currentSubsidiary === "WGE"
      ? ORDERPROCESSINGCOLUMNSWGE
      : ORDERPROCESSINGCOLUMNS;

  useEffect(() => {
    getOrderdata();
  }, []);

  const getOrderdata = async () => {
    try {
      const nsOrdersResponse = await axios.get(
        `netsuite/order-processing/get/orders?locations=${JSON.stringify(
          currentNetsuiteLocationIds
        )}&subsidiary=${currentSubsidiary}`
      );

      if (rolelevel === 4 && nsOrdersResponse?.data?.length) {
        setOrderData(
          nsOrdersResponse.data.filter(
            (data) =>
              !data["Warehouse Order Status"]?.includes("To be Printed") &&
              data["Warehouse Order Status"] !== "Printed"
          )
        );

        return;
      }

      setOrderData(nsOrdersResponse.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not get data: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  const getNewRowsClassName = (params) => {
    if (currentSubsidiary === "AIT") {
      if (
        params.row["Customer"] === "O'REILLY AUTOMOTIVE LLC" &&
        parseFloat(params.row["Projected Fill Rate %"]?.replace("%", "")) /
          100 <
          0.93
      ) {
        return "highlight";
      } else {
        return "";
      }
    }
  };

  if (loading) {
    return <Loading message={loading} />;
  }

  return (
    <Box p={1}>
      <DataGrid
        disableRowSelectionOnClick
        apiRef={apiRef}
        checkboxSelection={
          orderData?.length && currentSubsidiary === "AIT" && rolelevel !== 4
        }
        sx={{
          height: "89vh",
          "& .highlight": {
            backgroundColor: "yellow", // Custom highlight style
            color: "black", // Optional: Change text color
          },
        }}
        slots={{
          toolbar:
            currentSubsidiary === "WGE"
              ? GridToolbar
              : () => (
                  <CustomToolbar
                    setLoading={setLoading}
                    getOrderdata={getOrderdata}
                  />
                ),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: { fileName: `stock-orders-${Date.now()}` },
            printOptions: { disableToolbarButton: true },
          },
        }}
        initialState={
          currentSubsidiary === "WGE"
            ? {}
            : {
                columns: {
                  columnVisibilityModel: {
                    // Hide columns status and traderName, the other columns will remain visible
                    ["Allocated Lines"]: false,
                  },
                },
              }
        }
        density="compact"
        rows={orderData}
        columns={columns}
        getRowClassName={getNewRowsClassName}
        getRowId={(row) => row["SO #"]?.replace(/<\/?[^>]+(>|$)/g, "")}
        isRowSelectable={(params) => {
          if (params.row["Related Transfer Orders"]) {
            if (
              !params.row["Fulfillment Fill Rate %"] &&
              params.row["Shipping Method"] !== "Will Call"
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }}
      />
    </Box>
  );
};
export default OrderProcessing;
