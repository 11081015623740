import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { READYTOSHIPCOLUMNS } from "../../../../utils/columns/shipping/shippingColumns";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Container, Typography } from "@mui/material";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";

const ReadyToShipOrders = () => {
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState("Loading Orders...");

  const { openAlert } = useGeneralAlert();
  const { currentNetsuiteLocationIds } = useSelector((state) => state.user);

  useEffect(() => {
    getOrderData();
  }, []);

  const getOrderData = async () => {
    try {
      const response = await axios.get(
        "netsuite/shipping/get/orders/ready-to-ship"
      );

      const locationFilteredData = response.data?.filter((order) =>
        currentNetsuiteLocationIds?.includes(
          parseInt(order["Location Internal ID"])
        )
      );
      setOrderData(locationFilteredData);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not get orders: ${
          error?.response?.data?.msg || error.messsage
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Ready To Ship Orders
      </Typography>

      <DataGrid
        sx={{ height: "80vh" }}
        columns={READYTOSHIPCOLUMNS}
        rows={orderData}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: { fileName: `ready-to-ship-orders-${Date.now()}` },
            printOptions: { disableToolbarButton: true },
          },
        }}
        getRowId={(row) => row["Document Number"]}
      />
    </Container>
  );
};

export default ReadyToShipOrders;
