import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { printGeneralV2 } from "../../../../utils/printer-functions/printGeneralV2";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { Print } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import Loading from "../../../../components/Loading";
import axios from "../../../../axios/axios.config";
import FlexWrapper from "../../../../components/FlexWrapper";
import GeneralModalV3 from "../../../../components/general/GeneralModalV3";

const ContainerLogPage = () => {
  const [containerData, setContainerData] = useState([]);
  const [containerStatuses, setContainerStatuses] = useState([]);
  const [filter, setFilter] = useState("All");
  const [loading, setLoading] = useState("");

  const { currentSubsidiary, currentLocation } = useSelector(
    (state) => state.user
  );
  const { currentPrinter } = useSelector((state) => state.printers);
  const { openAlert } = useGeneralAlert();

  const filteredContainers =
    filter !== "All"
      ? containerData.filter((data) => data.Status === filter)
      : containerData;

  useEffect(() => {
    getContainerData();
  }, []);

  const getContainerData = async () => {
    setLoading("Loading Container Data...");
    const response = await axios.get("netsuite/container-log/get/containers");

    if (response.data?.length) {
      const filteredContainers = response.data.filter(
        (data) =>
          data["Subsidiary"] === currentSubsidiary &&
          data["Warehouse Location"] === currentLocation
      );

      const statuses = ["All"];
      for (const container of filteredContainers) {
        const status = container.Status;
        if (!statuses?.includes(status)) {
          statuses.push(status);
        }
      }

      setContainerStatuses(statuses);
      setContainerData(filteredContainers);
    }

    setLoading("");
  };

  const receiveContainer = async ({ containerId, name }) => {
    setLoading(`Receiving Container ${name}...`);

    try {
      const response = await axios.post(
        "netsuite/container-log/post/receive-container",
        { containerId }
      );

      openAlert({
        type: "success",
        message: response.data,
        duration: 10000,
      });

      await new Promise((resolve) => setTimeout(resolve, 5000));
      await getContainerData();

      if (loading) {
        setLoading("");
      }
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Receive Container ${name}: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
      setLoading("");
    }
  };

  const printContainerLabels = async ({ containerId }) => {
    try {
      openAlert({
        type: "success",
        message: "Printing Labels...",
        duration: 2000,
      });

      const response = await axios.post(
        "netsuite/container-log/post/print-labels",
        { containerId }
      );

      await printGeneralV2({
        currentPrinter,
        template: response.data.labelsToPrint || "",
        title: `Print Container Labels: ${response.data.container}`,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Print Labels: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container sx={{ pb: 1 }} maxWidth="md">
      <Typography variant="h4" textAlign="center">
        Container Log
      </Typography>

      {Boolean(containerStatuses?.length) && (
        <>
          <Typography component="p" variant="caption" textAlign="right">
            Total: {filteredContainers?.length}
          </Typography>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={filter}
              onChange={(event) => setFilter(event.target.value)}
              label="Status"
            >
              {containerStatuses?.map((status) => (
                <MenuItem
                  sx={{ textTransform: "capitalize" }}
                  key={status}
                  value={status}
                >
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}

      {containerData?.length ? (
        <>
          <Grid container spacing={2}>
            {filteredContainers.map((item) => (
              <Grid item xs={12} key={item["Container #"]}>
                <Card
                  variant="outlined"
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #e0e0e0",
                  }}
                >
                  <CardContent style={{ padding: "16px" }}>
                    <FlexWrapper
                      alignItems="center"
                      gap={1}
                      justifyContent="center"
                    >
                      {" "}
                      <Typography
                        fontWeight="bold"
                        variant="h6"
                        sx={{ fontWeight: 500 }}
                        textAlign="center"
                      >
                        {item["Container #"]}
                      </Typography>
                      {(item["Status"] === "Received in NetSuite" ||
                        item["Status"] === "Error Receiving in NetSuite") && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            printContainerLabels({
                              containerId: item["Internal ID"],
                            })
                          }
                        >
                          <Print color="primary" />
                        </IconButton>
                      )}
                    </FlexWrapper>
                    <Typography
                      variant="subtitle1"
                      textAlign="center"
                      sx={{ pb: 1 }}
                    >
                      {item["Shipment #"]}
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: "4px" }}>
                      <strong>Scheduled Date:</strong> {item["Scheduled Date"]}
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: "4px" }}>
                      <strong>Date Received:</strong> {item["Date Received"]}
                    </Typography>

                    <Typography variant="body2" style={{ marginBottom: "4px" }}>
                      <strong>Status:</strong> {item["Status"]}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        marginBottom: "4px",
                        fontWeight: item.Highlight === "1" ? "bold" : "normal",
                        backgroundColor:
                          item.Highlight === "1" ? "#fff9c4" : "inherit", // Light yellow background
                        padding: item.Highlight === "1" ? "2px 4px" : "0", // Adds padding for readability
                      }}
                    >
                      <strong>Purchase Orders:</strong>{" "}
                      {item["Purchase Orders"]}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: "4px" }}>
                      <strong>Receiving Bins:</strong> {item["Receiving Bins"]}
                    </Typography>
                  </CardContent>
                  {/* Check status and then check if highlight is 0 and receivinng bins is empty */}
                  {(item["Status"] === "Error Receiving in NetSuite" ||
                    item["Status"] === "Received at Warehouse" ||
                    item["Status"] === "En Route to Final Destination") &&
                    !(
                      item.Highlight === "0" && !Boolean(item["Receiving Bins"])
                    ) && (
                      <CardActions>
                        <GeneralModalV3
                          openComponentWidth="100%"
                          openComponent={
                            <Button
                              fullWidth
                              variant="contained"
                              color="success"
                              sx={{ fontWeight: "bold", width: "100%" }}
                            >
                              Receive Container
                            </Button>
                          }
                        >
                          <Box>
                            <Typography
                              fontWeight="bold"
                              textAlign="center"
                              variant="h4"
                              py={1}
                            >
                              {item["Container #"]}
                            </Typography>
                            <Divider />
                            <Typography p={2} variant="h6">
                              Are you sure you want to receive this container?
                            </Typography>
                            <Divider />
                            <Box p={2}>
                              <Button
                                onClick={() =>
                                  receiveContainer({
                                    name: item["Container #"],
                                    containerId: item["Internal ID"],
                                  })
                                }
                                color="success"
                                variant="contained"
                                fullWidth
                              >
                                Receive
                              </Button>
                            </Box>
                          </Box>
                        </GeneralModalV3>
                      </CardActions>
                    )}
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="60vh"
        >
          <Typography variant="h6" color="textSecondary" align="center">
            No data available to display
          </Typography>
        </Box>
      )}
    </Container>
  );
};
export default ContainerLogPage;
