import { useEffect, useMemo, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { handleErrorBeep } from "../../../utils/helper-functions/general/errors";
import { CheckCircle } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";

import ReceiveTransactionItemView from "./ReceiveTransactionItemView";
import axios from "../../../axios/axios.config";
import FlexWrapper from "../../FlexWrapper";
import ReceiveTransactionErrorModal from "./ReceiveTransactionErrorModal";

const ReceiveTransactionScanView = ({
  transactionData: {
    items,
    _id,
    totalitemstoreceive,
    totalreceived,
    internalid,
    trantype,
    receiveditems,
    locationinternalid,
    tranid,
    condition,
    tranref,
    soreference,
  },
  setTransactionData,
  bottomNavigationValue,
}) => {
  const [qty, setQty] = useState(1);
  const [itemInput, setItemInput] = useState("");
  const [inputError, setInputError] = useState(false);

  const { dispatchAlert, username, handleItemReceipt } = useOutletContext();
  const itemInputRef = useRef(null);

  const MemoizedItemView = useMemo(
    () => (
      <ReceiveTransactionItemView
        items={items}
        handleItemRefFocus={handleItemRefFocus}
      />
    ),
    [items]
  );

  useEffect(() => {
    if (bottomNavigationValue === "remaining") {
      handleItemRefFocus();
    }
  }, [bottomNavigationValue]);

  const handleReceieveItem = async (event) => {
    event.preventDefault();
    try {
      if (!qty || parseInt(qty) < 1) {
        throw new Error("Quantity must be greater than or equal to 1");
      }

      if (!itemInput) {
        throw new Error("Item value is required");
      }

      const foundItem = items.find((item) => {
        return (
          (item.lineitemname === itemInput ||
            item.lineitemupc === itemInput ||
            item.lineitemaddtlupccodes?.split("|")?.includes(itemInput)) &&
          parseInt(qty) <= parseInt(item.lineitemqtypendingreceipt)
        );
      });

      if (!foundItem) {
        throw new Error("Item not found or quantity exceeds needed quantity");
      }

      const item = foundItem;

      const receiveData = {
        qtyreceived: parseInt(qty),
        username,
        item: item.lineitemname,
        upc: item.lineitemupc,
        lineid: item.lineid,
        internalid: item.lineiteminternalid,
        timereceived: new Date().toISOString(),
        itemsid: item._id,
      };

      const response = await axios.patch(
        `receive-transaction/${_id}/receive-item/${item._id}`,
        {
          qtyReceived: parseInt(qty),
          receiveData,
        }
      );

      if (response.data) {
        setTransactionData(response.data);
      }

      setInputError("");
      handleItemRefFocus();
      dispatchAlert({
        type: "success",
        message: `Successfully received (${qty}) ${item.lineitemname}`,
        duration: 3000,
      });
    } catch (error) {
      setInputError(error?.response?.data?.msg || error?.message);
      handleErrorBeep();
    } finally {
      setQty(1);
      setItemInput("");
    }
  };

  //handles input focus (hides keyboard when focused)
  function handleItemRefFocus() {
    if (itemInputRef?.current !== null) {
      itemInputRef.current.readOnly = true;
      itemInputRef.current.focus();
      setTimeout(() => {
        itemInputRef.current.focus();
        itemInputRef.current.readOnly = false;
      });
    }
  }

  return (
    <Grid
      container
      pt={2}
      justifyContent="center"
      sx={{ width: "100%" }}
      component="form"
      onSubmit={handleReceieveItem}
    >
      {/* Error Modal */}
      <ReceiveTransactionErrorModal
        open={inputError}
        setInputError={setInputError}
        handleItemRefFocus={handleItemRefFocus}
      />

      <Grid item xs={12} justifyContent="center">
        <FlexWrapper gap={1}>
          <Typography component="p" variant="caption" sx={{ pb: 1 }}>
            {" "}
            Total: {totalitemstoreceive?.toLocaleString()}
          </Typography>
          <Typography component="p" variant="caption" sx={{ pb: 1 }}>
            {" "}
            Received: {totalreceived}
          </Typography>
        </FlexWrapper>
      </Grid>

      {totalitemstoreceive === totalreceived ? (
        <FlexWrapper height="20vh" alignItems="center">
          <Button
            size="large"
            variant="contained"
            color="success"
            endIcon={<CheckCircle />}
            onClick={() =>
              handleItemReceipt({
                id: internalid,
                mongoid: _id,
                receiveditems,
                trantype,
                locationinternalid,
                isPartial: false,
                tranid,
                condition,
                reference: tranref,
                soreference,
              })
            }
          >
            Complete Receive
          </Button>
        </FlexWrapper>
      ) : (
        <>
          <Grid item xs={2} md={1}>
            <TextField
              fullWidth
              required
              error={Boolean(inputError)}
              label="Qty"
              type="number"
              inputMode="numeric"
              inputProps={{ min: 1 }}
              value={qty}
              onChange={(event) => setQty(event.target.value)}
            />
          </Grid>

          <Grid item xs={10} md={6}>
            <TextField
              inputRef={itemInputRef}
              fullWidth
              required
              error={Boolean(inputError)}
              autoFocus
              label="Item"
              value={itemInput}
              onChange={(event) =>
                setItemInput(event.target.value.toUpperCase())
              }
            />
          </Grid>

          <Button type="submit" hidden sx={{ p: 0, display: "none" }} />
          {/* Item View */}
          <Grid item xs={12} md={7}>
            {MemoizedItemView}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ReceiveTransactionScanView;
