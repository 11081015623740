import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { Delete, Search } from "@mui/icons-material";
import {
  Container,
  Typography,
  TextField,
  Grid,
  Card,
  CardContent,
  InputAdornment,
  Box,
  Stack,
  LinearProgress,
  Link,
  IconButton,
  Button,
} from "@mui/material";

import axios from "../../../axios/axios.config";
import FlexWrapper from "../../../components/FlexWrapper";
import Loading from "../../../components/Loading";
import PickOrderAdminDeleteOrderModal from "../../../components/multi-page/pick-order-admin/PickOrderAdminDeleteOrderModal";

const PickViewOrdersPage = () => {
  const [search, setSearch] = useState("");
  const [orders, setOrders] = useState(null);
  const [isLoading, setIsLoading] = useState("");

  const { dispatchAlert } = useOutletContext();
  const { currentSubsidiary, currentNetsuiteLocationIds, rolelevel } =
    useSelector((state) => state.user);

  useEffect(() => {
    if (!orders) {
      getPickOrderData();
    }
  }, [orders]);

  const getPickOrderData = async () => {
    setIsLoading("Loading Orders...");
    try {
      const response = await axios.get(
        `pick-order/view-orders?subsidiary=${currentSubsidiary}&locations=${JSON.stringify(
          currentNetsuiteLocationIds
        )}&status=inprogress`
      );

      const finalOrders = [...response.data];

      for (const order of finalOrders) {
        const { pick, path } = order;
        const packStorage = {};

        if (!pick.length) {
          order.totalpicked = 0;
        } else {
          //calculate total picked
          order.totalpicked = pick.reduce(
            (acc, curr) => acc + (curr.binqtypicked || 0),
            0
          );
        }

        if (!path.length) {
          order.remaining = 0;
        } else {
          //calculate remaining
          order.remaining = path.reduce((acc, curr) => {
            let committed = 0;
            if (!packStorage[curr.lineid]) {
              packStorage[curr.lineid] = true;
              committed = curr.lineitemqtycommitted || 0;
            }
            return acc + parseInt(committed);
          }, 0);
        }
      }

      setOrders(finalOrders);
    } catch (error) {
      dispatchAlert({
        type: "error",
        message: `Could Not Get Pick Data, Please Refresh: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    } finally {
      setIsLoading("");
    }
  };

  const updateOrderStatus = async (id) => {
    try {
      setIsLoading("Updating Status...");
      await axios.patch(`pick-order/update/order/${id}/status`, {
        status: "fulfilled",
      });
      await getPickOrderData();
      dispatchAlert({
        type: "success",
        message: "Successfully updated status",
        duration: 2000,
      });
    } catch (error) {
      dispatchAlert({
        type: "error",
        message: `Could not update status: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
      setIsLoading("");
    }
  };

  if (isLoading || !orders) return <Loading message={isLoading} />;

  if (!orders?.length) {
    return (
      <Typography textAlign="center" variant="h4" pt={2}>
        No Orders Being Picked
      </Typography>
    );
  }

  const filteredOrders = search
    ? orders.filter(
        (order) =>
          order.tranid.includes(search.trim()) ||
          order.picklockedby.toUpperCase().includes(search.trim())
      )
    : orders;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Pick Orders In Progress
      </Typography>

      <Grid container alignItems="center">
        <Grid item xs={12} md={6} pb={1}>
          <TextField
            fullWidth
            size="small"
            label="Search"
            value={search}
            onChange={(event) => setSearch(event.target.value.toUpperCase())}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} pb={1}>
          <Typography textAlign="right">Total: {orders.length}</Typography>
        </Grid>
      </Grid>

      <Box sx={{ height: "75vh", overflow: "scroll", py: 1 }}>
        <Stack spacing={2} flexWrap="wrap" alignItems="center">
          {filteredOrders.length ? (
            filteredOrders.map((order) => (
              <Card variant="outlined" key={order._id} sx={{ width: "100%" }}>
                <CardContent>
                  <FlexWrapper
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Box>
                      {rolelevel <= 1 ? (
                        <FlexWrapper alignItems="baseline" gap={1}>
                          <Typography variant="caption">{order._id}</Typography>
                          {order.totalpicked === 0 ? (
                            <PickOrderAdminDeleteOrderModal
                              order={order}
                              setIsLoading={setIsLoading}
                              getPickOrderData={getPickOrderData}
                            />
                          ) : null}
                        </FlexWrapper>
                      ) : null}
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        gutterBottom
                        textAlign="center"
                        fontWeight="bold"
                      >
                        <Link
                          href={`https://${process.env.REACT_APP_NS_ACCOUNT_KEY}.app.netsuite.com/app/accounting/transactions/transaction.nl?id=${order.internalid}`}
                          target="_blank"
                        >
                          {order.tranid} - {order.brandpackaging}
                        </Link>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        textAlign="center"
                      >
                        {order.customer}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        textAlign="center"
                      >
                        PO#: {order.ponumber}
                      </Typography>
                    </Box>

                    <FlexWrapper alignItems="baseline" gap={1}>
                      <Typography
                        variant="caption"
                        gutterBottom
                        textAlign="right"
                      >
                        {order.status?.toUpperCase() === "INPROGRESS"
                          ? "IN PROGRESS"
                          : order.status?.toUpperCase()}
                      </Typography>
                      {order.status?.toUpperCase() === "INPROGRESS" &&
                      parseInt(order.remaining) === 0 &&
                      parseInt(rolelevel) === 0 ? (
                        <Button
                          variant="contained"
                          size="small"
                          color="success"
                          onClick={() => updateOrderStatus(order._id)}
                        >
                          Fulfill
                        </Button>
                      ) : null}
                    </FlexWrapper>
                  </FlexWrapper>

                  <Typography variant="body1" color="textSecondary">
                    <strong>Started By:</strong> {order.picklockedby}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Total Quantity to Pick:</strong>{" "}
                    {parseInt(order.remaining) + parseInt(order.totalpicked)}{" "}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Total Quantity Picked:</strong> {order.totalpicked}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Remaining Quantity To Pick:</strong>{" "}
                    {order.remaining}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Created At:</strong>{" "}
                    {new Date(order.createdAt)?.toLocaleString() || ""}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Last Update:</strong>{" "}
                    {new Date(order.updatedAt)?.toLocaleString()}
                  </Typography>

                  <Box mt={2}>
                    <FlexWrapper alignItems="center" flexWrap="nowrap">
                      <Box mr={2} sx={{ width: "100%" }}>
                        <LinearProgress
                          variant="determinate"
                          value={parseInt(
                            (
                              (order.totalpicked /
                                (parseInt(order.remaining) +
                                  parseInt(order.totalpicked))) *
                              100
                            ).toFixed(2)
                          )}
                          color="success"
                        />
                      </Box>

                      <Typography variant="body2" color="GrayText">{`${parseInt(
                        (
                          (order.totalpicked /
                            (parseInt(order.remaining) +
                              parseInt(order.totalpicked))) *
                          100
                        ).toFixed(2)
                      )}%`}</Typography>
                    </FlexWrapper>
                  </Box>
                </CardContent>
                {/* <CardActions>
                <FlexWrapper
                  justifyContent="flex-end"
                  width="100%"
                  alignItems="center"
                >
                  <Link to={`${order._id}`}>
                    <MaterialLink component="button" variant="button">
                      View Order Details{" "}
                      <span>
                        <Icon>
                          <ArrowForward fontSize="small" />
                        </Icon>
                      </span>
                    </MaterialLink>
                  </Link>
                </FlexWrapper>
              </CardActions> */}
              </Card>
            ))
          ) : (
            <Typography textAlign="center" variant="h6" pt={2}>
              No Orders Being Picked
            </Typography>
          )}
        </Stack>
      </Box>
    </Container>
  );
};

export default PickViewOrdersPage;
