import { useRef, useState } from "react";
import { useGeneralAlert } from "../../hooks/useGeneralAlert";
import { useSelector } from "react-redux";
import { printGeneralV2 } from "../../utils/printer-functions/printGeneralV2";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import axios from "../../axios/axios.config";
import FlexWrapper from "../../components/FlexWrapper";

const PrintItemLabels = () => {
  const { printer, currentSubsidiary } = useSelector((state) => state.user);
  const { currentPrinter } = useSelector((state) => state.printers);
  const { openAlert } = useGeneralAlert();
  const itemInputRef = useRef(null);

  const [input, setInput] = useState({
    item: "",
    brand: currentSubsidiary === "WGE" ? "TRAKMOTIVE-WGE" : "",
    printer,
    qty: 1,
    isBrownBox: true,
  });

  const handleChanges = (e) => {
    if (e.target.name === "item") {
      setInput({ ...input, [e.target.name]: e.target.value.toUpperCase() });
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    openAlert({
      type: "success",
      message: "Printing...",
      duration: 1000,
    });
    submitRequest(input);
    setInput({
      item: "",
      brand: input.brand,
      printer,
      qty: 1,
      isBrownBox: input.isBrownBox,
    });

    itemInputRef.current.inputMode = "none";
  };

  const submitRequest = async (input) => {
    try {
      //gets template from netsuite response
      const response = await axios.post(
        "netsuite/print-labels/warehouse",
        input
      );

      await printGeneralV2({
        currentPrinter,
        template: response.data.labelsToPrint || "",
        title: "Print Item Labels",
      });

      openAlert({
        type: "success",
        message:
          response.data.message || `Successfully Printed Labels for ${input}`,
        duration: 2000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message:
          error?.response?.data?.message || `Could Not Print: ${error.message}`,
        duration: 8000,
      });
    }
  };

  return (
    <Container sx={{ maxWidth: { xs: "xl", md: "md" } }}>
      <Typography component="h1" variant="h4" textAlign="center" py={2}>
        Print Item Labels
      </Typography>
      <form onSubmit={handleSubmit}>
        <FlexWrapper flexDirection="column" gap={2}>
          <TextField
            autoComplete="off"
            inputProps={{ autoComplete: "off" }}
            autoFocus
            fullWidth
            required
            inputRef={itemInputRef}
            inputMode="none"
            type="text"
            label="Item"
            name="item"
            value={input.item}
            onChange={handleChanges}
          />
          {currentSubsidiary === "WGE" ? (
            <FormControl fullWidth required>
              <InputLabel id="select-brand">Brand</InputLabel>
              <Select
                labelId="select-brand"
                id="select-brand-id"
                name="brand"
                label="brand"
                value={input.brand}
                onChange={handleChanges}
              >
                <MenuItem value="TRAKMOTIVE-WGE">TRAKMOTIVE</MenuItem>
                <MenuItem value="PLAIN-WGE">PLAIN</MenuItem>
                <MenuItem value="LPR">LPR</MenuItem>
                <MenuItem value="SKF">SKF</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <FormControl fullWidth required>
              <InputLabel id="select-brand">Brand</InputLabel>
              <Select
                labelId="select-brand"
                id="select-brand-id"
                name="brand"
                label="brand"
                value={input.brand}
                onChange={handleChanges}
              >
                <MenuItem value="TRAKMOTIVE">TRAKMOTIVE</MenuItem>
                <MenuItem value="TRAKMOTIVE-BW-LOGO">
                  TRAKMOTIVE-BW-LOGO
                </MenuItem>
                <MenuItem value="TRAKMOTIVE-W-MP">TRAKMOTIVE-W-MP</MenuItem>
                <MenuItem value="OREILLY">OREILLY</MenuItem>
                <MenuItem value="MPV">MPV</MenuItem>
                <MenuItem value="AUTOZONE">AUTOZONE</MenuItem>
                <MenuItem value="NAPA">NAPA</MenuItem>
                <MenuItem value="PLAIN">PLAIN</MenuItem>
                <MenuItem
                  value=""
                  disabled
                  sx={{ bgcolor: "rgba(25, 118, 210, 0.06)" }}
                >
                  ------------------------
                </MenuItem>
                <MenuItem value="SET">SET</MenuItem>
                <MenuItem value="NISSAN">NISSAN</MenuItem>
                <MenuItem value="INFINITI">INFINITI</MenuItem>
                <MenuItem
                  value=""
                  disabled
                  sx={{ bgcolor: "rgba(25, 118, 210, 0.06)" }}
                >
                  ------------------------
                </MenuItem>
                <MenuItem value="ALTUS AUTOMOTIVE">ALTUS AUTOMOTIVE</MenuItem>
                <MenuItem value="PARTS MOUNTAIN">PARTS MOUNTAIN</MenuItem>
                <MenuItem
                  value=""
                  disabled
                  sx={{ bgcolor: "rgba(25, 118, 210, 0.06)" }}
                >
                  ------------------------
                </MenuItem>
                <MenuItem value="FVP">FVP</MenuItem>
                <MenuItem value="PARTS MASTER">PARTS MASTER</MenuItem>
                <MenuItem value="AUTOMATIC DISTRIBUTORS">
                  AUTOMATIC DISTRIBUTORS
                </MenuItem>
              </Select>
            </FormControl>
          )}

          <TextField
            fullWidth
            required
            disabled
            type="text"
            defaultValue={input.printer}
          />
          <TextField
            fullWidth
            required
            type="number"
            inputMode="numeric"
            label="Print Quantity"
            name="qty"
            value={input.qty}
            onChange={handleChanges}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={input.isBrownBox}
                onChange={() => {
                  setInput({ ...input, isBrownBox: !input.isBrownBox });
                }}
                inputProps={{ "aria-label": "brownbox checkbox" }}
              />
            }
            label="Brown Box"
          />
          <Button
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            sx={{ py: 1 }}
          >
            {"Print Label"}
          </Button>
        </FlexWrapper>
      </form>
    </Container>
  );
};

export default PrintItemLabels;
