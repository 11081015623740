import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Stack,
  Typography,
} from "@mui/material";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";
import FlexWrapper from "../../../../components/FlexWrapper";

const WillCallOrdersPage = () => {
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState("Loading Will Call Orders...");

  const {
    currentNetsuiteLocationIds,
    currentSubsidiary,
    currentDepartment,
    username,
  } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();
  const navigate = useNavigate();

  useEffect(() => {
    getWillCallOrders();
  }, []);

  const getWillCallOrders = async () => {
    try {
      const nsResponse = await axios.get(
        `netsuite/order-processing/get/will-call-orders?locations=${JSON.stringify(
          currentNetsuiteLocationIds
        )}&subsidiary=${currentSubsidiary}`
      );
      const classStorage = new Set();

      for (const order of nsResponse.data) {
        classStorage.add(order["Status"]);
      }
      setOrderData(nsResponse.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Get Orders: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="lg" sx={{ pb: 1 }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Will Call Orders
      </Typography>

      {orderData.length ? (
        <>
          <FlexWrapper alignItems="baseline" justifyContent="flex-end">
            <Typography variant="caption" textAlign="center" gutterBottom>
              <strong>Total Orders:</strong> {orderData.length}
            </Typography>
          </FlexWrapper>
          <Stack sx={{ pt: 1 }} spacing={2}>
            {orderData?.map((order) => (
              <Card key={order["SO #"]} style={{ borderRadius: 8 }}>
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    textAlign="center"
                    fontWeight="bold"
                  >
                    {order["SO #"]} - {order["Brand Packaging"]}
                  </Typography>
                  <Typography gutterBottom textAlign="center">
                    {order["Customer"]}
                  </Typography>
                  <Typography variant="body2" gutterBottom textAlign="center">
                    PO #: {order["PO #"]}
                  </Typography>

                  <Typography variant="body1" pt={1}>
                    <strong>Date: </strong> {order.Date}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Total Qty: </strong> {order["Total Qty"]} (Fill
                    Rate: {order["Projected Fill Rate %"]})
                  </Typography>
                  <Typography variant="body1">
                    <strong>Shipping Method: </strong>{" "}
                    {order["Shipping Method"]}
                  </Typography>

                  {Boolean(order["Related Transfer Orders"]) ? (
                    order["Pick Locked By"] !== "" &&
                    order["Pick Locked By"] !== username ? (
                      <Typography variant="body1">
                        <strong>Related Transfer Orders: </strong>{" "}
                        {order["Related Transfer Orders"]}
                      </Typography>
                    ) : (
                      <>
                        <FlexWrapper gap={1}>
                          <Typography>
                            <strong>Related Transfer Orders: </strong>{" "}
                          </Typography>

                          {order["Related Transfer Orders"]
                            ?.split("|")
                            .map((toOrder) => {
                              return (
                                <Link
                                  key={toOrder}
                                  to={`/portal/${currentDepartment?.toLowerCase()}/pick-order?order=${toOrder?.trim()}&redirect=/portal/${currentDepartment?.toLowerCase()}/pick-to-so?order=${toOrder?.trim()}&original-page=/portal/${currentDepartment?.toLowerCase()}/will-call-orders`}
                                >
                                  <Typography> {toOrder}</Typography>
                                </Link>
                              );
                            })}
                        </FlexWrapper>
                      </>
                    )
                  ) : null}
                  <Typography variant="body1">
                    <strong>Warehouse Order Status: </strong>{" "}
                    {order["Warehouse Order Status"]}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Warehouse Notes: </strong>{" "}
                    {order["Warehouse Notes"]}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    disabled={Boolean(
                      (order["Pick Locked By"] !== "" &&
                        order["Pick Locked By"] !== username) ||
                        (order["Related Transfer Orders"] &&
                          !order["Fulfillment Fill Rate %"])
                    )}
                    variant="contained"
                    onClick={() =>
                      navigate(
                        `/portal/${currentDepartment?.toLowerCase()}/pick-order?order=${
                          order["SO #"]
                        }&redirect=/portal/${currentDepartment?.toLowerCase()}/will-call-orders`
                      )
                    }
                  >
                    Pick Order
                  </Button>
                </CardActions>
                <Typography
                  variant="body2"
                  color="error"
                  textAlign="center"
                  pb={1}
                  textTransform="capitalize"
                >
                  {order["Pick Locked By"] !== "" &&
                  order["Pick Locked By"] !== username
                    ? `Order already being picked by ${order["Pick Locked By"]}`
                    : ""}
                </Typography>
                <Typography
                  variant="body2"
                  color="error"
                  textAlign="center"
                  pb={1}
                  textTransform="capitalize"
                >
                  {order["Related Transfer Orders"] &&
                  !order["Fulfillment Fill Rate %"]
                    ? "Transfer Order must be picked First"
                    : ""}
                </Typography>
              </Card>
            ))}
          </Stack>
        </>
      ) : (
        <Typography variant="h6" textAlign="center" py={2}>
          No Orders To Display
        </Typography>
      )}
    </Container>
  );
};
export default WillCallOrdersPage;
