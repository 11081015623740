import { useState } from "react";
import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { handlePrintContentLabels } from "../../../../utils/printer-functions/printContentLabels";
import { printPickOrderPalletLabel } from "../../../../utils/printer-functions/printPickOrderPalletLabel";
import { resizeFile } from "../../../../utils/files/resizeFile";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { printGeneralV2 } from "../../../../utils/printer-functions/printGeneralV2";
import {
  ArrowBackIos,
  ArrowForwardIos,
  ExitToApp,
  Print,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import Loading from "../../../Loading";
import axios from "../../../../axios/axios.config";
import FlexWrapper from "../../../FlexWrapper";
import EditDiscrepanciesModal from "../../../warehouse/pack-process/update-pack-order/EditDiscrepanciesModal";
import ViewAWSImages from "../../../warehouse/pack-process/update-pack-order/ViewAWSImages";
import GeneralFileUpload from "../../../GeneralFileUpload";
import GeneralModalV3 from "../../../general/GeneralModalV3";

const UpdatePackResultsV2 = ({ data }) => {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const { openAlert } = useGeneralAlert();
  const { orderid } = useParams();
  const { username, currentDepartment, currentLocationInternalId } =
    useSelector((state) => state.user);
  const { currentPrinter } = useSelector((state) => state.printers);

  const [palletData, setPalletData] = useState(data?.packDetails);
  const [currentPalletIndex, setCurrentPalletIndex] = useState(0);
  const [currentBoxIndex, setCurrentBoxIndex] = useState(0);
  const [loading, setLoading] = useState("");
  const [discrepancies, setDiscrepanices] = useState(data?.discrepancies || 0);

  const currentPallet = palletData[currentPalletIndex];
  const currentImages = palletData[currentPalletIndex]?.images;
  const currentBox = currentPallet?.boxes?.length
    ? currentPallet?.boxes[currentBoxIndex]
    : null;

  if (navigation.state === "loading") {
    return <Loading message="Navigating..." />;
  }

  if (loading) {
    return <Loading message={loading} />;
  }

  const handleSaveFiles = async (files) => {
    try {
      const resizedFiles = [];
      for (const f of files) {
        const resizedFile = await resizeFile({
          file: new File(
            [f],
            `Pallet_${currentPallet?.palletId}_${Date.now()}.`,
            {
              type: f.type,
              lastModified: f.lastModified,
            }
          ),
          maxWidth: 2500,
          maxHeight: 2500,
          compressFormat: "jpeg",
          quality: 80,
          outputType: "file",
        });
        resizedFiles.push(resizedFile);
      }
      const newPalletData = [...palletData];
      //update images for current pallet
      newPalletData[currentPalletIndex].newImages = [
        ...newPalletData[currentPalletIndex].newImages,
        ...resizedFiles,
      ];

      newPalletData[currentPalletIndex].isupdated = true;
      setPalletData(newPalletData);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Error: ${error.message}`,
        duration: 3000,
      });
    }
  };

  const handleRemoveFile = (file) => {
    const newPalletData = [...palletData];
    //update images for current pallet
    newPalletData[currentPalletIndex].newImages = newPalletData[
      currentPalletIndex
    ].newImages.filter((fileObj) => fileObj.name !== file.name);

    setPalletData(newPalletData);
  };

  const handleInputChanges = (event, type = "pallet") => {
    const newData = [...palletData];
    if (type === "pallet") {
      if (
        event.target.name === "discrepancies" ||
        event.target.name === "weight"
      ) {
        newData[currentPalletIndex][event.target.name] = parseInt(
          event.target.value
        );
      } else {
        newData[currentPalletIndex][event.target.name] = event.target.value;
      }
    } else {
      //updating box
      if (event.target.name === "weight") {
        newData[currentPalletIndex].boxes[currentBoxIndex][event.target.name] =
          parseInt(event.target.value);
      } else {
        newData[currentPalletIndex].boxes[currentBoxIndex][event.target.name] =
          event.target.value;
      }
    }
    newData[currentPalletIndex].isupdated = true;
    setPalletData(newData);
  };

  const handlePrevPallet = () => {
    if (currentPalletIndex === 0) return;
    setCurrentPalletIndex(currentPalletIndex - 1);
    setCurrentBoxIndex(0);
  };

  const handleNextPallet = () => {
    if (currentPalletIndex === data?.packDetails?.length - 1) return;
    setCurrentPalletIndex(currentPalletIndex + 1);
    setCurrentBoxIndex(0);
  };

  //will update mongo and update netsuite
  const handleUpdatePalletData = async () => {
    setLoading("Updating Pallet Details...");

    const updatedPallets = palletData.filter((pallet) => pallet.isupdated);
    let errorPallet = null;

    try {
      let response = null;
      //if no new updates, just pull latest from mongo and update netsuite
      if (!updatedPallets.length) {
        response = await axios.get(`pack-process-v2/${orderid}?populate=false`);
      } else {
        //updates were made
        const newMongoData = [];
        //loop through updated pallets and check rquirements and save files to S3
        for (let i = 0; i < palletData.length; i++) {
          const currentPalletObj = palletData[i];
          errorPallet = i;

          if (currentPalletObj.isupdated) {
            if (!currentPalletObj?.weight || currentPalletObj?.weight <= 1) {
              throw new Error(
                `Incorrect weight for Pallet ${currentPalletObj?.palletId}`
              );
            }

            if (!currentPalletObj?.dimensions) {
              throw new Error(
                `Pallet ${currentPalletObj?.palletId} is missing required fields:  Dimensions`
              );
            }
            //only errors out when images are required. Only checks pallets that are updated by user, others ignored
            if (
              data?.isImagesRequired === "T" &&
              currentPalletObj?.images?.length +
                currentPalletObj?.newImages?.length <
                4
            ) {
              throw new Error(
                `Pallet ${currentPalletObj?.palletId} requires at least 4 images`
              );
            }

            //Save File to S3
            const imageKeys = [];
            const formData = new FormData();
            const folder = `warehouse/packprocess/palletimages/${data?.order}/`;
            formData.append("folder", folder);

            if (currentPalletObj?.newImages.length) {
              currentPalletObj?.newImages.forEach((file) => {
                const imageKey = `${file.name}`;
                //will store keys in mongo to generate URLs once saved
                imageKeys.push(folder + imageKey);
                formData.append("files", file);
              });

              //upload images to s3
              await axios.post("/files/upload/multi", formData, {
                headers: { "Content-Type": "multipart/form-data" },
              });
            }

            const palletUpdate = {
              ...currentPalletObj,
              weight: currentPalletObj?.weight,
              dimensions: currentPalletObj?.dimensions,
              images: [...currentPalletObj.images, ...imageKeys],
            };
            delete palletUpdate.newImages;
            delete palletUpdate.isupdated;

            newMongoData.push(palletUpdate);
          } else {
            newMongoData.push(currentPalletObj);
          }
        }

        //update mongo with new mongo Data
        response = await axios.patch(
          `pack-process-v2/update-pack-order/update-details/${orderid}/`,
          { newMongoData }
        );
      }

      if (!response)
        throw new Error("Could not get order data. Please try again.");

      //Use Updated data to make netsuite request
      //check if tran packages record exists
      const tranRecordCheckResponse = await axios.get(
        `netsuite/pack-process/get/transaction-packages/${response.data?.createdfrom}/${response.data?.shipmentsequence}`
      );

      const tranPckgData = tranRecordCheckResponse.data;
      //only delete if tran packages exist
      if (tranPckgData.count) {
        const packageId = tranPckgData.items[0].id;
        await axios.delete(
          `netsuite/pack-process/delete/transaction-packages/${packageId}`
        );
      }

      //create tran packages record (this is where we set header fields)
      const createTranPackageResponse = await axios.post(
        "netsuite/pack-process/create/transaction-packages",
        {
          name: response.data?.createdfrom,
          username,
          shipSequence: response.data?.shipmentsequence,
          discrepancies: response.data?.discrepancies,
        }
      );
      //tran packages record id
      const recordid = createTranPackageResponse.data?.recordid;

      //create the pallets linked to new recordid (set pallet level fields)
      for (const pallet of response.data?.packdetails) {
        await axios.post(
          "netsuite/pack-process/create/transaction-packages/pallet",
          {
            recordid, //Internal ID of the transaction packages record created in previous step
            palletnumber: pallet?.palletId, //Pallet Number as Integer
            palletweight: parseFloat(pallet?.weight), //Pallet Weight as Float
            palletdim: pallet?.dimensions, //Pallet Dimensions as String
          }
        );
      }

      //send a request to force tran packages page to update on netsuite to build tran packages breakdown
      await axios.patch("netsuite/pack-process/update/transaction-packages", {
        recordid,
      });

      navigate(`/portal/${currentDepartment?.toLowerCase()}/update-pack-order`);
      openAlert({
        type: "success",
        message: `Successfully Updated ${data?.order}`,
        duration: 5000,
      });
    } catch (error) {
      if (errorPallet !== null) {
        setCurrentPalletIndex(errorPallet);
      }
      openAlert({
        type: "error",
        message: `Could Not Update Data: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 10000,
      });
    } finally {
      setLoading("");
    }
  };
  //prints single pallet label
  const printContentLabels = async (pallet = null) => {
    //old template 203dpi
    // const templateHeader = `^XA^MMT^PW812^LL1218^LS0
    // ^FX
    // ^FT0,85^FB800,1,0,C,0^A0N,75^FDCONTENTS^FS
    // ^FT0,220^FB800,1,0,C,0^A0N,150^FD${data?.order}^FS
    // ^FT0,300^FB800,1,0,C,0^A0N,75^FDPO#: ${data?.ponumber}^FS
    // ^FT10,375^FB800,1,0,C,0^A0N,50^FDPALLET: ${
    //   currentPallet?.palletId < 9
    //     ? `0${currentPallet?.palletId}`
    //     : currentPallet?.palletId
    // }^FS
    // ^FT0,400^GB820,1,4,B^FS
    // ^FT0,450^FB125,1,0,C,0^A0N,30^FDQuantity^FS
    // ^FT125,450^FB300,1,0,L,0^A0N,30^FDPart Number^FS
    // ^FT425,450^FB125,1,0,C,0^A0N,30^FDQuantity^FS
    // ^FT550,450^FB300,1,0,L,0^A0N,30^FDPart Number^FS
    // ^FX`;

    const currentPalletData = pallet ? pallet : currentPallet;

    //new template 300dpi
    const templateHeader = `^XA
^MMT^PW1200^LL1800^LS0^POI^LH0,0    
^FT0,125^FB1175,1,0,C,0^A0N,115^FDCONTENTS^FS
^FT0,325^FB1175,1,0,C,0^A0N,225^FD${data?.order}^FS
^FT0,443^FB1175,1,0,C,0^A0N,85^FDPO#: ${data?.ponumber}^FS
^FT15,554^FB1175,1,0,C,0^A0N,75^FDPALLET: ${
      currentPalletData?.palletId < 9
        ? `0${currentPalletData?.palletId}`
        : currentPalletData?.palletId
    }^FS
^FT0,591^GB1212,1,6,B^FS
^FT0,665^FB185,1,0,C,0^A0N,45^FDQuantity^FS
^FT185,665^FB443,1,0,L,0^A0N,45^FDPart Number^FS
^FT628,665^FB185,1,0,C,0^A0N,45^FDQuantity^FS
^FT813,665^FB443,1,0,L,0^A0N,45^FDPart Number^FS`;

    const currentPalletItems = currentPalletData?.items;
    const currentPalletBoxes = currentPalletData?.boxes;
    let finalItems = [];

    if (currentPalletItems.length) {
      finalItems = [...currentPalletItems];
    }

    if (currentPalletBoxes?.length) {
      currentPalletBoxes.map((boxObj) => {
        if (boxObj.items.length) {
          finalItems = [...finalItems, ...boxObj.items];
        }
      });
    }

    if (!pallet) {
      openAlert({
        type: "success",
        message: `Printing Pallet ${currentPalletData?.palletId} Content Labels...`,
        duration: 2000,
      });
    }

    try {
      const zpl = await handlePrintContentLabels({
        templateHeader,
        currentPrinter,
        startingArr: finalItems,
        title: `Print Content Labels for Pallet ${currentPalletData?.palletId}-${data?.order}`,
        type: !pallet ? "print" : "zpl",
      });
      return zpl;
    } catch (error) {
      openAlert({
        type: "error",
        message: error?.response?.data?.msg || error.message,
        duration: 5000,
      });
    }
  };
  //print pick ticket first then print all pallet contents labels
  const printWholeOrderPallet = async () => {
    try {
      //final zpl code to send to printnode
      let finalZPLCode = "";

      openAlert({
        type: "success",
        message: "Printing All Labels...",
        duration: 2000,
      });

      //get data from pick order
      const response = await axios.get(
        `pick-order/get/order-number/${data.order}?shipmentsequence=${data.shipmentsequence}`
      );
      const pickOrder = response.data?.length ? response.data[0] : null;

      if (pickOrder) {
        //return pick label zpl first
        const pickZPL = await printPickOrderPalletLabel({
          currentPrinter,
          tranid: pickOrder.tranid,
          username,
          brand: pickOrder.brandpackaging,
          shippingAddress: pickOrder.shippingaddress,
          poNumber: pickOrder.ponumber,
          shipMethod: pickOrder.shipmethod,
          warehouseNotes: pickOrder.warehousenotes,
          alertFn: openAlert,
          title: "Printing Pick Label (From Update Pack)",
          currentLocationInternalId,
          pickQty: pickOrder.projectedpickqty,
          relatedtransferorders: pickOrder.relatedtransferorders,
          mustshipwith: pickOrder.mustshipwith,
          type: "zpl", //returns zpl code instead of printing
        });

        finalZPLCode += pickZPL;
      } else {
        openAlert({
          type: "warning",
          message: "Could Not Print Pick Label. Pick Order Not Found.",
          duration: 4000,
        });
      }

      //print pallet contents for each pallet
      for (const pallet of palletData) {
        const zplCode = await printContentLabels(pallet);
        finalZPLCode += zplCode;
      }

      await printGeneralV2({
        currentPrinter,
        template: finalZPLCode,
        title: `Print All Labels Fn ${data.order} - Update Pack Order Page`,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Print Order Labels: ${
          error?.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h5" textAlign="center" gutterBottom>
        {data?.order} - {data?.brand}
      </Typography>

      <FlexWrapper alignItems="center" justifyContent="space-between" gap={1}>
        <FlexWrapper>
          <GeneralModalV3
            openComponent={
              <IconButton>
                <ExitToApp color="error" />
              </IconButton>
            }
          >
            {(handleClose) => (
              <Box>
                <Typography textAlign="center" variant="h5" p={1}>
                  Exit Page?
                </Typography>
                <Divider />
                <Typography fontWeight="bold" textAlign="center" py={2}>
                  Make sure you have submitted data before exiting. All unsaved
                  data will be lost.
                </Typography>
                <Divider />
                <FlexWrapper justifyContent="flex-end">
                  <Button
                    sx={{ mt: 1, mr: 1 }}
                    size="large"
                    color="error"
                    variant="contained"
                    onClick={() => {
                      handleClose();
                      navigate(
                        `/portal/${currentDepartment?.toLowerCase()}/update-pack-order`
                      );
                    }}
                  >
                    Exit
                  </Button>
                </FlexWrapper>
              </Box>
            )}
          </GeneralModalV3>
          <Divider flexItem orientation="vertical" />
          {/* Discrepanices Modal*/}
          <EditDiscrepanciesModal
            discrepancies={discrepancies}
            setDiscrepanices={setDiscrepanices}
            openAlert={openAlert}
            id={orderid}
            type="v2"
          />
        </FlexWrapper>
        <FlexWrapper>
          <Button
            onClick={printWholeOrderPallet}
            sx={{ ml: 1 }}
            size="small"
            variant="contained"
          >
            Print All Labels
          </Button>
        </FlexWrapper>
      </FlexWrapper>

      {/* Pallet Changer goes here */}
      <Box display="flex" alignItems="center" justifyContent="center" py={2}>
        <IconButton
          disabled={currentPalletIndex === 0}
          onClick={handlePrevPallet}
        >
          <Tooltip title="Previous" arrow placement="top">
            <ArrowBackIos />
          </Tooltip>
        </IconButton>

        <FormControl variant="outlined" sx={{ minWidth: 200, mx: 1 }}>
          <Select
            labelId="pallet-select-label"
            value={currentPalletIndex}
            onChange={(event) => {
              setCurrentPalletIndex(event.target.value);
              setCurrentBoxIndex(0);
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
          >
            {data?.packDetails?.map((pallet, index) => (
              <MenuItem key={pallet._id} value={index}>
                Pallet {pallet?.palletId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <IconButton
          onClick={handleNextPallet}
          disabled={currentPalletIndex === data?.packDetails?.length - 1}
        >
          <Tooltip title="Next" arrow placement="top">
            <ArrowForwardIos />
          </Tooltip>
        </IconButton>
      </Box>

      <Box pt={1}>
        <FlexWrapper>
          <Typography variant="h5" gutterBottom pb={1}>
            Pallet Information{" "}
            <Typography
              component="span"
              variant="caption"
              fontWeight="bold"
              pr={1}
            >
              (Pallet Qty: {currentPallet.totalitemquantity} )
            </Typography>
          </Typography>

          <IconButton
            color="primary"
            disabled={!Boolean(currentPallet.totalitemquantity)}
            onClick={() => printContentLabels()}
          >
            <Tooltip title="Print Pallet Content Labels" arrow placement="top">
              <Print />
            </Tooltip>
          </IconButton>
        </FlexWrapper>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              required
              type="number"
              label="Pallet Number"
              InputLabelProps={{
                shrink: true,
              }}
              value={currentPallet?.palletId}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Enter Weight"
              name="weight"
              type="number"
              inputMode="numeric"
              inputProps={{ min: 1 }}
              value={currentPallet?.weight}
              onChange={(event) => handleInputChanges(event)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              name="dimensions"
              label="Enter Dimensions"
              type="text"
              value={currentPallet?.dimensions}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => handleInputChanges(event)}
            />
          </Grid>
        </Grid>
      </Box>
      {/* Box Details */}
      {Boolean(currentBox) && (
        <Box pt={1}>
          <FlexWrapper>
            <Typography variant="h5" pb={1}>
              Box Information{" "}
              <Typography
                component="span"
                variant="caption"
                fontWeight="bold"
                pr={1}
              >
                (Box Qty: {currentBox?.boxpackedamount} )
              </Typography>
            </Typography>

            {/* <IconButton
              color="primary"
              disabled={!Boolean(currentPallet.totalitemquantity)}
              onClick={() => printContentLabels()}
            >
              <Tooltip
                title="Print Pallet Content Labels"
                arrow
                placement="top"
              >
                <Print />
              </Tooltip>
            </IconButton> */}
          </FlexWrapper>
          <Stack pb={2} spacing={1} direction="row" flexWrap="wrap">
            {currentPallet?.boxes?.map((box, index) => (
              <Chip
                key={box._id}
                id={box.boxId}
                label={`Box ${box.boxId}`}
                color={"info"}
                variant={index === currentBoxIndex ? "filled" : "outlined"}
                onClick={() => {
                  setCurrentBoxIndex(index);
                }}
              />
            ))}
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                required
                type="number"
                label="Box Number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={currentBox?.boxId}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                label="Enter Box Weight"
                name="weight"
                type="number"
                inputMode="numeric"
                inputProps={{ min: 1 }}
                value={currentBox?.weight}
                onChange={(event) => handleInputChanges(event, "box")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="dimensions"
                label="Enter Box Dimensions"
                type="text"
                value={currentBox?.dimensions}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => handleInputChanges(event, "box")}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <Box pt={2}>
        <FlexWrapper alignItems="center" gap={1}>
          <Typography variant="h5" gutterBottom>
            Images
          </Typography>
          {currentImages.length ? (
            <ViewAWSImages
              currentImages={currentImages}
              openAlert={openAlert}
            />
          ) : null}
        </FlexWrapper>

        {data?.isImagesRequired === "T" ? (
          <Typography color="error" variant="caption" pb={1} fontWeight="bold">
            Images are required for this order
          </Typography>
        ) : null}

        <>
          <GeneralFileUpload
            multiple
            handleSaveFiles={handleSaveFiles}
            accept=".jpg,.jpeg,.png"
            handleRemoveFile={handleRemoveFile}
            files={currentPallet?.newImages}
            capture={true}
          />
          <Typography
            variant="caption"
            pt={1}
            fontWeight="bold"
            textTransform="capitalize"
          >
            Upload images of each side of the pallet
          </Typography>
        </>
      </Box>

      <Box py={2}>
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={handleUpdatePalletData}
        >
          Update Pallets
        </Button>
      </Box>
    </Container>
  );
};
export default UpdatePackResultsV2;
