export const READYTOSHIPCOLUMNS = [
  {
    field: "Date",
    headerName: "Date",
    type: "date",
    width: 100,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => new Date(params.value),
  },
  {
    field: "Document Number",
    headerName: "SO #",
    type: "string",
    width: 100,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Warehouse Order Status",
    headerName: "Warehouse Order Status",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Customer",
    headerName: "Customer",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "PO #",
    headerName: "PO #",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Warehouse Notes",
    headerName: "Warehouse Notes",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Shipping Method",
    headerName: "Shipping Method",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Must Ship With",
    headerName: "Must Ship With",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Date Ready to Ship",
    headerName: "Date Ready to Ship",
    type: "date",
    width: 150,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => (params.value ? new Date(params.value) : null),
  },
  {
    field: "Location",
    headerName: "Location",
    type: "string",
    width: 150,
    align: "center",
    headerAlign: "center",
  },
];

export const FREIGHTSHIPPEDTODAYCOLUMNS = [
  {
    field: "Shipped Date",
    headerName: "Shipped Date",
    type: "date",
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => new Date(params.value),
  },
  {
    field: "Shipping Method",
    headerName: "Shipping Method",
    type: "string",
    flex: 1.5,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Carrier",
    headerName: "Carrier",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Package Count",
    headerName: "Package Count",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Shipment Count",
    headerName: "Shipment Count",
    type: "string",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Tracking",
    flex: 5,
    headerName: "Tracking",
    type: "string",
    align: "center",
    headerAlign: "center",
  },
];
