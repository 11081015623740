import { useState } from "react";
import { Edit } from "@mui/icons-material";
import { Box, Button, IconButton, TextField } from "@mui/material";

import GeneralModalV3 from "../../../general/GeneralModalV3";
import axios from "../../../../axios/axios.config";

const EditDiscrepanciesModal = ({
  discrepancies,
  setDiscrepanices,
  id,
  openAlert,
  type = "v1",
}) => {
  const [input, setInput] = useState(discrepancies);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (event, handleClose) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      let response = null;

      if (type === "v1") {
        response = await axios.patch(
          `pack-process/update-pack-order/${id}/discrepancies`,
          {
            discrepancies: parseInt(input),
          }
        );
      } else {
        //v2 update
        response = await axios.patch(
          `pack-process-v2/update-pack-order/update-discrepancies/${id}`,
          {
            discrepancies: parseInt(input),
          }
        );
      }

      if (response) {
        setDiscrepanices(response?.data?.discrepancies);
      } else {
        throw new Error("Could not update discrepanices");
      }

      handleClose();
      openAlert({
        type: "success",
        message: "Successfully Updated Discrepanices",
        duration: 3000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Update Discrepanices:${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <GeneralModalV3
      openComponent={
        <IconButton>
          <Edit color="primary" />
        </IconButton>
      }
    >
      {(handleClose) => (
        <Box
          px={2}
          pt={3}
          component="form"
          onSubmit={(event) => handleSubmit(event, handleClose)}
        >
          <TextField
            autoFocus
            fullWidth
            type="number"
            required
            label="Enter Discrepancies"
            value={input}
            onChange={(event) => setInput(event.target.value)}
            inputProps={{ min: 0 }}
          />

          <Button
            disabled={submitting}
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            sx={{ mt: 2 }}
          >
            {submitting ? "Submitting..." : "Submit"}
          </Button>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default EditDiscrepanciesModal;
