import { useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleNav } from "../../../../redux/features/nav/navBarSlice";
import { openGeneralAlert } from "../../../../redux/features/alert/alertSlice";
import { useMediaQuery } from "@mui/material";

import axios from "../../../../axios/axios.config";
import ReceiveTransactionOverageErrorModal from "../../../../components/warehouse/receive-transaction/ReceiveTransactionOverageErrorModal";

const ReceiveTransactionLayout = () => {
  const [loading, setLoading] = useState("");
  const [overageError, setOverageError] = useState("");

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const matches = useMediaQuery("(max-width:600px)");
  const { username, currentDepartment, rolelevel } = useSelector(
    (state) => state.user
  );

  //hide nav when on results page
  useEffect(() => {
    if (location?.pathname?.split("/").length === 5) {
      dispatch(toggleNav(true));
    } else {
      dispatch(toggleNav(false));
    }
  }, [location]);

  const dispatchAlert = ({ type, message, duration }) => {
    dispatch(openGeneralAlert({ type, message, duration }));
  };

  const handleItemReceipt = async ({
    id,
    mongoid,
    receiveditems,
    locationinternalid,
    trantype,
    tranid,
    condition,
    reference,
    soreference,
  }) => {
    setLoading("Receiving Transaction...");

    try {
      //bin number will be OZ_RECEIVING_{locationinternalid} or SCRAP or TO#
      let bin = null;
      const storage = {};
      const nsStorage = {};
      const uniqueItems = new Set();
      const binAssignmentItems = [];

      if (
        condition !== "scrap" &&
        trantype === "transferorder" &&
        !soreference?.trim()
      ) {
        //check if bin exists
        const binResponse = await axios.get(
          `netsuite/receive-transaction/check-bin/${tranid}`
        );

        if (binResponse.data?.totalResults === 0) {
          //bin does not exist. create bin
          await axios.post(`netsuite/receive-transaction/post/create-bin`, {
            binNumber: tranid,
            location: locationinternalid,
            externalId: `NEWBIN_${tranid}`,
          });
        }

        //set bin as transfer order number
        bin = tranid;
      } else {
        //use the old bin logic
        bin =
          condition && condition === "scrap"
            ? "SCRAP"
            : `OZ_RECEIVING_${locationinternalid}`;
      }

      if (!bin) {
        throw new Error("Could Not Get Bin");
      }

      //make a call to NetSuite to get most current data
      const response = await axios.get(
        `netsuite/receive-transaction/${tranid?.trim()}`
      );

      for (const nsItem of response.data) {
        if (!nsStorage[nsItem.lineid]) {
          nsStorage[nsItem.lineid] = parseInt(nsItem.lineitemqtypendingreceipt);
        }
      }

      //save items by line ids
      for (const scanItem of receiveditems) {
        if (!storage[scanItem.lineid]) {
          storage[scanItem.lineid] = {
            itemreceive: true,
            orderLine: scanItem.lineid,
            quantity: 0,
            binnumbers: bin,
            itemname: scanItem.item,
          };

          //only assign unique item ids
          if (!uniqueItems.has(scanItem.internalid)) {
            binAssignmentItems.push({
              item: scanItem.internalid,
              binnumbers: bin,
            });

            //add internalid to set
            uniqueItems.add(scanItem.internalid);
          }
        }

        storage[scanItem.lineid].quantity += scanItem.qtyreceived;
      }

      const newItems = Object.values(storage);
      const newItemKeys = Object.keys(storage);

      //compare received items with ns items and check for missing items and overages
      for (const key of newItemKeys) {
        if (!nsStorage[key]) {
          throw new Error(
            `Order Line ID: ${key} (${
              storage[key].itemname
            }) contains an overage of ${parseInt(
              storage[key].quantity
            )}\nQuantity Received: ${parseInt(
              storage[key].quantity
            )}\nQuantity Remaining: 0`
          );
        }
        //check for overages
        if (parseInt(nsStorage[key]) < parseInt(storage[key].quantity)) {
          throw new Error(
            `Order Line ID: ${key} (${
              storage[key].itemname
            }) contains an overage of ${
              parseInt(storage[key].quantity) - parseInt(nsStorage[key])
            }\nQuantity Received: ${parseInt(
              storage[key].quantity
            )}\nQuantity Remaining: ${parseInt(nsStorage[key])}`
          );
        }

        delete storage[key].itemname;
      }

      //make the request to netsuite to place items in bin
      const binAssignmentResponse = await handleNetsuiteAPICall({
        binAssignmentItems,
        tranid,
      });

      if (binAssignmentResponse.data?.status !== 200) {
        throw new Error(
          "Could not assign items to bin in Netsuite. Receive Cancelled."
        );
      }

      const { data } = await axios.post(
        `netsuite/receive-transaction/post/item-receipt/${id}`,
        {
          memo: `Received by: ${username}`,
          items: newItems,
          trantype,
          externalid: `${tranid}_${mongoid}`,
          reference,
        }
      );
      let receiptid = null;

      if (data) {
        const urlArray = data.split("/");
        receiptid = parseInt(urlArray[urlArray.length - 1]);
      }

      //update mongo status to received
      await axios.patch(
        `receive-transaction/${mongoid}/update/fulfill-transaction`,
        {
          status: "received",
          itemreceiptinternalid: receiptid,
          receivedby: username,
          receivedtime: new Date().toISOString(),
        }
      );

      navigate(
        `/portal/${currentDepartment?.toLowerCase()}/receive-transaction`,
        { replace: true }
      );
      dispatchAlert({
        type: "success",
        message: `Successfully received transaction`,
        duration: 3000,
      });
    } catch (error) {
      if (error.message?.startsWith("Order Line ID:")) {
        setOverageError(error.message);
      } else {
        dispatchAlert({
          type: "error",
          message: `${error.response?.data?.msg || error.message}`,
          duration: 10000,
        });
      }
    } finally {
      setLoading("");
    }
  };

  const handleNetsuiteAPICall = async ({ binAssignmentItems, tranid }) => {
    try {
      const response = await axios.post(
        "netsuite/receive-transaction/post/item-bin-assignment",
        { items: binAssignmentItems }
      );

      return response;
    } catch (error) {
      const errorData = {
        integration: "netsuite",
        type: "receiving",
        action: "item-receipt",
        user: username,
        refnumber: tranid,
        errormessage: error.response?.data?.msg || error.message,
      };

      axios.post("integration-errors", {
        errorData,
      });

      throw error;
    }
  };

  const handleBackToSearch = () => {
    navigate("/portal/warehouse/receive-transaction", { replace: true });
  };

  return (
    <>
      <ReceiveTransactionOverageErrorModal
        open={overageError}
        setOpen={setOverageError}
      />

      <Outlet
        context={{
          dispatchAlert,
          loading,
          setLoading,
          username,
          handleItemReceipt,
          handleBackToSearch,
          matches,
          currentDepartment,
          navigate,
          navigation,
          rolelevel,
        }}
      />
    </>
  );
};

export default ReceiveTransactionLayout;
