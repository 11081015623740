import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

const StockLocatorPage = () => {
  return (
    <Container maxWidth="xl">
      <Typography textAlign="center" variant="h4">
        Stock Locator
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <Grid container spacing={3} maxWidth="sm">
          {/* Product Lookup Card */}
          <Grid item xs={12} sm={6}>
            <Card variant="outlined" sx={{ height: "100%", borderRadius: 2 }}>
              <CardActionArea
                component={Link}
                to="product-lookup"
                sx={{ height: "100%" }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    p: 3,
                  }}
                >
                  <Typography variant="h6" component="div">
                    Product Lookup
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                  >
                    Search for inventory by entering a product.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* Bin Lookup Card */}
          <Grid item xs={12} sm={6}>
            <Card variant="outlined" sx={{ height: "100%", borderRadius: 2 }}>
              <CardActionArea
                component={Link}
                to="bin-lookup"
                sx={{ height: "100%" }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    p: 3,
                  }}
                >
                  <Typography variant="h6" component="div">
                    Bin Lookup
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                    sx={{ mt: 1 }}
                  >
                    Search for inventory by entering a bin number.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default StockLocatorPage;
