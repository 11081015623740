import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { openGeneralAlert } from "../alert/alertSlice";

import axios from "../../../axios/axios.config";

const initialState = {
  _id: null,
  tranid: null,
  brandpackaging: null,
  customer: null,
  internalid: null,
  location: null,
  locationinternalid: null,
  mustshipwith: null,
  path: [],
  pick: [],
  discrepancies: [],
  nserrors: [],
  picklockedby: null,
  subsidiary: null,
  trantype: null,
  username: null,
  warehousenotes: null,
  loading: "",
  currentindex: 0,
  firstpicktime: null,
  lastpicktime: null,
  lastpickedby: "",
  totalItemQtyCurrentlyBeingPicked: null,
  totalItemQtyCurrentlyBeingMoved: null,
  binQtyAvailable: null,
  currentBinQty: null,
  filteredbins: null,
};

//called when we pick an item completely
export const pickItemFull = createAsyncThunk(
  "pick-order/pickItemFull",
  async (
    { pickedItemObj, _id, lastpicktime, lastpickedby, firstpicktime },
    thunkAPI
  ) => {
    try {
      const { path } = thunkAPI.getState().pickOrder;
      const newPath = path.filter(
        (item) => item.lineid !== pickedItemObj.lineid
      );

      const response = await axios.patch(
        `pick-order/update/order/${_id}/pick-item`,
        {
          itemData: pickedItemObj,
          newPath,
          lastpicktime,
          lastpickedby,
          firstpicktime,
        }
      );

      if (!response.data)
        throw new Error("Something Went Wrong, Could Not Pick Item");

      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  }
);

//called when a partial amount of an item gets picked
export const pickItemPartial = createAsyncThunk(
  "pick-order/pickedItemPartial",
  async (
    {
      pickedItemObj,
      _id,
      pickedAmount,
      lastpicktime,
      lastpickedby,
      firstpicktime,
    },
    thunkAPI
  ) => {
    try {
      const { path, currentindex } = thunkAPI.getState().pickOrder;
      let newPath = [];

      //removes current element from path
      for (let i = 0; i < path.length; i++) {
        if (i !== currentindex) {
          newPath.push(path[i]);
        }
      }

      const response = await axios.patch(
        `pick-order/update/order/${_id}/pick-item/partial`,
        {
          itemData: pickedItemObj,
          newPath,
          pickedAmount,
          lastpicktime,
          lastpickedby,
          firstpicktime,
        }
      );

      if (!response.data)
        throw new Error("Something Went Wrong, Could Not Pick Item");

      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  }
);

export const findNextBin = createAsyncThunk(
  "pick-order/findNextBin",
  async ({ _id }, thunkAPI) => {
    const { path, subsidiary, locationinternalid } =
      thunkAPI.getState().pickOrder;

    try {
      //path is empty
      if (!path.length) return { path };

      for (let i = 0; i < path.length; i++) {
        //current item in path
        const currentItem = path[i];

        const netsuiteResponse = await axios.post(
          "netsuite/post/pick-order/validate/bin",
          {
            itemInteralId: currentItem.lineiteminternalid,
            binInternalId: currentItem.bininternalid,
          }
        );
        //if no bin data returned from netsuite just return current path
        //cant validate bin so user must go in person
        if (!netsuiteResponse.data.length) {
          return { path };
        }

        const {
          binitemslocked,
          binlocked,
          binonhandavailable,
          iteminternalid,
        } = netsuiteResponse.data[0];

        //check if the bin is locked
        if (binlocked && binlocked === "T") {
          //check if item id is in locked ids if true then not a valid bin
          if (
            binitemslocked &&
            binitemslocked.split(",").includes(iteminternalid)
          ) {
            //bin is locked and item is in locked bin items and it is the last bin
            if (i === path.length - 1) {
              //update mongo path to be []
              const orderResponse = await axios.patch(
                `pick-order/update/order/${_id}/path`,
                { newPath: [] }
              );

              return { path: orderResponse.data.path };
            }
            continue;
          }
        }

        //bin isint locked or item isin't in locked bin items
        //check if bin is not empty
        let currentBinQty = parseInt(binonhandavailable);

        //First make a call to find the total amount of item qty that is currently being picked in bin
        let totalItemQtyCurrentlyBeingPicked =
          await getTotalItemQtyCurrentlyBeingPicked({
            subsidiary,
            locationinternalid,
            bininternalid: currentItem.bininternalid,
            lineiteminternalid: currentItem.lineiteminternalid,
          });

        //make a call to get the qty of item currently being transferred from bin
        let totalItemQtyCurrentlyBeingMoved =
          await getTotalItemQtyCurrentlyBeingMoved({
            subsidiary,
            locationinternalid,
            bininternalid: currentItem.bininternalid,
            lineiteminternalid: currentItem.lineiteminternalid,
          });

        const binQtyAvailable =
          currentBinQty -
          totalItemQtyCurrentlyBeingPicked -
          totalItemQtyCurrentlyBeingMoved;

        if (binQtyAvailable > 0) {
          //if binQtyAvailable is greater than 0 then valid bin
          //if i is equal to 0 then the current bin is valid
          if (i === 0) {
            return {
              path: path.map((item, index) => {
                if (index === 0)
                  return {
                    ...item,
                    binonhandavailable: parseInt(binQtyAvailable),
                  };

                return item;
              }),
              binonhandavailable: parseInt(binQtyAvailable),
              totalItemQtyCurrentlyBeingPicked,
              totalItemQtyCurrentlyBeingMoved,
              binQtyAvailable,
              currentBinQty,
            };
          } else {
            //found valid bin thats not current bin
            const newPath = path.slice(i);
            //make a call to update mongo with new path
            const orderResponse = await axios.patch(
              `pick-order/update/order/${_id}/path`,
              { newPath }
            );

            return {
              path: orderResponse.data.path.map((item, index) => {
                if (index === 0)
                  return {
                    ...item,
                    binonhandavailable: parseInt(binQtyAvailable),
                  };

                return item;
              }),
              binonhandavailable: parseInt(binQtyAvailable),
              totalItemQtyCurrentlyBeingPicked,
              totalItemQtyCurrentlyBeingMoved,
              binQtyAvailable,
              currentBinQty,
            };
          }
        }

        //netsuite never found a valid bin
        if (i === path.length - 1) {
          //update mongo path to be []
          const orderResponse = await axios.patch(
            `pick-order/update/order/${_id}/path`,
            { newPath: [] }
          );

          return { path: orderResponse.data.path };
        }
      }
    } catch (error) {
      if (
        error.response?.data?.msg === "timeout of 15000ms exceeded" ||
        error.response?.data?.msg.includes("timeout")
      ) {
        return {
          path,
          index: 0,
        };
      }

      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  }
);

export const resetAllItems = createAsyncThunk(
  "pick-order/resetAllItems",
  async (_, thunkAPI) => {
    const { currentNetsuiteLocationIds, currentSubsidiary } =
      thunkAPI.getState().user;
    const { _id, tranid } = thunkAPI.getState().pickOrder;
    try {
      //get path from netsuite
      const nsResponse = await axios.post(
        `netsuite/post/pick-order/order/${tranid}`,
        {
          currentNetsuiteLocationIds,
          userSubsidiary: currentSubsidiary,
        }
      );

      const { customer } = nsResponse.data[0];

      //get original path from ns
      let originalPath = nsResponse.data.map((item) => {
        return {
          bininternalid: parseInt(item.bininternalid),
          binnumber: item.binnumber,
          binonhandavailable: parseInt(item.binonhandavailable),
          lineid: parseInt(item.lineid),
          lineitemboxsize: item.lineitemboxsize,
          lineitemclass: item.lineitemclass,
          lineiteminternalid: parseInt(item.lineiteminternalid),
          lineitemname: item.lineitemname,
          lineitemqty: parseInt(item.lineitemqty),
          lineitemqtycommitted: parseInt(item.lineitemqtycommitted),
          lineitemqtypicked: parseInt(item.lineitemqtypicked),
          lineitemqtyremaining: parseInt(item.lineitemqtyremaining),
          lineitemsubclass: item.lineitemsubclass,
          lineitemupc: item.lineitemupc,
        };
      });

      // const sortedPath = sortObjectsByBinnumber(originalPath);

      //check for autozone vdp cva customer and reverse walkroute
      if (customer === "AUTOZONE VDP CVA") {
        originalPath = originalPath.reverse();
      }

      //update mongo
      const { data } = await axios.patch(
        `pick-order/update/order/${_id}/reset`,
        { originalPath }
      );

      const { path } = data;
      return path;
    } catch (error) {
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  }
);

export const reverseWalkRoute = createAsyncThunk(
  "pick-order/reverseWalkRoute",
  async (_, thunkAPI) => {
    const { path, _id } = thunkAPI.getState().pickOrder;
    try {
      const reversedPath = [...path].reverse();

      const { data } = await axios.patch(
        `pick-order/update/order/${_id}/reverse-path`,
        { reversedPath }
      );

      return data.path;
    } catch (error) {
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  }
);

export const skipBin = createAsyncThunk(
  "pick-order/skipBin",
  async ({ shortData }, thunkAPI) => {
    const { path, _id } = thunkAPI.getState().pickOrder;
    try {
      const newPath = [];
      for (let i = 1; i < path.length; i++) {
        newPath.push(path[i]);
      }
      //add skipped item to end of path
      newPath.push(path[0]);

      //update mongo path
      const { data } = await axios.patch(
        `pick-order/update/order/${_id}/skip/bin`,
        { newPath, shortData }
      );

      return { path: data.newPath, short: data.newShort };
    } catch (error) {
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  }
);

export const skipItemFull = createAsyncThunk(
  "pick-order/skipItemFull",
  async ({ shortData, bininternalid, lineiteminternalid }, thunkAPI) => {
    const { path, _id } = thunkAPI.getState().pickOrder;
    try {
      const newPath = [];
      for (let i = 1; i < path.length; i++) {
        newPath.push(path[i]);
      }

      //update mongo path and log short
      const { data } = await axios.patch(
        `pick-order/update/order/${_id}/skip/item-full`,
        {
          newPath,
          shortData,
        }
      );

      //lock bin
      await handleLockBin(bininternalid, lineiteminternalid);

      return { path: data.newPath, short: data.newShort };
    } catch (error) {
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  }
);

export const skipItemPartial = createAsyncThunk(
  "pick-order/skipItemPartial",
  async (
    {
      pickedAmount,
      shortData,
      lineid,
      pickedItemObj,
      bininternalid,
      lineiteminternalid,
      lastpicktime,
      lastpickedby,
      firstpicktime,
    },
    thunkAPI
  ) => {
    const { path, _id } = thunkAPI.getState().pickOrder;
    try {
      const newPath = [];
      for (let i = 1; i < path.length; i++) {
        newPath.push(path[i]);
      }
      //update mongo path and log short
      const { data } = await axios.patch(
        `pick-order/update/order/${_id}/skip/item-partial`,
        {
          newPath,
          shortData,
          pickedAmount,
          lineid,
          pickedItemObj,
          lastpicktime,
          lastpickedby,
          firstpicktime,
        }
      );
      //lock bin
      await handleLockBin(bininternalid, lineiteminternalid);

      return {
        path: data.newPath,
        short: data.newShort,
        pick: data.pick,
        lastpicktime: data.lastpicktime,
        lastpickedby: data.lastpickedby,
        firstpicktime: data.firstpicktime,
      };
    } catch (error) {
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  }
);
//function called to get a new path from the new netsuite data and the existing pick data
export const refreshBinData = createAsyncThunk(
  "pick-order/refreshBinData",
  async (_, thunkAPI) => {
    const { pick, tranid, _id } = thunkAPI.getState().pickOrder;
    const { currentNetsuiteLocationIds, currentSubsidiary } =
      thunkAPI.getState().user;
    try {
      //combine pick array into single lineids and calculate total pick qty
      const storage = {};
      for (const pickItem of pick) {
        if (!storage[pickItem.lineid]) {
          storage[pickItem.lineid] = {
            totalPicked: pickItem.binqtypicked,
          };
        } else {
          storage[pickItem.lineid].totalPicked += pickItem.binqtypicked;
        }
      }

      //get new netsuite path
      const { data } = await axios.post(
        `netsuite/post/pick-order/order/${tranid}`,
        {
          currentNetsuiteLocationIds,
          userSubsidiary: currentSubsidiary,
        }
      );

      let newPath = [];
      //update ns path with current picked data
      //compare ns remaining to the current picked amount
      for (const item of data) {
        //item hasnt been picked or picked amount is less than ns remaining amount
        if (
          !storage[parseInt(item.lineid)] ||
          (storage[parseInt(item.lineid)] &&
            storage[parseInt(item.lineid)].totalPicked <
              parseInt(item.lineitemqtyremaining))
        ) {
          newPath.push({
            bininternalid: parseInt(item.bininternalid),
            binnumber: item.binnumber,
            binonhandavailable: parseInt(item.binonhandavailable),
            lineid: parseInt(item.lineid),
            lineitemboxsize: item.lineitemboxsize,
            lineitemclass: item.lineitemclass,
            lineiteminternalid: parseInt(item.lineiteminternalid),
            lineitemname: item.lineitemname,
            lineitemqty: parseInt(item.lineitemqty),
            lineitemqtycommitted: parseInt(item.lineitemqtycommitted),
            lineitemqtypicked: storage[parseInt(item.lineid)]
              ? parseInt(item.lineitemqtypicked) +
                storage[parseInt(item.lineid)].totalPicked
              : parseInt(item.lineitemqtypicked), //if picked then update picked amount
            lineitemqtyremaining: parseInt(item.lineitemqtyremaining),
            lineitemsubclass: item.lineitemsubclass,
            lineitemupc: item.lineitemupc,
          });
        }
      }

      //Add sort logic here
      // const sortedPath = sortObjectsByBinnumber(newPath);

      if (data[0]?.customer === "AUTOZONE VDP CVA") {
        newPath = newPath.reverse();
      }

      //update mongoPath
      const mongoResponse = await axios.patch(
        `pick-order/update/order/${_id}/path`,
        {
          newPath,
        }
      );

      return mongoResponse.data.path;
    } catch (error) {
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  }
);

export const createShort = createAsyncThunk(
  "pick-order/createShort",
  async ({ shortData, orderid }, thunkAPI) => {
    try {
      const shortResponse = await axios.patch(
        `pick-order/update/order/${orderid}/short`,
        { shortData }
      );

      return { discrepancies: shortResponse.data };
    } catch (error) {
      thunkAPI.dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    }
  }
);

const pickOrderSlice = createSlice({
  name: "pick-order",
  initialState,
  reducers: {
    setInitialPickData: (_, { payload }) => {
      return { ...payload, currentindex: 0, filteredbins: null };
    },
    resetPickData: () => {
      return initialState;
    },
    updateLoading: (state, { payload }) => {
      state.loading = payload;
    },
    updateErrors: (state, { payload }) => {
      state.nserrors = payload;
    },
    filterPath: (state, { payload }) => {
      state.path = payload.filteredPath;
      state.filteredbins = payload.filteredBins;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(pickItemFull.pending, (state) => {
        state.loading = "Picking Item...";
      })
      .addCase(pickItemFull.fulfilled, (state, { payload }) => {
        state.pick = payload.pick;
        state.path = payload.path;
        state.loading = "";
        state.firstpicktime = payload.firstpicktime;
        state.lastpicktime = payload.lastpicktime;
        state.lastpickedby = payload.lastpickedby;

        //update index if it exceeds path length
        if (payload.path.length && state.currentindex >= payload.path.length) {
          state.currentindex = payload.path.length - 1;
        }
      })
      .addCase(pickItemPartial.pending, (state) => {
        state.loading = "Picking Item...";
      })
      .addCase(pickItemPartial.fulfilled, (state, { payload }) => {
        state.pick = payload.pick;
        state.path = payload.path;
        state.loading = "";
        state.firstpicktime = payload.firstpicktime;
        state.lastpicktime = payload.lastpicktime;
        state.lastpickedby = payload.lastpickedby;

        //update index if it exceeds path length
        if (payload.path.length && state.currentindex >= payload.path.length) {
          state.currentindex = payload.path.length - 1;
        }
      })
      .addCase(findNextBin.pending, (state) => {
        state.loading = "Getting Next Bin...";
      })
      .addCase(findNextBin.fulfilled, (state, { payload }) => {
        state.path = payload.path;
        state.currentindex = 0;
        state.loading = "";
        state.totalItemQtyCurrentlyBeingPicked =
          payload.totalItemQtyCurrentlyBeingPicked || 0;
        state.totalItemQtyCurrentlyBeingMoved =
          payload.totalItemQtyCurrentlyBeingMoved || 0;
        state.binQtyAvailable = payload.binQtyAvailable || 0;
        state.currentBinQty = payload.currentBinQty || 0;
      })
      .addCase(resetAllItems.pending, (state) => {
        state.loading = "Reseting All Items...";
      })
      .addCase(resetAllItems.fulfilled, (state, { payload }) => {
        state.loading = "";
        state.path = payload;
        state.pick = [];
        state.currentindex = 0;
        state.discrepancies = [];
      })
      .addCase(reverseWalkRoute.pending, (state) => {
        state.loading = "Reversing Route...";
      })
      .addCase(reverseWalkRoute.fulfilled, (state, { payload }) => {
        state.loading = "";
        state.path = payload;
      })
      .addCase(skipBin.pending, (state) => {
        state.loading = "Skipping Bin...";
      })
      .addCase(skipBin.fulfilled, (state, { payload }) => {
        state.loading = "";
        state.path = payload.path;
        state.discrepancies.push(payload.short);
      })
      .addCase(skipItemFull.pending, (state) => {
        state.loading = "Removing Bin And Logging Short...";
      })
      .addCase(skipItemFull.fulfilled, (state, { payload }) => {
        state.loading = "";
        state.path = payload.path;
        state.discrepancies.push(payload.short);
      })
      .addCase(skipItemPartial.pending, (state) => {
        state.loading = "Removing Bin And Logging Short...";
      })
      .addCase(skipItemPartial.fulfilled, (state, { payload }) => {
        state.loading = "";
        state.path = payload.path;
        state.discrepancies.push(payload.short);
        state.pick = payload.pick;
        state.lastpicktime = payload.lastpicktime;
        state.lastpickedby = payload.lastpickedby;
        state.firstpicktime = payload.firstpicktime;
      })
      .addCase(refreshBinData.pending, (state) => {
        state.loading = "Refreshing Bin Data...";
      })
      .addCase(refreshBinData.fulfilled, (state, { payload }) => {
        state.loading = "";
        state.path = payload;
        state.filteredbins = null;
      })
      .addCase(createShort.pending, (state) => {
        state.loading = "Logging Discrepancy...";
      })
      .addCase(createShort.fulfilled, (state, { payload }) => {
        state.discrepancies = payload.discrepancies;
        state.loading = "";
      });
  },
});

/*HELPER FUNCTIONS */
const handleLockBin = async (bininternalid, lineiteminternalid) => {
  try {
    await axios.patch(
      `netsuite/update/pick-order/bin/${bininternalid}/locked-status`,
      { iteminternalid: lineiteminternalid }
    );
  } catch (error) {
    throw error;
  }
};

const getTotalItemQtyCurrentlyBeingPicked = async ({
  subsidiary,
  locationinternalid,
  lineiteminternalid,
  bininternalid,
}) => {
  try {
    const { data } = await axios.get(
      `pick-order/get/total-item-bin-qty?subsidiary=${subsidiary}&locationinternalid=${locationinternalid}&lineiteminternalid=${lineiteminternalid}&bininternalid=${bininternalid}`
    );

    if (data?.totalQty) {
      return data.totalQty;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

const getTotalItemQtyCurrentlyBeingMoved = async ({
  subsidiary,
  locationinternalid,
  lineiteminternalid,
  bininternalid,
}) => {
  try {
    const { data } = await axios.get(
      `pick-order/get/total-inventory-move-qty?subsidiary=${subsidiary}&locationinternalid=${locationinternalid}&lineiteminternalid=${lineiteminternalid}&bininternalid=${bininternalid}`
    );

    if (data?.totalQtyBeingMoved) {
      return data.totalQtyBeingMoved;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

//sorts the path from mongo to have  OZ bins
const sortObjectsByBinnumber = (path) => {
  const ozObjects = [];
  const otherObjects = [];

  for (const obj of path) {
    if (obj?.binnumber?.startsWith("OZ_RECEIVING_")) {
      ozObjects.push(obj);
    } else {
      otherObjects.push(obj);
    }
  }

  // Combine the two arrays, OZ objects at the end
  const sortedPath = [...otherObjects, ...ozObjects];
  return sortedPath;
};

export const {
  setInitialPickData,
  resetPickData,
  updateLoading,
  updateErrors,
  filterPath,
} = pickOrderSlice.actions;

export default pickOrderSlice.reducer;
