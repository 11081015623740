import axios from "../../../axios/axios.config";

export const handleAdjustment = async ({
  subsidiaryinternalid,
  username,
  items,
  locationinternalid,
  _id,
  isPickShort = true,
}) => {
  try {
    const nsResponse = await axios.post(
      "netsuite/pick-discrepancies/post/inventory-adjustment",
      {
        subsidiaryinternalid,
        username,
        items,
        locationinternalid,
        _id,
      }
    );

    if (isPickShort) {
      await axios.patch(`pick-shorts/${_id}/update/adjustment`, {
        adjustmentinternalid: nsResponse.data?.msg || null,
      });
    }

    //message contains the internal id of the transaction
    return nsResponse.data?.msg || null;
  } catch (error) {
    throw error;
  }
};

export const handleApproveAndAdjust = async ({
  subsidiaryinternalid = null, //only needed for adjustments
  username = null, //only needed for adjustments
  items = null, //only needed for adjustments
  locationinternalid = null, //only needed for adjustments
  _id = null, //only needed for adjustments
  adjustment,
  bininternalid,
  lineiteminternalid,
}) => {
  try {
    let adjustmentinternalid = null;
    //unlock item in bin
    await axios.get(
      `netsuite/general/get/bin/${bininternalid}/remove-bin-lock?lineiteminternalid=${lineiteminternalid}`
    );

    //make adjustment if necessary
    if (adjustment !== 0 && items) {
      adjustmentinternalid = await handleAdjustment({
        subsidiaryinternalid,
        username,
        items,
        locationinternalid,
        _id,
      });
    }

    return { adjustmentinternalid, msg: "Successfully Unlocked Bin" };
  } catch (error) {
    throw error;
  }
};
