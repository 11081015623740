import axios from "../../axios/axios.config";

export const loadOrders = async () => {
  const subsidiary = localStorage.getItem("subsidiary");
  const locations = localStorage.getItem("netsuitelocations");

  try {
    // Run both requests in parallel using Promise.all
    const [response1, response2] = await Promise.all([
      axios.get(
        `pack-process/view-orders?subsidiary=${subsidiary}&locations=${locations}&status=open`
      ),
      axios.get(
        `pack-process-v2/view-orders-page/view-by-status?subsidiary=${subsidiary}&locations=${locations}&status=open`
      ),
    ]);

    // Combine the results from both responses
    const combinedOrders = [
      ...(response1.data || []),
      ...(response2.data || []),
    ];

    return combinedOrders.length ? combinedOrders : null;
  } catch (error) {
    return null; // Handle error gracefully
  }
};

export const loadSingleOrder = async ({ params }) => {
  const { orderid } = params;

  const response = await axios.get(`pack-process/view-orders/${orderid}`);

  if (response.data) return response.data;

  return null;
};
