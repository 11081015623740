import axios from "../../../axios/axios.config";

export const fulfillPickOrder = async ({
  username,
  pick,
  trantype,
  tranid,
  _id,
  lastpicktime,
  lastpickedby,
  firstpicktime,
  pacejetstation,
  custbody_ait_so_to_related_fulfill,
  orderid,
  ponumber,
  destinationlocationid,
}) => {
  try {
    if (!_id || !username || !tranid || !pick || !orderid) {
      throw new Error("Could not fulfill order: Misisng required fields");
    }

    const time = new Date().toISOString();

    const storage = {}; // will be final object we use in api call to NetSuite
    const currentItemBinStorage = {}; //will store bins and quantities for our line ids

    for (const item of pick) {
      if (!storage[item.lineid]) {
        storage[item.lineid] = {
          itemreceive: true,
          orderLine: item.lineid,
          iteminternalid: item.lineiteminternalid,
        };
      }

      //store bin numbers in binstorage
      //each lineid will have an object containing bins as keys and quantities as values
      if (!currentItemBinStorage[item.lineid]) {
        currentItemBinStorage[item.lineid] = {
          [item.binnumber]: parseInt(item.binqtypicked),
        };
      } else {
        if (!currentItemBinStorage[item.lineid][item.binnumber]) {
          currentItemBinStorage[item.lineid][item.binnumber] = parseInt(
            item.binqtypicked
          );
        } else {
          currentItemBinStorage[item.lineid][item.binnumber] += parseInt(
            item.binqtypicked
          );
        }
      }
    }
    //loop through each line id in bin storage
    for (const lineid of Object.keys(currentItemBinStorage)) {
      const currentBinsObj = currentItemBinStorage[lineid];
      const binsArr = Object.keys(currentBinsObj);

      if (binsArr.length > 1) {
        //loop and create a string to update binnumbers and qty
        let finalString = "";
        let finalQty = 0;

        for (const bin of binsArr) {
          finalString =
            finalString === ""
              ? `${bin}(${currentBinsObj[bin]})`
              : finalString + ` ${bin}(${currentBinsObj[bin]})`;

          finalQty += currentBinsObj[bin];
        }

        storage[lineid].binnumbers = finalString;
        storage[lineid].quantity = finalQty;
      } else {
        //update qty and binnumber in storage since only one bin exists
        storage[lineid].quantity = currentBinsObj[binsArr[0]];
        storage[lineid].binnumbers = binsArr[0];
      }
    }

    let receiveItems = null;
    let receiveRequestItems = null;
    let memo = null;
    let autoreceive =
      trantype === "transferorder" && ponumber && destinationlocationid;
    //sets up data structures to autoreceive in server
    if (autoreceive) {
      receiveItems = Object.values(storage).map((data) => {
        const { iteminternalid } = data;
        return {
          item: iteminternalid,
          binnumbers: `OZ_RECEIVING_${destinationlocationid}`,
        };
      });

      //data sending to receive item receipt
      memo = `Received By: ${username}`;
      //items array we will send in receive request
      receiveRequestItems = Object.values(storage).map((data) => {
        return {
          itemreceive: true,
          orderLine: parseInt(data["orderLine"]) + 1,
          binnumbers: `OZ_RECEIVING_${destinationlocationid}`,
        };
      });
    }

    //call to fulfill order and update mongo
    const response = await axios.post("multi-actions/pick-order/fulfill", {
      externalid: `${tranid}_${_id}`,
      username,
      items: Object.values(storage).map((data) => {
        const { itemreceive, orderLine, binnumbers, quantity } = data;
        return {
          itemreceive,
          orderLine,
          binnumbers,
          quantity,
        };
      }),
      trantype,
      tranid,
      _id,
      orderid,
      lastpicktime,
      lastpickedby,
      firstpicktime,
      pacejetstation: pacejetstation ? pacejetstation : null,
      time,
      custbody_ait_so_to_related_fulfill,
      receiveRequestItems,
      receiveItems,
      memo,
      autoreceive,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
