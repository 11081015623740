import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";

import GeneralModalV3 from "../../../general/GeneralModalV3";

const StockOrdersInfoModal = () => {
  const statuses = [
    {
      status: "To be Printed",
      definition: "Order is ready to be printed",
    },
    { status: "Printed", definition: "Order has been printed" },
    {
      status: "Being Picked by: username",
      definition: "Order is currently being picked by picker listed",
    },
    {
      status: "Being Picked by: SPLITORDER(X)",
      definition:
        "Order is currently being picked as a split order, X denotes number of splits",
    },
    {
      status: "Picked",
      definition: "Order has been picked (Possibly not complete)",
    },
    {
      status: "Picked at 100% Fill",
      definition: "Order picked with 100% fill rate",
    },
    {
      status: "Packed",
      definition: "Order has been fully packed",
    },
    {
      status: "Shipped",
      definition: "Order has been fully shipped (Can no longer be modified)",
    },
  ];

  return (
    <GeneralModalV3
      openComponent={
        <IconButton>
          <Info color="primary" />
        </IconButton>
      }
    >
      <Typography variant="h4" textAlign="center" py={1}>
        Warehouse Order Status
      </Typography>
      <Divider />
      <Box p={1}>
        {statuses?.length
          ? statuses?.map((status) => (
              <Box key={status.status}>
                <Typography variant="body2" py={1} fontSize="16px">
                  <strong>{status.status}</strong>: {status.definition}
                </Typography>
                <Divider />
              </Box>
            ))
          : null}
      </Box>
    </GeneralModalV3>
  );
};
export default StockOrdersInfoModal;
