import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  createPalletBoxV2,
  editPalletBoxV2,
  toggleBoxDetailsModal,
  toggleCurrentBox,
} from "../../../../redux/features/pack-process-v2/packProcessV2Slice";

//Component used to create and edit box information
const PackProcessBoxInfoModal = () => {
  const dispatch = useDispatch();
  const { _id, packdetails, palletnumber, boxDetails } = useSelector(
    (state) => state.packProcessV2
  );
  const { username } = useSelector((state) => state.user);

  const [error, setError] = useState("");
  const [input, setInput] = useState({
    boxId: "",
    weight: "",
    dimensions: "",
  });
  const type = boxDetails?.type;
  const currentBox = boxDetails?.box;

  useEffect(() => {
    if (type) {
      if (type === "edit") {
        //set input to current box details
        setInput({
          boxId: currentBox?.boxId,
          weight: currentBox?.weight,
          dimensions: currentBox?.dimensions,
        });
      } else {
        setInput({
          boxId: packdetails[palletnumber]?.boxes?.length + 1,
          weight: 1,
          dimensions: "", //default dimensions
        });
      }
    }
  }, []);

  const handleChanges = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });

    if (error) {
      setError("");
    }
  };

  const handleSubmit = async () => {
    if (type === "edit" && Boolean(currentBox)) {
      dispatch(toggleBoxDetailsModal(null));
      dispatch(
        editPalletBoxV2({
          _id,
          palletId: packdetails[palletnumber]?.palletId,
          boxNumber: input.boxId,
          dimensions: input.dimensions,
          weight: input.weight,
          boxId: currentBox._id,
        })
      );
      dispatch(toggleCurrentBox(currentBox.boxId));
    } else {
      dispatch(
        createPalletBoxV2({
          _id,
          createdby: username,
          palletId: packdetails[palletnumber]?.palletId,
          boxId: input.boxId,
          dimensions: input.dimensions,
          weight: input.weight,
        })
      );
    }
  };

  return (
    <Dialog open={true} fullScreen disableEscapeKeyDown>
      <Container maxWidth="xl">
        <IconButton
          onClick={() => {
            setError("");
            setInput({
              boxId: "",
              weight: "",
              dimensions: "",
            });

            if (currentBox) {
              dispatch(toggleCurrentBox(currentBox.boxId));
            }
            dispatch(toggleBoxDetailsModal(null));
          }}
        >
          <Close />
        </IconButton>

        <Typography textAlign="center" variant="h5" textTransform="capitalize">
          {type} Box In Pallet {packdetails[palletnumber]?.palletId}
        </Typography>

        <Box>
          <Grid
            container
            spacing={2}
            component="form"
            pt={3}
            onSubmit={() => {}}
          >
            <Grid item xs={6}>
              <TextField
                disabled
                autoFocus
                fullWidth
                required
                label="Box Number"
                name="boxId"
                type="number"
                inputMode="numeric"
                value={input.boxId}
                error={Boolean(error)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Weight (Optional)"
                name="weight"
                type="number"
                inputMode="numeric"
                value={input.weight}
                onChange={handleChanges}
                error={Boolean(error)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="dimensions"
                label="Dimensions (Optional)"
                type="text"
                value={input.dimensions}
                onChange={handleChanges}
                error={Boolean(error)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                component="p"
                textAlign="center"
                color="error"
              >
                {error}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                size="large"
                type="button"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
              >
                {type === "edit" ? "Edit" : "Add"} Box
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
};

export default PackProcessBoxInfoModal;
