//helper function to return a tie string that represents elapsed time between to time objects
export const calculateElapsedTime = (time1, time2) => {
  const firstTime = new Date(time1);
  const lastTime = new Date(time2);

  // Calculate the difference in milliseconds
  const differenceInMs = lastTime - firstTime;

  // Convert to hours, minutes, and seconds
  const hours = Math.floor(differenceInMs / (1000 * 60 * 60));
  const minutes = Math.floor((differenceInMs % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((differenceInMs % (1000 * 60)) / 1000);

  // Return formatted string
  return `${hours}h ${minutes}m ${seconds}s`;
};
