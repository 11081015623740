import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axios/axios.config";

const initialState = {
  currentPrinter: {},
};

export const getPrinterObjectFromUser = createAsyncThunk(
  "printers/getPrinterObjectFromUser",
  async (printerID, thunkAPI) => {
    try {
      if (printerID) {
        const response = await axios.get(`printers/${printerID}`);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

const printerSlice = createSlice({
  name: "printer",
  initialState,
  reducers: {
    getPrinterFromStorage: (state, action) => {
      const currentPrinter = JSON.parse(localStorage.getItem("currentPrinter"));
      state.currentPrinter = currentPrinter;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      getPrinterObjectFromUser.fulfilled,
      (state, { payload }) => {
        localStorage.setItem("currentPrinter", JSON.stringify(payload));
        state.currentPrinter = payload;
      }
    );
  },
});

export const { getPrinterFromStorage } = printerSlice.actions;
export default printerSlice.reducer;
