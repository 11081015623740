import { useEffect, useState } from "react";
import { departments } from "../../utils/departments/departments";
import { useDispatch } from "react-redux";
import { closeFullScreenModal } from "../../redux/features/modal/modalSlice";
import { openGeneralAlert } from "../../redux/features/alert/alertSlice";
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import axios from "../../axios/axios.config";
import FlexWrapper from "../FlexWrapper";

const UserForm = ({ user, subsidiaries, locations, printers, setUsers }) => {
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    firstname: "",
    lastname: "",
    username: "",
    password: "",
    email: "",
    subsidiary: [],
    department: [],
    location: [],
    printer: "",
    company: "",
    timezone: "",
    rolelevel: "",
    usertype: "",
    manager: "",
    salesrepcode: -1,
    partnercode: -1,
    customercode: -1,
    status: "pending",
    company: "AIT",
    language: "",
    remoteaccess: false,
    pacejetstation: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    //load user in state if exists
    if (user) {
      const {
        firstname,
        lastname,
        username,
        email,
        subsidiary,
        department,
        location,
        printer,
        company,
        timezone,
        rolelevel,
        usertype,
        manager,
        salesrepcode,
        partnercode,
        customercode,
        status,
        language,
        netsuiteinternalid,
        remoteaccess,
        pacejetstation,
      } = user;

      setUserInput({
        firstname,
        lastname,
        username,
        password: "",
        email,
        subsidiary,
        department,
        location: location.map((loc) => `${loc.subsidiary}-${loc.location}`),
        printer,
        company,
        timezone,
        rolelevel,
        usertype,
        manager,
        salesrepcode,
        partnercode,
        customercode,
        status,
        language,
        netsuiteinternalid,
        remoteaccess: remoteaccess ? remoteaccess : false,
        pacejetstation: pacejetstation ? pacejetstation : "",
      });
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      let newUsers = [];
      const formattedLocation = userInput.location.map((loc) => {
        const [subsidiary, location] = loc.split("-");
        return { subsidiary, location };
      });

      //if no user prop provided we will create one, else update provided user
      if (!user) {
        const response = await axios.post("users/admin/create/user", {
          ...userInput,
          location: formattedLocation,
        });

        newUsers = response.data;
      } else {
        const editUserObj = { ...userInput };

        if (!userInput.password) {
          delete editUserObj.password;
        }

        const response = await axios.patch(
          `users/admin/update/user/${user._id}`,
          {
            ...editUserObj,
            location: formattedLocation,
          }
        );

        newUsers = response.data;
      }

      dispatch(
        openGeneralAlert({
          type: "success",
          message: user
            ? "Successfully Updated User"
            : "Successfully Created New User",
          duration: 3000,
        })
      );
      setUsers(newUsers);
      dispatch(closeFullScreenModal());
    } catch (error) {
      dispatch(
        openGeneralAlert({
          type: "error",
          message: error.response?.data?.msg || error.message,
          duration: 5000,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleMultiInputChange = (event) => {
    setUserInput({
      ...userInput,
      // On autofill we get a stringified value.
      [event.target.name]:
        typeof event.target.value === "string"
          ? event.target.value.split(",")
          : event.target.value,
    });
  };

  const handleUserInputChange = (event) => {
    setUserInput({ ...userInput, [event.target.name]: event.target.value });
  };

  return (
    <Container maxWidth="sm">
      <FlexWrapper justifyContent="center" flexWrap="wrap">
        <Typography variant="h3" gutterBottom textAlign="center">
          {user ? "Edit User" : "Create User"}
        </Typography>

        <Grid
          container
          spacing={1}
          component="form"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Grid item xs={12} md={6}>
            <TextField
              autoFocus
              name="firstname"
              required
              fullWidth
              id="firstname"
              label="First Name"
              value={userInput.firstname}
              onChange={handleUserInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              id="lastname"
              label="Last Name"
              name="lastname"
              value={userInput.lastname}
              onChange={handleUserInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              inputProps={{ autoComplete: "off" }}
              required
              fullWidth
              label="Username"
              name="username"
              value={userInput.username}
              onChange={handleUserInputChange}
              disabled={Boolean(user)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required={!Boolean(user)}
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={userInput.password}
              onChange={handleUserInputChange}
              inputProps={{ autoComplete: "new-password" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              inputProps={{ autoComplete: "off" }}
              fullWidth
              name="email"
              label="Email"
              type="email"
              id="email"
              value={userInput.email}
              onChange={handleUserInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label-department">
                Department
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-department"
                id="demo-simple-select"
                label="Department"
                name="department"
                multiple
                value={userInput.department}
                onChange={handleMultiInputChange}
              >
                {departments.map((dept) => {
                  return (
                    <MenuItem key={dept} value={dept}>
                      {dept.replace("-", " ")}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label-department">
                Subsidiary
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-subsidiary"
                id="demo-simple-select-subsidiary"
                label="Subsidiary"
                name="subsidiary"
                multiple
                value={userInput.subsidiary}
                onChange={handleMultiInputChange}
              >
                {subsidiaries.map((sub) => {
                  return (
                    <MenuItem key={sub._id} value={sub.shortname}>
                      {sub.shortname}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {Boolean(userInput.subsidiary.length) && (
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label-location">
                  Location
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-location"
                  id="demo-simple-select-location"
                  label="Location"
                  name="location"
                  multiple
                  value={userInput.location}
                  onChange={handleMultiInputChange}
                >
                  {locations
                    .filter((loc) =>
                      userInput.subsidiary.includes(loc.subsidiary)
                    )
                    .sort((a, b) => a.subsidiary.localeCompare(b.subsidiary))
                    .map((loc) => {
                      return (
                        <MenuItem
                          key={loc._id}
                          value={`${loc.subsidiary}-${loc.shortname}`}
                        >
                          {loc.subsidiary}-{loc.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl
              fullWidth
              required={userInput.department.includes("REVERSE-LOGISTICS")}
            >
              <InputLabel id="default-printer-select-label">
                Default Printer
              </InputLabel>
              <Select
                labelId="default-printer-select-label"
                id="default-printer-select"
                name="printer"
                label="Default Printer"
                value={userInput.printer}
                onChange={handleUserInputChange}
              >
                <MenuItem value="">None</MenuItem>
                {printers.map((printer) => {
                  return (
                    <MenuItem key={printer._id} value={printer.printername}>
                      {printer.printername}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="number"
              name="pacejetstation"
              label="Pacejet Workstation"
              value={userInput.pacejetstation}
              onChange={handleUserInputChange}
            />
          </Grid>
          {userInput.department.includes("SALES-REP") && (
            <Grid item xs={12}>
              <TextField
                InputProps={{
                  autoComplete: "off",
                }}
                fullWidth
                required
                name="company"
                label="Rep Agency"
                value={userInput.company}
                onChange={handleUserInputChange}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-rolelevel">
                Role Level
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-rolelevel"
                id="demo-simple-select-rolelevel"
                label="rolelevel"
                name="rolelevel"
                value={userInput.rolelevel}
                onChange={handleUserInputChange}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label-user-type">
                User Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-user-type"
                id="demo-simple-select-user-type"
                label="User Type"
                name="usertype"
                value={userInput.usertype}
                onChange={handleUserInputChange}
              >
                <MenuItem value="customer">Customer</MenuItem>
                <MenuItem value="employee">Employee</MenuItem>
                <MenuItem value="partner">Partner</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              name="company"
              label="Company"
              value={userInput.company}
              onChange={handleUserInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              name="manager"
              label="Manager"
              value={userInput.manager}
              onChange={handleUserInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label-timezone">
                Timezone
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-timezone"
                id="demo-simple-select-timezone"
                label="Timezone"
                name="timezone"
                value={userInput.timezone}
                onChange={handleUserInputChange}
              >
                <MenuItem value="PST">PST</MenuItem>
                <MenuItem value="EST">EST</MenuItem>
                <MenuItem value="GMT">GMT</MenuItem>
                <MenuItem value="CEST">CEST</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="number"
              name="netsuiteinternalid"
              label="Netsuite Internal Id"
              value={userInput.netsuiteinternalid}
              onChange={handleUserInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="salesrepcode"
              label="Sales Rep Code"
              type="number"
              id="salesrepcode"
              value={userInput.salesrepcode}
              onChange={handleUserInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="customercode"
              label="Customer Code"
              type="number"
              id="customercode"
              value={userInput.customercode}
              onChange={handleUserInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="partnercode"
              label="Partner Code"
              type="number"
              id="partnercode"
              value={userInput.partnercode}
              onChange={handleUserInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label-department">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-status"
                id="demo-simple-select-status"
                label="Status"
                name="status"
                value={userInput.status}
                onChange={handleUserInputChange}
              >
                <MenuItem value={"pending"}>Pending</MenuItem>
                <MenuItem value={"approved"}>Approved</MenuItem>
                <MenuItem value={"voided"}>Voided</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label-language">
                Language
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-language"
                id="demo-simple-select-language"
                label="Language"
                name="language"
                value={userInput.language}
                onChange={handleUserInputChange}
              >
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="es">Spanish</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="remote-access-label">Remote Access</InputLabel>
              <Select
                labelId="remote-access-label"
                value={userInput.remoteaccess}
                onChange={handleUserInputChange}
                label="Remote Access"
                name="remoteaccess"
              >
                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
            >
              {user ? "Edit User" : "Create User"}
            </Button>
          </Grid>
        </Grid>
      </FlexWrapper>
    </Container>
  );
};
export default UserForm;
