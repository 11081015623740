import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import axios from "../../../../axios/axios.config";

const StockLocatorLayout = () => {
  const { currentSubsidiary, currentNetsuiteLocationIds } = useSelector(
    (state) => state.user
  );

  const getMovesAndPickInProgress = async (inventoryData) => {
    try {
      const { binlocationid, iteminternalid, bininternalid, available } =
        inventoryData;

      //get pick in progress
      const pickInProgressResponse = await axios.get(
        `pick-order/get/total-item-bin-qty?subsidiary=${currentSubsidiary}&locationinternalid=${binlocationid}&lineiteminternalid=${iteminternalid}&bininternalid=${bininternalid}`
      );

      //get moves in progress
      const inventoryMoveInProgressResponse = await axios.get(
        `pick-order/get/total-inventory-move-qty?subsidiary=${currentSubsidiary}&locationinternalid=${binlocationid}&lineiteminternalid=${iteminternalid}&bininternalid=${bininternalid}`
      );

      return (
        available -
        (inventoryMoveInProgressResponse.data?.totalQtyBeingMoved +
          pickInProgressResponse.data?.totalQty)
      );
    } catch (error) {
      throw error;
    }
  };

  return (
    <Outlet
      context={{ getMovesAndPickInProgress, currentNetsuiteLocationIds }}
    />
  );
};
export default StockLocatorLayout;
