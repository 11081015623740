import { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Delete, Search } from "@mui/icons-material";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from "@mui/material";

import FlexWrapper from "../../FlexWrapper";
import ReceiveTransactionDeleteConfirmationModal from "./ReceiveTransactionDeleteConfirmationModal";
import Loading from "../../Loading";

const ReceiveTransactionItemsScannedView = ({
  transactionData: { receiveditems, items },
  setTransactionData,
}) => {
  const [itemToDelete, setItemToDelete] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");
  const [newItems, setNewItems] = useState([]);
  const itemInputRef = useRef(null);

  const { matches } = useOutletContext();

  useEffect(() => {
    getFilteredItemsData();
  }, []);

  //gets the full data for received items from items array
  const getFilteredItemsData = () => {
    setLoading("Loading Received Items...");
    const newFilteredItems = [];
    for (const recItem of receiveditems) {
      const foundItem = items.find((item) => item._id === recItem.itemsid);

      if (foundItem) {
        const newFoundItem = { ...foundItem };
        delete newFoundItem._id;
        newFilteredItems.push({ ...newFoundItem, ...recItem });
      }
    }
    setNewItems(newFilteredItems);
    setLoading("");
  };

  if (loading) return <Loading message={loading} />;

  const filteredItems =
    Boolean(search) && newItems.length
      ? newItems
          .filter(
            (item) =>
              item.item?.includes(search?.trim()) ||
              item.lineid?.toString()?.includes(search?.trim()) ||
              item.lineitemdescription?.includes(search?.trim()) ||
              (search.length > 10 && item.upc?.includes(search?.trim()))
          )
          .reverse()
      : [...newItems].reverse();

  return (
    <>
      <TextField
        label="Search Items"
        fullWidth={matches}
        size="small"
        sx={{ my: 1 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        value={search}
        onChange={(event) => setSearch(event.target.value.toUpperCase())}
      />
      <FlexWrapper justifyContent="center" width="100%">
        {/* Delete Confirmation Modal */}
        <ReceiveTransactionDeleteConfirmationModal
          item={itemToDelete}
          setItemToDelete={setItemToDelete}
          setTransactionData={setTransactionData}
        />
        <Box
          sx={{
            overflowY: "scroll",
            height: matches
              ? window.innerHeight - 190.75
              : "calc(100vh - 200px)",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          <Stepper
            alternativeLabel={false}
            orientation="vertical"
            sx={{ py: 1 }}
          >
            {filteredItems?.length ? (
              filteredItems.map((step) => (
                <Step key={step._id} completed>
                  <StepLabel
                    optional={
                      <Box>
                        <Typography>{step.qtyreceived} Received</Typography>
                        <Typography variant="body2">
                          Received By: {step.username}
                        </Typography>
                        <Typography variant="body2">
                          {new Date(step.timereceived).toLocaleString()}
                        </Typography>
                        <Typography variant="body2" fontWeight="bold">
                          Order Line ID: {step.lineid}
                        </Typography>
                      </Box>
                    }
                  >
                    <FlexWrapper flexDirection="column">
                      <FlexWrapper alignItems="center">
                        <Typography variant="h5">{step.item}</Typography>
                        {step._id && (
                          <Tooltip placement="top" title="Remove Item" arrow>
                            <IconButton onClick={() => setItemToDelete(step)}>
                              <Delete color="error" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </FlexWrapper>
                      <Box>
                        <Typography variant="caption" fontWeight="bold">
                          {step.lineitemdescription}
                        </Typography>
                      </Box>
                    </FlexWrapper>
                  </StepLabel>
                </Step>
              ))
            ) : (
              <FlexWrapper
                justifyContent="center"
                height="40vh"
                alignItems="flex-end"
              >
                <Typography variant="h5" fontWeight="bold">
                  No Received Items To Display
                </Typography>
              </FlexWrapper>
            )}
          </Stepper>
        </Box>
      </FlexWrapper>
    </>
  );
};

export default ReceiveTransactionItemsScannedView;
