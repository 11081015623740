import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import GeneralModalV3 from "../../general/GeneralModalV3";
import axios from "../../../axios/axios.config";
import FlexWrapper from "../../FlexWrapper";

const PackProcessViewDeleteOrderModal = ({ order, setLoading, setOrders }) => {
  const { openAlert } = useGeneralAlert();
  const { currentSubsidiary, currentNetsuiteLocationIds } = useSelector(
    (state) => state.user
  );

  const handleDelete = async ({ handleClose }) => {
    setLoading("Deleting Order...");
    try {
      await axios.delete(
        `pack-process-v2/view-orders-page/delete/${order._id}`
      );
      await getOrders();

      handleClose();
      openAlert({
        type: "success",
        message: `Successfully Deleted ${order.createdfrom}`,
        duration: 2000,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not delete order: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  const getOrders = async () => {
    setLoading("Loading Orders...");

    try {
      // Run both requests in parallel using Promise.all
      const [response1, response2] = await Promise.all([
        axios.get(
          `pack-process/view-orders?subsidiary=${currentSubsidiary}&locations=${JSON.stringify(
            currentNetsuiteLocationIds
          )}&status=open`
        ),
        axios.get(
          `pack-process-v2/view-orders-page/view-by-status?subsidiary=${currentSubsidiary}&locations=${JSON.stringify(
            currentNetsuiteLocationIds
          )}&status=open`
        ),
      ]);

      // Combine the results from both responses
      const combinedOrders = [
        ...(response1.data || []),
        ...(response2.data || []),
      ];
      setOrders(combinedOrders.length ? combinedOrders : []);
    } catch (error) {
      throw error;
    }
  };

  return (
    <GeneralModalV3
      openComponent={
        <Tooltip title="Delete Order" placement="top" arrow>
          <IconButton size="small">
            <Delete fontSize="12px" color="error" />
          </IconButton>
        </Tooltip>
      }
    >
      {(handleClose) => (
        <Box>
          <Typography textAlign="center" variant="h4" py={0.5}>
            Delete <strong>{order.createdfrom}</strong>?
          </Typography>
          <Divider />
          <Typography textAlign="center" p={2} variant="h6">
            Order will be permanently removed and you will no longer be able to
            view it.
          </Typography>
          <Divider />
          <FlexWrapper justifyContent="flex-end" p={1}>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDelete({ handleClose })}
            >
              Delete
            </Button>
          </FlexWrapper>
        </Box>
      )}
    </GeneralModalV3>
  );
};
export default PackProcessViewDeleteOrderModal;
