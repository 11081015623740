import { useLoaderData } from "react-router-dom";
import { Typography } from "@mui/material";

import UpdatePackOrderResultsV1 from "../../../../components/multi-page/pack-process/update-pack-order/UpdatePackOrderResultsV1";
import UpdatePackResultsV2 from "../../../../components/multi-page/pack-process/update-pack-order/UpdatePackResultsV2";

const UpdatePackResultPage = () => {
  const data = useLoaderData();

  if (data === null) {
    return (
      <Typography variant="h4" textAlign="center" pt={3}>
        Could Not Get Data, Please Refresh
      </Typography>
    );
  }

  if (data.type === "v1") {
    return <UpdatePackOrderResultsV1 data={data} />;
  }

  if (data.type === "v2") {
    return <UpdatePackResultsV2 data={data} />;
  }
};
export default UpdatePackResultPage;
