import { useState } from "react";
import { Email, Message } from "@mui/icons-material";
import { sendGeneralEmail } from "../../../utils/email/generalEmailFunction";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import GeneralModalV3 from "../../general/GeneralModalV3";

const CycleCountsSendEmailModal = ({ onClose, bin, type, jobName, items }) => {
  const [message, setMessage] = useState("");

  const { username, currentSubsidiary } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();

  const handleSendMessage = async (closeFn) => {
    let toEmail = null;
    switch (currentSubsidiary) {
      case "AIT":
        toEmail = "cyclecounts@trakmotive.com";
        break;
      case "WGE":
        toEmail = "cyclecounts@wonheurope.com";
        break;
      default:
        toEmail = null;
    }

    const emailBody = {
      to: process.env.REACT_APP_TEST_EMAIL || toEmail,
      subject: `Cycle Counts Alert Notification`,
      body: `<body style="font-family: Arial, sans-serif; color: #333;">
  <div style="max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px;">
    <h2 style="color: #007BFF;">Issue Report Details</h2>
    <p><strong>User:</strong> ${username}</p>
    <p><strong>Type:</strong> ${type}</p>
    <p><strong>Job Name:</strong> ${jobName}</p>
    <p><strong>Bin:</strong> ${bin}</p>
    <p><strong>Items:</strong> ${items}</p>
    <p><strong>Date Reported:</strong> ${new Date().toLocaleString()}</p>
    <p><strong>Issue Reported:</strong></p>
    <p style="background-color: #f9f9f9; padding: 15px; border-left: 4px solid #007BFF;">${message}</p>
  </div>
</body>`,
      isHtml: true,
    };

    try {
      await sendGeneralEmail(emailBody);
      openAlert({
        type: "success",
        message: "Successfully Sent Email",
        duration: 2000,
      });
      closeFn();
      //run onClose fn if provided
      if (onClose) {
        onClose();
      }

      setMessage("");
    } catch (error) {
      openAlert({
        type: "success",
        message: `Could Not Send Email: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 5000,
      });
    }
  };

  return (
    <GeneralModalV3
      keepMounted={false}
      onClose={() => {
        if (onClose) {
          onClose();
        }
        setMessage("");
      }}
      openComponent={
        <IconButton>
          <Message color="primary" />
        </IconButton>
      }
    >
      {(handleClose) => (
        <>
          <Typography variant="h5" textAlign="center" gutterBottom pt={1}>
            Alert Inventory Control
          </Typography>
          <Divider />
          <Box p={1}>
            <Typography>
              <strong>Bin:</strong> {bin}
            </Typography>
            <Typography gutterBottom>
              <strong>Job Name:</strong> {jobName}
            </Typography>

            <TextField
              sx={{ mt: 1 }}
              autoFocus
              fullWidth
              label="Message"
              placeholder="Message to send to Inventory Control Team"
              multiline
              InputProps={{ minRows: 4 }}
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
          </Box>
          <Divider />

          <Box px={1}>
            <Button
              onClick={() => handleSendMessage(handleClose)}
              disabled={!Boolean(message)}
              type="button"
              sx={{ my: 1 }}
              fullWidth
              variant="contained"
              endIcon={<Email />}
            >
              Send
            </Button>
          </Box>
        </>
      )}
    </GeneralModalV3>
  );
};

export default CycleCountsSendEmailModal;
