import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "../../../../axios/axios.config";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { EMPTYBINSREPORTCOLUMNS } from "../../../../utils/columns/inventory-reports/inventoryReportsColumns";
import { RestartAlt } from "@mui/icons-material";
import FlexWrapper from "../../../../components/FlexWrapper";

const EmptyBinsReport = () => {
  const [reportData, setReportData] = useState([]);
  const [startBin, setStartBin] = useState("");
  const [endBin, setEndBin] = useState("");
  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");
  const { currentNetsuiteLocationIds } = useSelector((state) => state.user);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading("Loading Bin Data...");
    setError("");
    try {
      const response = await axios.get(
        `netsuite/inventory-reports/get/empty-bins?nsLocations=${currentNetsuiteLocationIds?.join(
          ","
        )}&startBin=${startBin}&endBin=${endBin}`
      );

      if (response.data?.length === 0) {
        throw new Error("No Data Found");
      }

      setReportData(response.data);
    } catch (error) {
      setError(error.response?.data?.msg || error.message);
    } finally {
      setLoading("");
    }
  };

  const handleReset = () => {
    setStartBin("");
    setEndBin("");
    setReportData([]);
  };

  return (
    <Container maxWidth={reportData?.length === 0 ? "md" : "xl"} sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Empty Bins Report
      </Typography>

      {/* DataGrid to display bin data */}
      {reportData.length > 0 ? (
        <Box sx={{ width: "100%" }}>
          <FlexWrapper justifyContent="flex-end">
            <Button
              variant="contained"
              size="medium"
              endIcon={<RestartAlt />}
              onClick={handleReset}
            >
              Reset Search
            </Button>
          </FlexWrapper>
          <DataGrid
            sx={{ height: "75vh", mt: 1 }}
            rows={reportData}
            columns={EMPTYBINSREPORTCOLUMNS}
            disableRowSelectionOnClick
            getRowId={(row) => row.binnumber}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: { fileName: `empty-bins-${Date.now()}` },
                printOptions: { disableToolbarButton: true },
              },
            }}
          />
        </Box>
      ) : (
        <Box
          component="form"
          onSubmit={handleSubmit}
          display="flex"
          flexDirection="column"
          gap={2}
          alignItems="center"
          mb={3}
        >
          <TextField
            error={Boolean(error)}
            autoFocus
            label="Enter Start Bin"
            value={startBin}
            onChange={(e) => setStartBin(e.target.value?.toUpperCase())}
            fullWidth
            required
          />
          <TextField
            error={Boolean(error)}
            label="Enter End Bin"
            value={endBin}
            onChange={(e) => setEndBin(e.target.value?.toUpperCase())}
            fullWidth
            required
          />
          {Boolean(error) && (
            <Typography
              color="error"
              textAlign="center"
              variant="caption"
              component="p"
            >
              {error}
            </Typography>
          )}
          <Button variant="contained" type="submit" disabled={loading}>
            {loading ? loading : "Search"}
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default EmptyBinsReport;
