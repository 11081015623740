import { Divider, IconButton, Stack, Typography } from "@mui/material";
import GeneralModalV3 from "../../general/GeneralModalV3";
import { Info } from "@mui/icons-material";

const ReceiveTransactionItemInfoModal = ({ item, handleItemRefFocus }) => {
  return (
    <GeneralModalV3
      onClose={() => handleItemRefFocus()}
      openComponent={
        <IconButton size="small">
          <Info color="primary" fontSize="small" />
        </IconButton>
      }
    >
      <Typography variant="h4" textAlign="center" py={1}>
        {item.lineitemname}
      </Typography>
      <Divider />
      <Stack p={1}>
        <Typography variant="h6">
          <strong>Qty: </strong> {item.lineitemqty}
        </Typography>
        <Typography variant="h6">
          <strong>Received: </strong> {item.lineitemqtyreceived}
        </Typography>
        <Typography variant="h6">
          <strong>Remaining: </strong>{" "}
          {item.lineitemqty - item.lineitemqtyreceived}
        </Typography>
      </Stack>
    </GeneralModalV3>
  );
};
export default ReceiveTransactionItemInfoModal;
