export const PICKTOONTOSOCOLUMNS = [
  {
    field: "itemname",
    headerName: "Item",
    type: "string",
    align: "center",
    headerAlign: "center",
    flex: 1,
  },
  {
    field: "quantityreceived",
    headerName: "Qty",
    type: "number",
    align: "center",
    headerAlign: "center",
    flex: 1,
  },
];
