import { useDispatch, useSelector } from "react-redux";
import { setCurrentDepartment } from "../../redux/features/user/userSlice";
import { departmentMap } from "../../utils/menus/menu";
import { Box, Container, List, Typography } from "@mui/material";
import {
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";

import NestedMenuListItem from "../../components/NestedMenuListItem";
import MenuListItem from "../../components/MenuListItem";
import SpeedDialSelector from "../../components/SpeedDialSelector";

const PortalMenu = () => {
  const { currentDepartment, department, rolelevel } = useSelector(
    (state) => state.user
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  // causing whole app to refresh when search params were created/deleted
  // if (navigation.state === "loading") {
  //   return <Loading message="Navigating..." />;
  // }

  return location.pathname === `/portal/${currentDepartment.toLowerCase()}` ? (
    <Container
      maxWidth="xl"
      sx={{ position: "relative", minHeight: "85vh", my: 1 }}
    >
      <Typography
        variant="h5"
        component="h1"
        fontWeight="bold"
        gutterBottom
        textAlign="center"
        pt={2}
      >
        {`${currentDepartment} PORTAL`.replace("-", " ")}
      </Typography>

      <Box display="flex" justifyContent="center" pt={1}>
        <List sx={{ maxWidth: "sm" }}>
          {currentDepartment &&
            departmentMap[currentDepartment].map((menu, index) => {
              return menu.nested ? (
                !menu.admin || (menu.admin && menu.admin(rolelevel)) ? (
                  <NestedMenuListItem
                    key={menu.title}
                    title={menu.title}
                    icon={menu.icon}
                    description={menu.description}
                    submenus={menu.submenus}
                    lastIndex={departmentMap[currentDepartment].length - 1}
                    index={index}
                    rolelevel={rolelevel}
                  />
                ) : null
              ) : (
                (!menu.admin || (menu.admin && menu.admin(rolelevel))) && (
                  <MenuListItem
                    key={menu.title}
                    title={menu.title}
                    icon={menu.icon}
                    description={menu.description}
                    to={menu.to}
                    index={index}
                    lastIndex={departmentMap[currentDepartment].length - 1}
                    rolelevel={rolelevel}
                  />
                )
              );
            })}
        </List>
      </Box>
      {currentDepartment && department && department.length > 1 ? (
        <SpeedDialSelector
          actions={department.filter((dept) => dept !== currentDepartment)}
          iconFn={(dept) => {
            dispatch(setCurrentDepartment(dept));
            navigate(`/portal/${dept.toLowerCase()}`);
          }}
        />
      ) : null}
    </Container>
  ) : (
    <Outlet />
  );
};
export default PortalMenu;
