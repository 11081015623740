export const PACKPROCESSDETAILSITEMSCOLUMNS = [
  {
    id: "item",
    label: "Item",
    minWidth: "20%",
    align: "center",
  },
  { id: "quantity", label: "Qty", minWidth: "20%", align: "center" },
  {
    id: "binnumber",
    label: "Bin Number",
    minWidth: "20%",
    align: "center",
    format: (value) => {
      return Array.isArray(value) ? value.join(" | ") : value;
    },
  },
  {
    id: "iful",
    label: "Fulfillment",
    minWidth: "20%",
    align: "center",
    format: (value) => {
      return Array.isArray(value) ? value.join(" | ") : value;
    },
  },
];
