import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { openGeneralAlert } from "../../../redux/features/alert/alertSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  findNextBin,
  refreshBinData,
  resetAllItems,
  resetPickData,
  reverseWalkRoute,
  updateLoading,
} from "../../../redux/features/pick-order/pickOrderSlice";
import {
  updatePickLockedBy,
  updatePickLockedByMongo,
} from "../../../utils/helper-functions/pick-order/updatePickLockedBy";

import PickOrderActionsWarningModal from "./PickOrderActionsWarningModal";
import { fulfillPickOrder } from "../../../utils/helper-functions/pick-order/fulfillPickOrder";
import { useGeneralAlert } from "../../../hooks/useGeneralAlert";

const PickOrderActions = ({ value }) => {
  const [actionModal, setActionModal] = useState({
    isOpen: false,
    type: "",
    action: () => {},
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {
    path,
    pick,
    internalid,
    tranid,
    _id,
    trantype,
    onefulfillment,
    lastpicktime,
    lastpickedby,
    firstpicktime,
    splitnumber,
    ponumber,
    destinationlocationid,
  } = useSelector((state) => state.pickOrder);
  const { username, currentDepartment, pacejetstation, rolelevel } =
    useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { openAlert } = useGeneralAlert();

  const redirect = searchParams.get("redirect");
  const originalPage = searchParams.get("original-page");

  const handleOpenActionsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //partially fulfills order completely server side (matches with pick order view fn)
  const handleServerFulfillAction = async () => {
    try {
      dispatch(updateLoading("Fulfilling Order..."));
      //fulfill order and autorecives if applicable
      await fulfillPickOrder({
        username,
        pick,
        trantype,
        tranid,
        _id,
        orderid: internalid,
        lastpicktime,
        lastpickedby,
        firstpicktime,
        pacejetstation: pacejetstation ? pacejetstation : null,
        custbody_ait_so_to_related_fulfill: false,
        ponumber,
        destinationlocationid,
      });

      openAlert({
        type: "success",
        message: `Successfully Partially Fulfilled ${
          trantype === "transferorder" && ponumber && destinationlocationid
            ? "and Received"
            : ""
        } ${tranid}`,
        duration: 3000,
      });

      //update pickLockedBy field in netsuite
      updatePickLockedBy({ username: "", internalid, trantype });

      //reset pick data
      dispatch(resetPickData());

      //redirect user
      let redirectUrl = `/portal/${currentDepartment?.toLowerCase()}/pick-order`;
      let originalPageUrl = searchParams.get("original-page")
        ? searchParams.get("original-page")
        : null;

      if (searchParams.get("redirect")) {
        redirectUrl = searchParams.get("redirect");

        if (originalPageUrl) {
          redirectUrl += `&original-page=${encodeURIComponent(
            originalPageUrl
          )}`;
        }
      }

      navigate(redirectUrl, {
        replace: true,
      });
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Fulfill ${tranid}: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });

      dispatch(updateLoading(""));
    }
  };

  const handleReverseWalkRouteAction = async () => {
    handleClose();
    await dispatch(reverseWalkRoute()).unwrap();
    await dispatch(findNextBin({ _id })).unwrap();
  };

  const handleResetAllItemsAction = async () => {
    handleClose();
    await dispatch(resetAllItems()).unwrap();
    await dispatch(findNextBin({ _id })).unwrap();
  };

  const handleExitPickingAction = () => {
    let backPage = `/portal/${currentDepartment?.toLowerCase()}/pick-order`;
    if (redirect) {
      if (originalPage) {
        backPage = originalPage;
      } else {
        backPage = redirect;
      }
    }
    navigate(backPage, {
      replace: true,
    });
    dispatch(resetPickData());
    handleClose();
    //update pickLockedBy field in netsuite if not split order else clear mongo pick locked by
    if (!splitnumber) {
      updatePickLockedBy({ username: "", internalid, trantype });
    } else {
      updatePickLockedByMongo({
        id: _id,
        value: "",
      });
    }

    dispatch(
      openGeneralAlert({
        type: "success",
        message: `Successfully Exited ${tranid}`,
        duration: 3000,
      })
    );
  };

  const handleRefreshBinDataAction = async () => {
    handleClose();
    await dispatch(refreshBinData()).unwrap();
    await dispatch(findNextBin({ _id })).unwrap();
  };

  return (
    <Box sx={{ alignSelf: "flex-end" }}>
      <PickOrderActionsWarningModal
        open={actionModal.isOpen}
        closeFn={() => {
          setActionModal({ type: "", action: () => {}, isOpen: false });
          handleClose();
        }}
        type={actionModal.type}
        action={actionModal.action}
      />
      <Button
        variant="contained"
        size="small"
        onClick={handleOpenActionsMenu}
        endIcon={<KeyboardArrowDown />}
        sx={{ p: 0.5 }}
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ textTransform: "uppercase" }}
      >
        {path.length !== 0 && onefulfillment !== "T" && (
          <MenuItem
            onClick={() =>
              setActionModal({
                isOpen: true,
                type: "partial-fulfill",
                action: handleServerFulfillAction,
              })
            }
          >
            Partial Fulfill
          </MenuItem>
        )}
        {/* Reverse path only */}
        {value === "current" && (
          <MenuItem onClick={handleReverseWalkRouteAction}>
            Reverse Walkroute
          </MenuItem>
        )}
        {/* Call ns and get path and filter out already picked data, and update picked amount */}
        {value === "current" && (
          <MenuItem
            onClick={() =>
              setActionModal({
                isOpen: true,
                type: "refresh-bin",
                action: handleRefreshBinDataAction,
              })
            }
          >
            Refresh Bin Data
          </MenuItem>
        )}
        {/* Filter By Bin Range */}
        {value === "current" && Boolean(path.length) && (
          <MenuItem
            onClick={() =>
              setActionModal({
                isOpen: true,
                type: "filter-bin-range",
                action: () => {},
              })
            }
          >
            Filter Bin Range
          </MenuItem>
        )}
        {/* Call ns and get path and clear pick */}
        {value === "scanned" && parseInt(rolelevel) <= 1 && (
          <MenuItem
            onClick={() =>
              setActionModal({
                isOpen: true,
                type: "reset-all-items",
                action: handleResetAllItemsAction,
              })
            }
          >
            Reset All Items
          </MenuItem>
        )}
        {/* Clear picklockedby field and return to search page */}
        <MenuItem
          onClick={() =>
            setActionModal({
              isOpen: true,
              type: "cancel-pick",
              action: handleExitPickingAction,
            })
          }
        >
          Pause Picking
        </MenuItem>
      </Menu>
    </Box>
  );
};
export default PickOrderActions;
