import { handleBrowserPrintToPrinter } from "./browserPrint";

import axios from "../../axios/axios.config";

export const printLabel = (tote, printer) => {
  let printJobUrl = "https://api.printnode.com/printjobs";
  let apiID = process.env.REACT_APP_PRINT_API_ID;
  let labelTemplate = `^XA^MMT
  ^PW1200^LL0600
  ^LS0^PON^LH0,0
  ^FT40,275^BY7,2,250^BCN,,N,N^FD${tote.toteid}^FS
  ^FT25,460^FB1150,2,0,L,0^A0N,100^FD${tote.toteid}^FS
  ^FT25,520^FB1150,2,0,L,0^A0N,60^FD${tote.tranref}  |  ${tote.memo}^FS
  ^FT25,570^FB1150,2,0,L,0^A0N,30^FDCreated By: ${tote.createdby} ${new Date(
    tote.createdAt
  ).toLocaleString()}^FS
  ^FT25,600^FB1150,2,0,L,0^A0N,30^FDLast Updated By: ${
    tote.lastupdatedby
  } ${new Date(tote.updatedAt).toLocaleString()}^FS
  ^PQ@QTY@,0,1,Y^XZ`;

  let body = {
    printerId: printer,
    title: "New Tote Label: " + tote.toteid,
    contentType: "raw_base64",
    content: btoa(labelTemplate),
    source: "MERN > printLabel",
  };

  axios
    .post(printJobUrl, body, {
      headers: {
        Authorization: "Basic " + btoa(apiID),
        "Content-Type": "application/json",
      },
    })
    .then((res) => console.log(res))
    .catch((err) => console.log(err));
};

export const printGenericLabel = async ({
  currentPrinter,
  barcode,
  size,
  additional,
  qty,
  alertFn = null,
}) => {
  try {
    if (!currentPrinter) {
      throw new Error(
        "No printer is selected. Please select a printer in your settings."
      );
    }

    let printJobUrl = "https://api.printnode.com/printjobs";
    let apiID = process.env.REACT_APP_PRINT_API_ID;
    var labelTemplate = "";

    if (size === "4x2") {
      labelTemplate =
        "^XA^MMT^PW1200^LL0600^LS0^PON^LH0,0^FT40,400^BY5,2,300^BCN,,Y,N^FD@REPLACETEXT@^FS^FT55,625^FB1150,2,0,L,0^A0N,60^FD@ADDTLTEXT@^FS^PQ@QTY@,0,1,Y^XZ";
    } else if (size === "4x6") {
      labelTemplate =
        "^XA^MMT^PW1200^LL1800^LS0^PON^LH0,0^FT620,140^BY10,3,517^BCR,,N,N^FD@REPLACETEXT@^FS^FT140,140^FB1700,2,0,L,0^A0R,259^FD@REPLACETEXT@^FS^FT75,100^FB1600,2,0,C,0^A0R,111^FD@ADDTLTEXT@^FS^PQ@QTY@,0,1,Y^XZ";
    } else if (size === "75x65") {
      labelTemplate =
        "^XA^MMT^PW880^LL760^LS0^PON^LH0,0^FT40,375^BY4,2,300^BCN,,Y,N^FD@REPLACETEXT@^FS^FT55,700^FB800,4,0,C,0^A0N,60^FD@ADDTLTEXT@^FS^PQ@QTY@,0,1,Y^XZ";
    }
    labelTemplate = labelTemplate.replaceAll("@REPLACETEXT@", barcode);
    labelTemplate = labelTemplate.replace("@ADDTLTEXT@", additional);
    labelTemplate = labelTemplate.replace("@QTY@", parseInt(qty));

    if (
      currentPrinter.printername?.startsWith("USB") &&
      currentPrinter.printnodeid === 0
    ) {
      //print using browser printer
      handleBrowserPrintToPrinter({
        currentPrinter,
        alertFn,
        template: labelTemplate,
      });
    } else {
      let body = {
        printerId: currentPrinter?.printnodeid,
        title: "Generic Label: " + barcode,
        contentType: "raw_base64",
        content: btoa(labelTemplate),
        source: "MERN > printGenericLabel",
      };

      return axios.post(printJobUrl, body, {
        headers: {
          Authorization: "Basic " + btoa(apiID),
          "Content-Type": "application/json",
        },
      });
    }
  } catch (error) {
    throw error;
  }
};

const getSupersededItem = async (ssPart, subsidiary) => {
  try {
    const item = await axios.get(`items/${ssPart}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      params: {
        subsidiary,
      },
    });
    return item.data;
  } catch (error) {
    console.log(error);
  }
};

export const printSupersededLabel = async (
  originalItem,
  ssPart,
  printer,
  subsidiary
) => {
  const data = await getSupersededItem(ssPart, subsidiary);
  const item = await data[0];
  const template = `^XA^MMT^PW827^LL472^LS0
  ^PON^LH0,0
  ^FT760,40^A0N,34^FD${item.boxsize}^FS
  ^FT0,135^FB827,1,0,C,0^A0N,120,110^FD${item.item}^FS
  ^FT0,225^FB827,1,0,C,0^A0N,90,75^FD${
    item.basenumber === item.item ? "" : item.basenumber
  }^FS
  ^BY7,2,150^FT85,400^BUN,,Y,N^FD${item.upc}^FS
  ^PQ${item.qty},0,1,Y^XZ
  `;

  let printJobUrl = "https://api.printnode.com/printjobs";
  let apiID = process.env.REACT_APP_PRINT_API_ID;
  let body = {
    printerId: printer,
    title: `Superseded Item Label Print: ${originalItem["Item:"]} -> ${ssPart}`,
    contentType: "raw_base64",
    content: btoa(template),
    source: "MERN > Item Disposition > printSupersededLabel",
  };

  axios
    .post(printJobUrl, body, {
      headers: {
        Authorization: "Basic " + btoa(apiID),
        "Content-Type": "application/json",
      },
    })
    .then((res) => console.log("Print superseded", res.data))
    .catch((err) => console.log(err));
};

export const printItemCrossRef = async (
  crossReferencedItem,
  originalItem,
  printer,
  brandText,
  userinitials = ""
) => {
  //old template
  //   const template = `^XA^MMT^PW827^LL472^LS0
  //   ^PON^LH0,0
  //  ^FT20,35^A0N,30^FD${brandText ? brandText : "Return to Vendor"}^FS
  //   ^FT760,40^A0N,34^FD${originalItem.boxsize}^FS
  //   ^FT0,135^FB827,1,0,C,0^A0N,120,110^FD${originalItem.item}^FS
  //   ^FT0,225^FB827,1,0,C,0^A0N,90,75^FD${
  //     crossReferencedItem.bpn ? crossReferencedItem.bpn : crossReferencedItem.upc
  //   }^FS
  //   ^BY7,2,150^FT85,400^BUN,,Y,N^FD${originalItem.upc}^FS
  //   ^PQ${originalItem.qty},0,1,Y^XZ
  //   `;

  //new template with initials
  const template = `^XA^MMT^PW827^LL472^LS0
^PON^LH0,0
^FT20,55^A0N,50^FD${brandText ? brandText : "Return to Vendor"}^FS
^FT20,100^A0N,50^FD${userinitials}^FS
^FT740,55^A0N,50^FD${originalItem.boxsize}^FS
^FT0,110^GB830,1,2,B^FS
^FT0,200^FB827,1,0,C,0^A0N,100^FD${originalItem.item}^FS
^FT0,270^FB827,1,0,C,0^A0N,80^FD${
    crossReferencedItem.bpn ? crossReferencedItem.bpn : crossReferencedItem.upc
  }^FS
^BY7,2,140^FT85,425^BUN,,Y,N^FD${originalItem.upc}^FS
^PQ${originalItem.qty},0,1,Y^XZ`;

  let printJobUrl = "https://api.printnode.com/printjobs";
  let apiID = process.env.REACT_APP_PRINT_API_ID;
  let body = {
    printerId: printer,
    title: `Cross Reference Item Label Print: ${
      crossReferencedItem.bpn
        ? crossReferencedItem.bpn
        : crossReferencedItem.upc
    } -> ${originalItem.item}`,
    contentType: "raw_base64",
    content: btoa(template),
    source: "MERN > Item Disposition > printItemCrossRef",
  };

  axios
    .post(printJobUrl, body, {
      headers: {
        Authorization: "Basic " + btoa(apiID),
        "Content-Type": "application/json",
      },
    })
    .then((res) => console.log("Print cross reference label", res.data))
    .catch((err) => console.log(err));
};

export const printIndividualItemLabel = (
  item,
  qty,
  printer,
  crossReferencedItem = null
) => {
  //qty dictates how many labels are printed
  const template = `^XA^MMT^PW827^LL472^LS0
  ^PON^LH0,0
  ^FT760,40^A0N,34^FD${item.boxsize}^FS
  ^FT0,135^FB827,1,0,C,0^A0N,120,110^FD${item.item}^FS
  ${
    crossReferencedItem
      ? `^FT0,225^FB827,1,0,C,0^A0N,90,75^FD${
          crossReferencedItem.bpn
            ? crossReferencedItem.bpn
            : crossReferencedItem.upc
        }^FS`
      : ""
  }
  ^FT0,225^FB827,1,0,C,0^A0N,90,75^FD${
    item.basenumber === item.item ? "" : item.basenumber
  }^FS
  ^BY7,2,150^FT85,400^BUN,,Y,N^FD${item.upc}^FS
  ^PQ${qty},0,1,Y^XZ 
  `;

  let printJobUrl = "https://api.printnode.com/printjobs";
  let apiID = process.env.REACT_APP_PRINT_API_ID;
  let body = {
    printerId: printer,
    title: `Print Item Label: ${item.item}`,
    contentType: "raw_base64",
    content: btoa(template),
    source: "MERN > Print Item Label > printIndividualItemLabel",
  };

  axios
    .post(printJobUrl, body, {
      headers: {
        Authorization: "Basic " + btoa(apiID),
        "Content-Type": "application/json",
      },
    })
    .then((res) => console.log("Print Item Label", res.data))
    .catch((err) => console.log(err));
};

// Item Disposition
export const printItemPlainLabel = async ({
  brandText,
  item,
  printer,
  userinitials = "",
}) => {
  //old template
  //   const template = `^XA^MMT^PW827^LL472^LS0
  //   ^PON^LH0,0
  //  ^FT20,35^A0N,30^FD${brandText ? brandText : "Return to Vendor"}^FS
  //   ^FT760,40^A0N,34^FD${item.boxsize}^FS
  //   ^FT0,135^FB827,1,0,C,0^A0N,120,110^FD${item.item}^FS
  //   ^FT0,225^FB827,1,0,C,0^A0N,90,75^FD${
  //     item.basenumber === item.item ? "" : item.basenumber
  //   }^FS
  //   ^BY7,2,150^FT85,400^BUN,,Y,N^FD${item.upc}^FS
  //   ^PQ${item.qty},0,1,Y^XZ
  //   `;

  //new template with initial
  const template = `^XA^MMT^PW827^LL472^LS0
^PON^LH0,0
^FT20,55^A0N,50^FD${brandText ? brandText : "Return to Vendor"}^FS
^FT20,100^A0N,50^FD${userinitials}^FS
^FT740,55^A0N,50^FD${item.boxsize}^FS
^FT0,110^GB830,1,2,B^FS
^FT0,200^FB827,1,0,C,0^A0N,100^FD${item.item}^FS
^FT0,270^FB827,1,0,C,0^A0N,80^FD${
    item.basenumber === item.item ? "" : item.basenumber
  }^FS
^BY7,2,140^FT85,425^BUN,,Y,N^FD${item.upc}^FS
^PQ${item.qty},0,1,Y^XZ`;

  let printJobUrl = "https://api.printnode.com/printjobs";
  let apiID = process.env.REACT_APP_PRINT_API_ID;
  let body = {
    printerId: printer,
    title: `Item Plain Label Print -> ${item.item}`,
    contentType: "raw_base64",
    content: btoa(template),
    source: "MERN > Item Disposition > printItemCrossRef",
  };

  try {
    await axios.post(printJobUrl, body, {
      headers: {
        Authorization: "Basic " + btoa(apiID),
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    throw error;
  }
};
