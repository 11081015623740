import axios from "../../axios/axios.config";

export const updatePackResultsPageLoader = async ({ params }) => {
  const { orderid } = params;
  try {
    // First API call if it fails it will fall back to second url
    let response = await axios.get(`pack-process/update-pack-order/${orderid}`);

    // Process the pack details
    return processPackDetails(response.data);
  } catch (error) {
    if (error?.response?.status === 404) {
      try {
        // Second API call if the first fails with 404
        const response = await axios.get(
          `pack-process-v2/${orderid}?populate=true`
        );

        if (
          !response.data.packdetails ||
          !Object.keys(response.data.packdetails).length
        ) {
          throw new Error("Pack details not found in second request.");
        }

        // Process the pack details from the second response
        return processPackDetails(response.data, "v2");
      } catch (secondError) {
        throw new Error(secondError.response?.data?.msg || secondError.message);
      }
    }

    // If error is not a 404, rethrow it
    throw new Error(error.response?.data?.msg || error.message);
  }
};

// Helper function to process the pack details
const processPackDetails = (data, type = "v1") => {
  const packDetails = {};

  if (type === "v1") {
    const palletKeys = Object.keys(data.packdetails);

    for (const pallet of palletKeys) {
      if (!packDetails[pallet]) {
        packDetails[pallet] = { ...data.packdetails[pallet] };
      }

      const palletData = packDetails[pallet];

      if (palletData.items) {
        delete palletData.items;
      }

      if (!palletData.images) {
        palletData.originalImages = [];
      } else {
        palletData.originalImages = palletData.images;
      }

      palletData.newImages = [];
      palletData.isupdated = false;
    }
    return {
      palletKeys,
      currentPallet: palletKeys[0],
      packDetails,
      order: data.createdfrom,
      isImagesRequired: data.palletimages,
      discrepancies: data.discrepancies,
      palletimages: data.palletimages,
      type,
    };
  } else {
    return {
      packDetails: data.packdetails.map((obj) => {
        return { ...obj, newImages: [] };
      }),
      order: data.createdfrom,
      isImagesRequired: data.palletimages,
      discrepancies: data.discrepancies,
      type,
      ponumber: data.ponumber,
      brand: data.brand,
      shipmentsequence: data.shipmentsequence,
    };
  }
};

//loader for pack process results page (no longer being used)
export const packProcessResultsPageLoader = async ({ params }) => {
  try {
    const response = await axios.get(`pack-process-v2/${params.orderid}`);

    return response.data;
  } catch (error) {
    return null;
  }
};
