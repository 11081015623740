import { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { ArrowRightSharp, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";
import FlexWrapper from "../../../../components/FlexWrapper";

const EnterCycleCountsPage = () => {
  const [searchInput, setSearchInput] = useState("");
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState("Loading Data...");

  const { openAlert, user } = useOutletContext();

  useEffect(() => {
    getJobsData();
  }, []);

  //gets job data and filters them
  const getJobsData = async () => {
    setLoading("Loading Cycle Counts...");
    try {
      const response = await axios.get(
        `cycle-counts-jobs/get/all?location=${user.currentLocation}`
      );

      if (response.data) {
        const remainingNew = response.data.filter(
          (obj) => obj.startedby?._id !== user._id && obj.status === "new"
        );

        const remainingBeingCounted = response.data.filter(
          (obj) =>
            obj.startedby?._id !== user._id && obj.status === "beingcounted"
        );

        setJobs({
          all: response.data,
          own: response.data.filter((obj) => obj.startedby?._id === user._id),
          remaining: [...remainingNew, ...remainingBeingCounted],
        });
      }
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Get Data: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 10000,
      });
    } finally {
      setLoading("");
    }
  };

  const handleChanges = (event) => {
    setSearchInput(event.target.value.toUpperCase());
  };

  const handleRevertToNew = async (job) => {
    setLoading("Reverting Count...");
    try {
      const response = await axios.patch(
        `cycle-counts-jobs/update/${job._id}/enter/revert-count`
      );

      await getJobsData();

      openAlert({
        type: "success",
        message: "Successfully reverted cycle count",
        duration: 2000,
      });
    } catch (error) {
      setLoading("");
      openAlert({
        type: "error",
        message: `Could Not Get Data: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 10000,
      });
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  if (!jobs.all?.length) {
    return (
      <Container maxWidth="md">
        <Typography textAlign="center" variant="h4" pt={2}>
          No Counts Needed
        </Typography>
      </Container>
    );
  }

  const filteredJobs =
    Boolean(searchInput) && jobs?.all?.length
      ? jobs.all.filter((job) =>
          job.name.toLowerCase().includes(searchInput.toLowerCase())
        )
      : null;

  return (
    <Container maxWidth="md">
      <Typography variant="h4" textAlign="center" gutterBottom>
        Enter Cycle Counts
      </Typography>

      <TextField
        fullWidth
        label="Search"
        variant="outlined"
        value={searchInput}
        onChange={handleChanges}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />

      <Box
        sx={{
          maxHeight: "calc(100vh - 205px)",
          overflowY: "auto",
          py: 1,
        }}
      >
        <Stack spacing={2}>
          {/* Displays Filtered Jobs (all) */}
          {filteredJobs?.length ? (
            filteredJobs.map((job) => (
              <Card key={job._id} variant="outlined" sx={{ borderRadius: 2 }}>
                <FlexWrapper width="100%" justifyContent="space-between">
                  <Typography
                    variant="caption"
                    p={1}
                    textTransform="capitalize"
                  >
                    <strong>
                      {job.status === "beingcounted"
                        ? "Being Counted"
                        : job.status}
                    </strong>
                    {user.rolelevel === 0 && (
                      <Typography variant="caption" component="div">
                        {job._id}
                      </Typography>
                    )}
                  </Typography>

                  {job.status !== "new" && user.rolelevel <= 3 && (
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      sx={{ m: 1 }}
                      onClick={() => handleRevertToNew(job)}
                    >
                      Revert
                    </Button>
                  )}
                </FlexWrapper>
                <CardHeader
                  title={
                    <Typography
                      textAlign="center"
                      variant="h4"
                      fontWeight="bold"
                    >
                      {job.name}
                    </Typography>
                  }
                  subheader={
                    <>
                      <Typography textAlign="center">
                        Location: <strong>{job.itemlocation}</strong>
                      </Typography>
                      <FlexWrapper gap={1} justifyContent="center">
                        {" "}
                        <Typography textAlign="center">
                          Unique Bins: <strong>{job.uniquebins}</strong>
                        </Typography>
                        -
                        <Typography textAlign="center">
                          Unique Items: <strong>{job.uniqueitems}</strong>
                        </Typography>
                      </FlexWrapper>
                    </>
                  }
                />
                <CardContent>
                  <FlexWrapper flexDirection="column">
                    <Typography variant="body2">
                      Created: {new Date(job.createdAt).toLocaleString()} (
                      {job.createdby?.username})
                    </Typography>
                    {job.startedon && (
                      <Typography variant="body2">
                        Started: {new Date(job.startedon).toLocaleString()} (
                        {job.startedby?.username})
                      </Typography>
                    )}
                  </FlexWrapper>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    fullWidth
                    endIcon={<ArrowRightSharp />}
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={job._id}
                  >
                    View Details
                  </Button>
                </CardActions>
              </Card>
            ))
          ) : (
            <>
              {/* Displays own jobs */}
              {!Boolean(searchInput) && jobs.own?.length
                ? jobs.own.map((job) => (
                    <Card
                      key={job._id}
                      variant="outlined"
                      sx={{ borderRadius: 2 }}
                    >
                      <FlexWrapper width="100%" justifyContent="space-between">
                        <Typography
                          variant="caption"
                          p={1}
                          textTransform="capitalize"
                        >
                          <strong>
                            {job.status === "beingcounted"
                              ? "Being Counted"
                              : job.status}
                          </strong>
                          {user.rolelevel === 0 && (
                            <Typography variant="caption" component="div">
                              {job._id}
                            </Typography>
                          )}
                        </Typography>
                        {job.status !== "new" && user.rolelevel <= 3 && (
                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            sx={{ m: 1 }}
                            onClick={() => handleRevertToNew(job)}
                          >
                            Revert
                          </Button>
                        )}
                      </FlexWrapper>
                      <CardHeader
                        title={
                          <Typography
                            textAlign="center"
                            variant="h4"
                            fontWeight="bold"
                          >
                            {job.name}
                          </Typography>
                        }
                        subheader={
                          <>
                            <Typography textAlign="center">
                              Location: <strong>{job.itemlocation}</strong>
                            </Typography>
                            <FlexWrapper gap={1} justifyContent="center">
                              <Typography textAlign="center">
                                Unique Bins: <strong>{job.uniquebins}</strong>
                              </Typography>
                              -
                              <Typography textAlign="center">
                                Unique Items: <strong>{job.uniqueitems}</strong>
                              </Typography>
                            </FlexWrapper>
                          </>
                        }
                      />
                      <CardContent>
                        <FlexWrapper flexDirection="column">
                          <Typography variant="body2">
                            Created: {new Date(job.createdAt).toLocaleString()}{" "}
                            ({job.createdby?.username})
                          </Typography>
                          {job.startedon && (
                            <Typography variant="body2">
                              Started:{" "}
                              {new Date(job.startedon).toLocaleString()} (
                              {job.startedby?.username})
                            </Typography>
                          )}
                        </FlexWrapper>
                      </CardContent>
                      <Divider />
                      <CardActions>
                        <Button
                          fullWidth
                          endIcon={<ArrowRightSharp />}
                          variant="contained"
                          color="primary"
                          component={Link}
                          to={job._id}
                        >
                          View Details
                        </Button>
                      </CardActions>
                    </Card>
                  ))
                : null}

              {/* Displays remaining jobs */}
              {!Boolean(searchInput) && jobs.remaining?.length
                ? jobs.remaining.map((job) => (
                    <Card
                      key={job._id}
                      variant="outlined"
                      sx={{ borderRadius: 2 }}
                    >
                      <FlexWrapper width="100%" justifyContent="space-between">
                        <Typography
                          variant="caption"
                          p={1}
                          textTransform="capitalize"
                        >
                          <strong>
                            {job.status === "beingcounted"
                              ? "Being Counted"
                              : job.status}
                          </strong>
                          {user.rolelevel === 0 && (
                            <Typography variant="caption" component="div">
                              {job._id}
                            </Typography>
                          )}
                        </Typography>
                        {job.status !== "new" && user.rolelevel <= 3 && (
                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            sx={{ m: 1 }}
                            onClick={() => handleRevertToNew(job)}
                          >
                            Revert
                          </Button>
                        )}
                      </FlexWrapper>
                      <CardHeader
                        title={
                          <Typography
                            textAlign="center"
                            variant="h4"
                            fontWeight="bold"
                          >
                            {job.name}
                          </Typography>
                        }
                        subheader={
                          <>
                            <Typography textAlign="center">
                              Location: <strong>{job.itemlocation}</strong>
                            </Typography>
                            <FlexWrapper gap={1} justifyContent="center">
                              <Typography textAlign="center">
                                Unique Bins: <strong>{job.uniquebins}</strong>
                              </Typography>
                              -
                              <Typography textAlign="center">
                                Unique Items: <strong>{job.uniqueitems}</strong>
                              </Typography>
                            </FlexWrapper>
                          </>
                        }
                      />
                      <CardContent>
                        <FlexWrapper flexDirection="column">
                          <Typography variant="body2">
                            Created: {new Date(job.createdAt).toLocaleString()}{" "}
                            ({job.createdby?.username})
                          </Typography>
                          {job.startedon && (
                            <Typography variant="body2">
                              Started:{" "}
                              {new Date(job.startedon).toLocaleString()} (
                              {job.startedby?.username})
                            </Typography>
                          )}
                        </FlexWrapper>
                      </CardContent>
                      <Divider />
                      <CardActions>
                        <Button
                          fullWidth
                          endIcon={<ArrowRightSharp />}
                          variant="contained"
                          color="primary"
                          component={Link}
                          to={job._id}
                        >
                          View Details
                        </Button>
                      </CardActions>
                    </Card>
                  ))
                : null}

              {Boolean(searchInput) && !filteredJobs?.length && (
                <Typography justifyContent="center" textAlign="center" pt={2}>
                  No Cycle Counts To Display
                </Typography>
              )}
            </>
          )}
        </Stack>
      </Box>
    </Container>
  );
};
export default EnterCycleCountsPage;
