import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import axios from "../../../../axios/axios.config";
import Loading from "../../../../components/Loading";
import FlexWrapper from "../../../../components/FlexWrapper";
import PuertoRicoFlagImg from "../../../../assets/puerto-rico.png";

const SpecialOrders = () => {
  const [orderData, setOrderData] = useState([]);
  const [orderClasses, setOrderClasses] = useState([]); //will be used for filtering
  const [orderBrands, setOrderBrands] = useState([]); //will be used for filtering
  const [classFilter, setClassFilter] = useState("all"); //filters order
  const [brandFilter, setBrandFilter] = useState("all"); //filters order
  const [loading, setLoading] = useState("Loading Special Orders...");

  const {
    currentNetsuiteLocationIds,
    currentSubsidiary,
    currentDepartment,
    username,
  } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const filteredOrders = orderData.filter((order) => {
    const classMatches =
      classFilter === "all" || order["Items Class"] === classFilter;
    const brandMatches =
      brandFilter === "all" || order["Brand Packaging"] === brandFilter;

    return classMatches && brandMatches;
  });

  useEffect(() => {
    getSpecialOrders();
  }, []);

  useEffect(() => {
    const classFilterParam = searchParams.get("classFilter");
    const brandFilterParam = searchParams.get("brandFilter");

    if (classFilterParam) {
      setClassFilter(classFilterParam);
    }

    if (brandFilterParam) {
      setBrandFilter(brandFilterParam);
    }
  }, []);

  const getSpecialOrders = async () => {
    try {
      const nsResponse = await axios.get(
        `netsuite/order-processing/get/special-orders?locations=${JSON.stringify(
          currentNetsuiteLocationIds
        )}&subsidiary=${currentSubsidiary}`
      );

      const classStorage = new Set();
      const brandStorage = new Set();

      for (const order of nsResponse.data) {
        classStorage.add(order["Items Class"]);
        brandStorage.add(order["Brand Packaging"]);
      }

      setOrderClasses(Array.from(classStorage.keys()));
      setOrderBrands(Array.from(brandStorage.keys()));
      setOrderData(nsResponse.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could Not Get Orders: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  if (orderData?.length === 0) {
    return (
      <Typography variant="h6" textAlign="center" py={2}>
        No Orders To Display
      </Typography>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ pb: 1 }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Special Orders
      </Typography>

      <Typography
        textAlign="right"
        variant="subtitle1"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "primary.main",
        }}
      >
        Total Orders: {filteredOrders.length}
      </Typography>

      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        {/* Filters Section */}
        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="class-select-label">Filter By Class</InputLabel>
            <Select
              fullWidth
              labelId="class-select-label"
              id="class-select"
              value={classFilter}
              label="Filter By Class"
              onChange={(event) => {
                setClassFilter(event.target.value);
                setSearchParams({
                  brandFilter,
                  classFilter: event.target.value,
                });
              }}
              sx={{
                backgroundColor: "background.paper",
                "&:hover": { backgroundColor: "action.hover" },
              }}
            >
              <MenuItem value="all">All</MenuItem>
              {orderClasses?.map((order) => (
                <MenuItem key={order} value={order}>
                  {order}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="brand-select-label">Filter By Brand</InputLabel>
            <Select
              fullWidth
              labelId="brand-select-label"
              id="brand-select"
              value={brandFilter}
              label="Filter By Brand"
              onChange={(event) => {
                setBrandFilter(event.target.value);
                setSearchParams({
                  classFilter,
                  brandFilter: event.target.value,
                });
              }}
              sx={{
                backgroundColor: "background.paper",
                "&:hover": { backgroundColor: "action.hover" },
              }}
            >
              <MenuItem value="all">All</MenuItem>
              {orderBrands?.map((order) => (
                <MenuItem key={order} value={order}>
                  {order}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {filteredOrders.length ? (
        <>
          <Stack sx={{ pt: 1 }} spacing={2}>
            {filteredOrders?.map((order) => (
              <Card key={order["SO #"]} style={{ borderRadius: 8 }}>
                <CardContent>
                  {order["Shipping State"] === "PR" ? (
                    <Tooltip
                      arrow
                      placement="top"
                      title="This order ships to Puerto Rico"
                    >
                      <img height="25px" width="25px" src={PuertoRicoFlagImg} />
                    </Tooltip>
                  ) : null}
                  <Typography
                    variant="h5"
                    gutterBottom
                    textAlign="center"
                    fontWeight="bold"
                  >
                    {order["SO #"]} - {order["Brand Packaging"]}
                  </Typography>
                  <Typography gutterBottom textAlign="center">
                    {order["Customer"]}
                  </Typography>
                  <Typography variant="body2" gutterBottom textAlign="center">
                    PO #: {order["PO #"]}
                  </Typography>

                  <Typography variant="body1" pt={1}>
                    <strong>Date: </strong> {order.Date}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Total Qty: </strong> {order["Total Qty"]} (Fill
                    Rate: {order["Projected Fill Rate %"]})
                  </Typography>
                  <Typography variant="body1">
                    <strong>Items: </strong> {order.Items}
                  </Typography>
                  <Typography variant="body1">
                    <strong>First Pick Bin: </strong> {order["First Pick Bin"]}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Shipping Method: </strong>{" "}
                    {order["Shipping Method"]}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Shipping State: </strong> {order["Shipping State"]}
                  </Typography>
                  {Boolean(order["Related Transfer Orders"]) ? (
                    order["Pick Locked By"] !== "" &&
                    order["Pick Locked By"] !== username ? (
                      <Typography variant="body1">
                        <strong>Related Transfer Orders: </strong>{" "}
                        {order["Related Transfer Orders"]}
                      </Typography>
                    ) : (
                      <>
                        <FlexWrapper gap={1}>
                          <Typography>
                            <strong>Related Transfer Orders: </strong>{" "}
                          </Typography>

                          {order["Related Transfer Orders"]
                            ?.split("|")
                            .map((toOrder) => {
                              return (
                                <Link
                                  key={toOrder?.trim()}
                                  to={`/portal/${currentDepartment?.toLowerCase()}/pick-order?order=${toOrder?.trim()}&redirect=/portal/${currentDepartment?.toLowerCase()}/pick-to-so?order=${toOrder?.trim()}&original-page=${encodeURIComponent(
                                    `/portal/${currentDepartment?.toLowerCase()}/special-orders?classFilter=${classFilter}&brandFilter=${brandFilter}`
                                  )}`}
                                >
                                  <Typography> {toOrder?.trim()}</Typography>
                                </Link>
                              );
                            })}
                        </FlexWrapper>
                      </>
                    )
                  ) : null}
                </CardContent>
                <CardActions>
                  <Button
                    disabled={
                      (order["Pick Locked By"] !== "" &&
                        order["Pick Locked By"] !== username) ||
                      !order["First Pick Bin"]
                    }
                    variant="contained"
                    onClick={() =>
                      navigate(
                        `/portal/${currentDepartment?.toLowerCase()}/pick-order?order=${
                          order["SO #"]
                        }&redirect=${encodeURIComponent(
                          `/portal/${currentDepartment?.toLowerCase()}/special-orders?classFilter=${classFilter}&brandFilter=${brandFilter}`
                        )}`
                      )
                    }
                  >
                    Pick Order
                  </Button>
                </CardActions>
                <Typography
                  variant="body2"
                  color="error"
                  textAlign="center"
                  pb={1}
                  textTransform="capitalize"
                >
                  {order["Pick Locked By"] !== "" &&
                  order["Pick Locked By"] !== username
                    ? `Order already being picked by ${order["Pick Locked By"]}`
                    : ""}
                </Typography>
                <Typography
                  variant="body2"
                  color="error"
                  textAlign="center"
                  pb={1}
                  textTransform="capitalize"
                >
                  {!order["First Pick Bin"] && order["Related Transfer Orders"]
                    ? "Transfer Order must be picked First"
                    : ""}
                </Typography>
              </Card>
            ))}
          </Stack>
        </>
      ) : (
        <Typography variant="h6" textAlign="center" py={2}>
          No Orders To Display
        </Typography>
      )}
    </Container>
  );
};
export default SpecialOrders;
