import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";

import axios from "../../../../axios/axios.config";
import { useSelector } from "react-redux";
import Loading from "../../../Loading";

const PackProcessViewActiveOrders = ({ setOrderInput }) => {
  const [userOrders, setUserOrders] = useState(null);
  const [loading, setLoading] = useState("");

  const { openAlert } = useGeneralAlert();
  const { username } = useSelector((state) => state.user);
  const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    handleGetUserOrders();
  }, []);

  const handleGetUserOrders = async () => {
    setLoading("Loading user orders...");
    try {
      const response = await axios.get(
        `pack-process-v2/active-orders/${username}`
      );

      setUserOrders(response.data);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Could not get user orders: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 10000,
      });
    } finally {
      setLoading("");
    }
  };

  const handleCardClick = (order) => {
    setOrderInput(order?.toUpperCase());
  };

  if (loading) {
    return <Loading message={loading} />;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom py={2} textAlign="center">
        Your Orders
      </Typography>

      <Box sx={matches ? { height: "55vh", overflow: "scroll" } : {}}>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          pb={1}
          gap={1}
        >
          {userOrders?.length ? (
            userOrders.map((order) => (
              <Grid key={order._id} item xs={12} sm={6} md={4}>
                <Card
                  style={{
                    height: "100%",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  variant="outlined"
                  onClick={() => handleCardClick(order.createdfrom)}
                >
                  <CardContent style={{ paddingBottom: "16px" }}>
                    <Typography variant="subtitle1" textAlign="center">
                      {order.createdfrom}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Craeted Date:</strong>{" "}
                      {new Date(order.createdAt).toLocaleDateString()}
                    </Typography>
                    {order.brand && (
                      <Typography variant="body2">
                        <strong>Brand:</strong> {order.brand}
                      </Typography>
                    )}
                    {order.customer && (
                      <Typography variant="body2">
                        <strong>Customer:</strong> {order.customer}
                      </Typography>
                    )}
                    <Typography variant="body2">
                      <strong>Total To Pack:</strong> {order.totalitemstopack}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Total Packed :</strong> {order.totalpacked}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography textAlign="center">No orders to display</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default PackProcessViewActiveOrders;
