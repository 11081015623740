import { useEffect, useState } from "react";
import { ExitToApp, Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import axios from "../../axios/axios.config";
import Loading from "../../components/Loading";
import FlexWrapper from "../../components/FlexWrapper";
import { useGeneralAlert } from "../../hooks/useGeneralAlert";

const HelpCenterPage = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState("Loading...");
  const [searchInput, setSearchInput] = useState("");

  const { currentDepartment } = useSelector((state) => state.user);
  const { openAlert } = useGeneralAlert();
  const navigate = useNavigate();

  const filteredFiles =
    files?.length && Boolean(searchInput)
      ? files.filter((f) => f.key?.toUpperCase()?.includes(searchInput))
      : files;

  useEffect(() => {
    getFiles();
  }, []);

  const getFiles = async () => {
    const s3Department = currentDepartment?.toLowerCase();

    try {
      const response = await axios.get(
        `files/get/help-center/files?Prefix=SOP/${
          s3Department?.charAt(0).toUpperCase() + s3Department?.slice(1)
        }`
      );

      setFiles(
        response.data.filter(
          (data) =>
            data.key !==
            `SOP/${
              s3Department?.charAt(0).toUpperCase() + s3Department?.slice(1)
            }/`
        )
      );
    } catch (error) {
      openAlert({
        type: "error",
        message: `Error: ${error?.response?.data?.msg || error.message}`,
        duration: 8000,
      });
    } finally {
      setLoading("");
    }
  };

  if (Boolean(loading)) {
    return <Loading message={loading} />;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 1 }}>
      <Box>
        <Box pb={1}>
          <Tooltip title="Back to Home">
            <IconButton
              aria-label=""
              onClick={() => {
                navigate(`/portal/${currentDepartment.toLowerCase()}`);
              }}
            >
              <ExitToApp color="error" />
            </IconButton>
          </Tooltip>
        </Box>

        <FlexWrapper alignItems="baseline" justifyContent="space-between">
          <Typography variant="h4">Help Center</Typography>
          <Typography variant="body2">
            <strong>Total: </strong> {filteredFiles?.length}
          </Typography>
        </FlexWrapper>

        <Box pb={2}>
          <TextField
            fullWidth
            label="Search"
            value={searchInput}
            onChange={(event) =>
              setSearchInput(event.target.value?.toUpperCase())
            }
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Grid container spacing={2}>
          {filteredFiles.map((file) => (
            <Grid item xs={12} key={file.key}>
              <Card sx={{ height: "100%" }}>
                <CardContent>
                  <FlexWrapper justifyContent="space-between">
                    <Typography variant="h6" gutterBottom>
                      {file.key.split("/").pop()?.replace(".pdf", "")}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </Button>
                  </FlexWrapper>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default HelpCenterPage;
