import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NavBar from "../../components/NavBar";

const Portal = () => {
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    //default language is english so if language is different then update language for app
    if (user?.language !== "en") {
      i18n.changeLanguage(user.language);
    }
  }, []);

  //handles logic to send user to appropriate department when on /portal page
  if (location.pathname === "/portal") {
    return (
      <Navigate
        to={
          user?.currentDepartment
            ? `/portal/${user.currentDepartment.toLowerCase()}`
            : "/session"
        }
      />
    );
  }

  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
};
export default Portal;
