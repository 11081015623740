import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Box, Button, Menu, MenuItem } from "@mui/material";

const ReceiveTransactionActions = ({
  transactionData: {
    internalid,
    _id,
    receiveditems,
    trantype,
    totalitemstoreceive,
    totalreceived,
    locationinternalid,
    tranid,
    condition,
    tranref,
    soreference,
  },
  setIsReferenceModalOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const { handleItemReceipt } = useOutletContext();

  const handleCancelReceipt = () => {
    setAnchorEl(null);
    navigate(`/portal/warehouse/receive-transaction`, { replace: true });
  };

  return (
    <Box alignSelf="right">
      <Button
        size="small"
        variant="contained"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Actions
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {Boolean(
          totalitemstoreceive !== totalreceived && receiveditems.length
        ) && (
          <MenuItem
            onClick={() =>
              handleItemReceipt({
                id: internalid,
                mongoid: _id,
                receiveditems,
                trantype,
                locationinternalid,
                isPartial: true,
                tranid,
                condition,
                reference: tranref,
                soreference,
              })
            }
          >
            Partial Receive
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setIsReferenceModalOpen(true);
            setAnchorEl(null);
          }}
        >
          Update Reference
        </MenuItem>
        <MenuItem onClick={handleCancelReceipt}>Pause Receipt</MenuItem>
      </Menu>
    </Box>
  );
};
export default ReceiveTransactionActions;
